import { FC, useEffect, useState, useReducer } from "react";

import { LawProposition, Law, Comment } from "../../OnChain/chainInfo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faHeartCrack } from "@fortawesome/free-solid-svg-icons";

const LawCommentContainer: FC<any> = (props) => {
    const { comment, onLikeClick, onDislikeClick, containerClass } = props

    return (
        <div className={`law-container no-hover d-flex ${containerClass} mb-3 p-4`}>
            <img src={comment.commenterBobo.imageUrl} className="img-fluid me-3" style={{borderRadius: "50%", width: "50px"}} />
            <span className="text-start"><b>{comment.commenterBobo.name}</b> {comment.comment} <br/><i style={{fontSize: ".9rem"}}>{new Date(comment.commentTimestamp * 1000).toLocaleString("en-US")}</i></span>
            <div className="d-flex flex-column align-items-end">
                <span className="float-right ms-5">{comment.numLikes} <FontAwesomeIcon icon={faHeart} className={`ms-2 align-self-center ${onLikeClick !== undefined ? "hover-text-selected pointer-hover" :""}`} style={{borderRadius: "50%"}} onClick={onLikeClick} /></span>
                <span className="float-right ms-5">{comment.numDislikes} <FontAwesomeIcon icon={faHeartCrack} className={`ms-2 align-self-center ${onDislikeClick !== undefined ? "hover-text-selected pointer-hover" :""}`} style={{borderRadius: "50%"}} onClick={onDislikeClick} /></span>
            </div>
            
            
        </div>
    )
}

export default LawCommentContainer
