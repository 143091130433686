import {
  Connection,
  MemcmpFilter,
  PublicKey,
  Transaction,
  ConfirmOptions,
  SignatureResult
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  Token,
} from "@solana/spl-token";
import {
  Metadata,
  MetadataData,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";

import * as anchor from "@project-serum/anchor";
import {
  Program, Wallet, web3, BN, Idl, Provider
} from '@project-serum/anchor';

import { toast } from 'react-toastify';

import { delay, getStakeDataAddress, getStakingVaultAddress } from "../OnChain/utils"
import stakingProgramIdl from '../OnChain/StakingProgram/idl/nft_staking.json';

import ranks from '../OnChain/ranks.json'

export const CANDY_MACHINE_ADDRESS = new PublicKey(
  "5VB5StUrvwMLirDcfrXQDMVLM3GSAdaZ32oLxaHKqiV8"
);

export const PROGRAM_ID = new PublicKey(
  stakingProgramIdl.metadata.address
);

const opts: ConfirmOptions = {
  preflightCommitment: "processed"
}

export async function getProvider(connection: Connection, wallet: Wallet) {
  const provider = new Provider(
    connection, wallet, opts,
  );
  return provider;
}

async function sendAndConfirmTransactions(
  connection: Connection,
  wallet: Wallet,
  transactions: Transaction[]
): Promise<SignatureResult[]> {
  let { blockhash } = await connection.getRecentBlockhash("singleGossip");

  transactions.forEach((transaction) => {
    console.log("Transaction tick")
    transaction.feePayer = wallet.publicKey;
    transaction.recentBlockhash = blockhash;
  });

  let signedTransactions: Transaction[] = await wallet.signAllTransactions(transactions);

  let signatures: string[] = await Promise.all(
    signedTransactions.map((transaction) =>
      connection.sendRawTransaction(transaction.serialize(), {
        skipPreflight: true,
      })
    )
  );
  // toast.info("Waiting for confirmation")
  toast.info("Waiting for confirmation")
  let rpcResponses = await Promise.all(
    signatures.map((signature) =>
      connection.confirmTransaction(signature, "processed")
    )
  );
  console.log("Transactions success!")

  return rpcResponses.map(resp => {
    announceResponse(resp.value); return resp.value
  })
}

function announceResponse(result: SignatureResult) {
  if (result.err === null) {
    toast.success("Transaction successful")
  } else {
    toast.error("An error occured with the transaction")
  }
}

export interface VaultData {
  address: PublicKey,
  admin: PublicKey,
  rewardMint: PublicKey,
  rewardDecimals: BN,
  rarityBrackets: BN[],
  rarityMultipliers: BN[], // rarity_bracket_identifier: multiplier
  lockedDurations: BN[], // Duration: multiplier
  durationMultipliers: BN[],
  minLockupPeriod: BN, // in seconds /// Not currently used
  baseRewardRate: BN, // Tokens per second
  overLockMultiplier: BN, // rewards earned after the lock duration get multiplied by this
  overLockMultiplierDecimals: BN,

  collectionCandyMachine: PublicKey,
  numStaked: BN
}

export async function getVaultData(
  connection: Connection,
  wallet: any
): Promise<VaultData | null> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(stakingProgramIdl as Idl, PROGRAM_ID, provider);

  let vaultDataAddress = await getStakingVaultAddress()

  let rewardDecimals = 9;

  let vaultData: VaultData | null;
  try {
    let vaultDataAccount = await program.account.vaultData.fetch(vaultDataAddress)
    vaultData = {
      address: vaultDataAddress,
      admin: vaultDataAccount.admin as PublicKey,
      rewardMint: vaultDataAccount.rewardMint as PublicKey,
      rewardDecimals: new BN(rewardDecimals),
      rarityBrackets: vaultDataAccount.rarityBrackets as BN[],
      rarityMultipliers: vaultDataAccount.rarityMultipliers as BN[], // rarity_bracket_identifier: multiplier

      lockedDurations: vaultDataAccount.lockedDurations as BN[], // Duration: multiplier
      durationMultipliers: vaultDataAccount.durationMultipliers as BN[],
      minLockupPeriod: vaultDataAccount.minLockupPeriod as BN,
      baseRewardRate: vaultDataAccount.baseRewardRate as BN,
      overLockMultiplier: vaultDataAccount.overLockMultiplier as BN,
      overLockMultiplierDecimals: vaultDataAccount.overLockMultiplierDecimals as BN,

      collectionCandyMachine: vaultDataAccount.collectionCandyMachine as PublicKey,
      numStaked: vaultDataAccount.numStaked as BN,
    }
  } catch (e) {
    vaultData = null
  }

  return vaultData;
}

export async function stakeNFT(
  connection: Connection,
  wallet: any,
  mint: PublicKey,
  vault: VaultData,
  locked_duration: number
): Promise<string> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(stakingProgramIdl as Idl, PROGRAM_ID, provider);

  let rarity_bracket_identifier = getRole(mint);

  const metadataInfo = await Metadata.getPDA(mint);
  const mintTokenAccount = (await connection.getTokenLargestAccounts(mint)).value[0].address;
  const vaultNftAta = await getAssociatedTokenAddress(
    vault.address,
    mint,
    true
  );
  const stakeData = await getStakeDataAddress(mint);

  let response = await program.rpc.stake(new BN(rarity_bracket_identifier), new BN(locked_duration), {
    accounts: {
      payer: wallet.publicKey,
      mint,
      metadataInfo,

      vaultData: vault.address,
      collectionCandyMachine: vault.collectionCandyMachine,
      payerNftAta: mintTokenAccount,
      vaultNftAta,
      stakeData,

      tokenProgram: TOKEN_PROGRAM_ID,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId
    }
  })

  return response;
}

export async function stakeAllNFTs(
  connection: Connection,
  wallet: any,
  mints: PublicKey[],
  vault: VaultData,
  locked_duration: number
): Promise<SignatureResult[]> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(stakingProgramIdl as Idl, PROGRAM_ID, provider);

  async function getStakeTransactionForMint(
    mint: PublicKey
  ): Promise<Transaction> {
    let rarity_bracket_identifier = getRole(mint);

    const metadataInfo = await Metadata.getPDA(mint);
    const mintTokenAccount = (await connection.getTokenLargestAccounts(mint)).value[0].address;
    const vaultNftAta = await getAssociatedTokenAddress(
      vault.address,
      mint,
      true
    );
    const stakeData = await getStakeDataAddress(mint);

    return program.transaction.stake(new BN(rarity_bracket_identifier), new BN(locked_duration), {
      accounts: {
        payer: wallet.publicKey,
        mint,
        metadataInfo,

        vaultData: vault.address,
        collectionCandyMachine: vault.collectionCandyMachine,
        payerNftAta: mintTokenAccount,
        vaultNftAta,
        stakeData,

        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        systemProgram: anchor.web3.SystemProgram.programId
      }
    })
  }

  let transactions: Transaction[] = [];
  for (let mint of mints) {
    transactions.push(await getStakeTransactionForMint(mint));
  }

  let response = await sendAndConfirmTransactions(connection, wallet, transactions);
  return response;
}

export async function unstakeNFT(
  connection: Connection,
  wallet: any,
  mint: PublicKey,
  vault: VaultData,
): Promise<string> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(stakingProgramIdl as Idl, PROGRAM_ID, provider);

  const metadataInfo = await Metadata.getPDA(mint);
  const payerNftAta = await getAssociatedTokenAddress(
    wallet.publicKey,
    mint
  )
  const vaultNftAta = await getAssociatedTokenAddress(
    vault.address,
    mint,
    true
  );

  const rewardMint = vault.rewardMint;
  const payerRewardAta = await getAssociatedTokenAddress(
    wallet.publicKey,
    rewardMint
  )
  const vaultRewardAta = await getAssociatedTokenAddress(
    vault.address,
    rewardMint,
    true
  )
  const stakeData = await getStakeDataAddress(mint)



  let response = await program.rpc.unstake({
    accounts: {
      payer: wallet.publicKey,
      mint,
      metadataInfo,

      vaultData: vault.address,
      collectionCandyMachine: vault.collectionCandyMachine,
      payerNftAta,
      vaultNftAta,

      payerRewardAta,
      vaultRewardAta,
      rewardMint,

      stakeData,

      tokenProgram: TOKEN_PROGRAM_ID,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId
    }
  })

  return response;
}

export async function unstakeAllNFTs(
  connection: Connection,
  wallet: any,
  mints: PublicKey[],
  vault: VaultData,
): Promise<SignatureResult[]> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(stakingProgramIdl as Idl, PROGRAM_ID, provider);

  async function getUnstakeTransactionForMint(
    mint: PublicKey
  ): Promise<Transaction> {

    const metadataInfo = await Metadata.getPDA(mint);

    const payerNftAta = await getAssociatedTokenAddress(
      wallet.publicKey,
      mint
    )
    const vaultNftAta = await getAssociatedTokenAddress(
      vault.address,
      mint,
      true
    );

    const rewardMint = vault.rewardMint;
    const payerRewardAta = await getAssociatedTokenAddress(
      wallet.publicKey,
      rewardMint
    )
    const vaultRewardAta = await getAssociatedTokenAddress(
      vault.address,
      rewardMint,
      true
    )
    const stakeData = await getStakeDataAddress(mint);

    return program.transaction.unstake({
      accounts: {
        payer: wallet.publicKey,
        mint,
        metadataInfo,

        vaultData: vault.address,
        collectionCandyMachine: vault.collectionCandyMachine,
        payerNftAta,
        vaultNftAta,

        payerRewardAta,
        vaultRewardAta,
        rewardMint,

        stakeData,

        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        systemProgram: anchor.web3.SystemProgram.programId
      }
    })
  }

  let transactions: Transaction[] = [];
  for (let mint of mints) {
    transactions.push(await getUnstakeTransactionForMint(mint));
  }

  let response = await sendAndConfirmTransactions(connection, wallet, transactions);
  return response;
}

export async function withdrawRewards(
  connection: Connection,
  wallet: any,
  mint: PublicKey,
  vault: VaultData,
): Promise<string> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(stakingProgramIdl as Idl, PROGRAM_ID, provider);

  const metadataInfo = await Metadata.getPDA(mint);

  const rewardMint = vault.rewardMint;
  const payerRewardAta = await getAssociatedTokenAddress(
    wallet.publicKey,
    rewardMint
  )
  const vaultRewardAta = await getAssociatedTokenAddress(
    vault.address,
    rewardMint,
    true
  )

  const stakeData = await getStakeDataAddress(mint)

  let response = await program.rpc.withdraw({
    accounts: {
      payer: wallet.publicKey,
      mint,
      metadataInfo,

      vaultData: vault.address,
      collectionCandyMachine: vault.collectionCandyMachine,

      payerRewardAta,
      vaultRewardAta,
      rewardMint,

      stakeData,

      tokenProgram: TOKEN_PROGRAM_ID,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId
    }
  })

  return response;
}

export async function withdrawAllRewards(
  connection: Connection,
  wallet: any,
  mints: PublicKey[],
  vault: VaultData
): Promise<SignatureResult[]> {
  const provider = await getProvider(connection, wallet);
  const program = new Program(stakingProgramIdl as Idl, PROGRAM_ID, provider);

  async function getWithdrawInstructionForMint(
    mint: PublicKey
  ): Promise<Transaction> {
    const metadataInfo = await Metadata.getPDA(mint);

    const rewardMint = vault.rewardMint;
    const payerRewardAta = await getAssociatedTokenAddress(
      wallet.publicKey,
      rewardMint
    );
    const vaultRewardAta = await getAssociatedTokenAddress(
      vault.address,
      rewardMint,
      true
    );

    const stakeData = await getStakeDataAddress(mint);

    const response: Transaction = program.transaction.withdraw({
      accounts: {
        payer: wallet.publicKey,
        mint,
        metadataInfo,

        vaultData: vault.address,
        collectionCandyMachine: vault.collectionCandyMachine,

        payerRewardAta,
        vaultRewardAta,
        rewardMint,

        stakeData,

        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        systemProgram: anchor.web3.SystemProgram.programId
      }
    })

    return response;
  }

  let transactions: Transaction[] = [];
  for (let mint of mints) {
    transactions.push(await getWithdrawInstructionForMint(mint));
  }

  let response = await sendAndConfirmTransactions(connection, wallet, transactions);
  return response;
}



export function getMintCurrentRewards(
  vault: VaultData,
  startTimestamp: number,
  lockedDuration: number,
  rarityBracket: number,
  withdrawn: number
): number {
  const baseRate = Number(vault.baseRewardRate);
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const rarityMultiplier = getRarityMultiplier(vault, rarityBracket)
  const durationMultiplier = getDurationMultiplier(vault, lockedDuration)

  const overLockMultiplier = Number(vault.overLockMultiplier)
  const overLockMultiplierDecimals = Number(vault.overLockMultiplierDecimals);

  let totalRewards = calculate_total_rewards(baseRate, startTimestamp, currentTimestamp, lockedDuration, rarityMultiplier, durationMultiplier, overLockMultiplier, overLockMultiplierDecimals);
  // console.log((totalRewards - Number(withdrawn)) / Math.pow(10, Number(vault.rewardDecimals)))
  return (totalRewards - Number(withdrawn)) / Math.pow(10, Number(vault.rewardDecimals));
}

function calculate_total_rewards(
  base_rate: number,
  start_timestamp: number,
  current_timestamp: number,
  locked_duration: number,
  rarity_multiplier: number,
  duration_multiplier: number,
  over_lock_multiplier: number,
  over_lock_multiplier_decimals: number
): number {
  let stake_duration = current_timestamp - start_timestamp;
  let total_rewards = base_rate * stake_duration * rarity_multiplier * duration_multiplier / 10_000;

  if (stake_duration > locked_duration) {
    let overlock_rewards = (base_rate * (stake_duration - locked_duration) * rarity_multiplier * duration_multiplier) / 10_000;
    total_rewards -= overlock_rewards - overlock_rewards * over_lock_multiplier / (10 ** over_lock_multiplier_decimals);
  }
  return total_rewards;
}

export function getAllCurrentRewards(
  stakedNfts: StakedData[],
  vault: VaultData
): number {
  let allCurrentRewards = 0;
  for (let stakedNft of stakedNfts) {
    allCurrentRewards += Number(getMintCurrentRewards(vault, stakedNft.timestamp, stakedNft.lockedDuration, stakedNft.rarityBracket, stakedNft.withdrawn));
  }
  return allCurrentRewards;
}


export function getRole(
  token: PublicKey,
): any {
  let boboRanks: any = ranks;
  let boboMint: string = token.toString()
  let role: number = boboRanks[boboMint]
  return role;
}

function parseUintLe(data: Uint8Array, offset: number = 0, length: number): bigint {
  let number = BigInt(0);
  for (let i = 0; i < length; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 4);
  return number;
}

function parseUint32Le(data: Uint8Array, offset: number = 0): bigint {
  let number = BigInt(0);
  for (let i = 0; i < 4; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 4);
  return number;
}

function parseUint64Le(data: Uint8Array, offset: number = 0): bigint {
  let number = BigInt(0);
  for (let i = 0; i < 8; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 8);
  return number;
}

function getAssociatedTokenAddress(
  walletAddress: PublicKey,
  tokenAddress: PublicKey,
  allowOffCurve: boolean = false
): Promise<PublicKey> {
  return Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tokenAddress,
    walletAddress,
    allowOffCurve
  );
}

export function getRarityMultiplier(
  vault: VaultData,
  rarityBracket: number
): number {
  let rarityIndex = 0;
  for (let id of vault.rarityBrackets) {
    if (Number(id) == rarityBracket) {
      rarityIndex = vault.rarityBrackets.indexOf(id)
    }
  }
  return Number(vault.rarityMultipliers[rarityIndex])
}
export function getDurationMultiplier(
  vault: VaultData,
  lockedDuration: number
): number {
  let durationIndex = 0;
  for (let id of vault.lockedDurations) {
    if (Number(id) == lockedDuration) {
      durationIndex = vault.lockedDurations.indexOf(id)
    }
  }
  return Number(vault.durationMultipliers[durationIndex])
}


export interface UnstakedData {
  mint: PublicKey;
  data: MetadataDataData;
  uri: any;
}
export interface StakedData extends UnstakedData {
  timestamp: number;
  withdrawn: number;
  rewardMultiplier: number;
  rarityBracket: number;
  lockedDuration: number;
  dailyRate: number;
}

export async function getStakedDataByOwner(
  connection: Connection,
  wallet: any,
  owner: PublicKey,
  vault: VaultData | null
): Promise<StakedData[]> {
  let stakeDataAccounts = await connection.getProgramAccounts(PROGRAM_ID, {
    filters: [
      createStakeTokenOwnerFilter(owner),
    ],
  });

  let mintPDAs = await Promise.all(stakeDataAccounts.map(async ({ account: { data } }) => {
    let mint = new PublicKey(data.slice(44, 76));
    return await Metadata.getPDA(mint)
  }))

  let metadatas = await Metadata.getInfos(connection, mintPDAs)

  let allNftDatas = [];
  const batchSize = 18;
  const batchInterval = 1000;
  for (let i = 0; i < stakeDataAccounts.length; i += batchSize) {
    const batch = stakeDataAccounts.slice(i, i + batchSize);
    const batchNftDatas = await Promise.all(
      batch.map(async ({ account: { data } }, index) => {
        let mint = new PublicKey(data.slice(44, 76));
        let metadataAccount = metadatas.get(mintPDAs[index + i]);
        let metadata;
        if (metadataAccount?.data != undefined) {
          metadata = MetadataData.deserialize(metadataAccount?.data)
        }
        const provider = await getProvider(connection, wallet);
        const program = new Program(stakingProgramIdl as Idl, PROGRAM_ID, provider);
  
        let stakeDataInfo = await getStakeDataAddress(mint)
        let stakeData = await program.account.stakeData.fetch(stakeDataInfo)

        let timestamp: number = Number(stakeData.entryTimestamp)
        let rarityBracket: number = Number(stakeData.rarityBracketIdentifier);
        let lockedDuration: number = Number(stakeData.lockedDuration);
  
        const rarityMultiplier = vault !== null ? getRarityMultiplier(vault, rarityBracket) : null
        const durationMultiplier = vault !== null ? getDurationMultiplier(vault, lockedDuration) : null
        let rewardMultiplier = rarityMultiplier !== null && durationMultiplier !== null ? rarityMultiplier * durationMultiplier / (100 * 100) : 0
  
        let withdrawn = Number(parseUint64Le(data, 82));
  
        let dailyRate = vault !== null ? Number(vault.baseRewardRate) * 86400 * rewardMultiplier / Math.pow(10, Number(vault.rewardDecimals)) : 0
  
        let nftData: StakedData = {
          mint,
          data: metadata.data,
          uri: metadata.data.uri,
          timestamp,
          withdrawn,
          rewardMultiplier,
          rarityBracket,
          lockedDuration,
          dailyRate,
        }
  
        return nftData
      })
    )
    allNftDatas.push(...batchNftDatas);
    await delay(batchInterval);
  }
  allNftDatas = allNftDatas.filter((nftData,index,self)=>self.findIndex(v2=>(v2.mint.toString()===nftData.mint.toString()))===index)

  return allNftDatas;
}


export async function isBoboStaked(
  connection: Connection,
  wallet: Wallet,
  mint: PublicKey
): Promise<boolean> {
  console.log(`Checking if ${mint} is staked`)
  const provider = await getProvider(connection, wallet);
  const program = new Program(stakingProgramIdl as Idl, PROGRAM_ID, provider);

  const stakeData = await getStakeDataAddress(new PublicKey(mint));
  try {
    const stakeDataAddress = await program.account.stakeData.fetch(stakeData)
  } catch (error) {
    return false
  }
  return true
}

export function createStakeTokenOwnerFilter(owner: PublicKey): MemcmpFilter {
  return {
    memcmp: {
      offset: 12,
      bytes: owner.toBase58(),
    },
  };
}