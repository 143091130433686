import {
    Connection,
    ConfirmOptions,
    PublicKey,
    Keypair,
    Transaction
} from "@solana/web3.js";
import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import * as anchor from "@project-serum/anchor";
import {
    Program, Provider, Wallet, web3, BN, Idl
} from '@project-serum/anchor';
import * as spl from '@solana/spl-token';

// import {sendAndConfirmTransactionsWithoutWallet} from "./NewBridge"
import {isBoboStaked} from "./newStaking"
import {getStakeDataAddress} from "../OnChain/utils"

import { toast } from 'react-toastify';

import boboWeapons from "./bobo_weapons.json"
import weaponsAirdropIdl from "../OnChain/WeaponsAirdrop/idl/weapons_airdrop.json"

import { programs } from '@metaplex/js';
const { metadata: { Metadata } } = programs;

const CANDY_MACHINE_ADDRESS = new PublicKey("5VB5StUrvwMLirDcfrXQDMVLM3GSAdaZ32oLxaHKqiV8") // mainnet
const BOW_MINT = new PublicKey("BoBoWy2Z4QvyZRw7JdNS1dxXPA56DNrxCX97YEzobcA6") // mainnet

const opts: ConfirmOptions = {
    preflightCommitment: "processed"
}

const weaponsAirdropProgramIdl = new PublicKey(weaponsAirdropIdl.metadata.address)

const claimableWeapons = [
    "Golden Gun",
    "Rm Assault Rifle",
    "Bazooka",
    "Silent Pistol",
    "Ak 47",
    "Shotgun",
    "Combat Knife",
    "Bull Tears",
    "Blaster Pistol",
    "Katana",
    "Ballistic Shotgun",
    "Grenade",
    "Axe",
    "Gold Sulfateuse",
    "Prime Fia Sword",
    "S02 Sniper",
    "Machinegun",
    "Laser Pistol",
]
  
export async function getProvider(connection: Connection, wallet: Wallet) {
    const provider = new Provider(
      connection, wallet, opts,
    );
    return provider;
}

export function getBoboIdWeapon(boboId: string): string {
    const name = "BOBO #" + boboId.toString()
    const weaponsNames: any = boboWeapons
    return weaponsNames[name]
}

export async function canBoboClaimWeapon(
    connection: Connection,
    wallet: Wallet,
    boboMint: string,
    boboId: string
): Promise<boolean> {
    const provider = await getProvider(connection, wallet)
    const program = new Program(weaponsAirdropIdl as Idl, weaponsAirdropProgramIdl, provider);

    // Check in list
    const weaponName = getBoboIdWeapon(boboId);
    console.log(weaponName)
    if(!claimableWeapons.includes(weaponName) || weaponName === null) return false

    const weaponPDA = await getWeaponAirdropInfoPDA(new PublicKey(boboMint), program)
    const weaponAccount = await program.account.weaponAirdropInfo.fetch(weaponPDA);
    console.log(weaponAccount)
    if(weaponAccount == null) return false
    return !weaponAccount.claimed
}


export async function claimBoboWeapon(
    connection: Connection,
    wallet: Wallet,
    boboMint: PublicKey
): Promise<boolean> {
    const isBoboStakedResult = await isBoboStaked(connection, wallet, boboMint)
    console.log(`isBoboStakedResult: ${isBoboStakedResult}`)
    let claimResult: boolean = false;
    if(isBoboStakedResult) {
        claimResult = await claimWeaponWhileStaked(connection, wallet, boboMint)
    } else {
        claimResult = await claimWeapon(connection, wallet, boboMint)
    }
    return claimResult
}

export async function claimWeapon(
    connection: Connection,
    wallet: Wallet,
    boboMint: PublicKey
): Promise<boolean> {
    const provider = await getProvider(connection, wallet)
    const program = new Program(weaponsAirdropIdl as Idl, weaponsAirdropProgramIdl, provider);

    const treasuryInfo = await getTreasuryInfoPDA(program)
    const weaponPDA = await getWeaponAirdropInfoPDA(boboMint, program)
    const weaponAccount = await program.account.weaponAirdropInfo.fetch(weaponPDA);
    const weaponMint = weaponAccount.weaponMint
    // const airdropAccountWeaponAta = await getAssociatedTokenAddress(weaponPDA, weaponMint, true)

    const boboAccount = (await connection.getTokenLargestAccounts(boboMint)).value[0].address;
    const claimerFeeMintAta = (await connection.getTokenAccountsByOwner(wallet.publicKey, {mint: BOW_MINT})).value[0]?.pubkey
    if(claimerFeeMintAta === undefined) {
        toast.error("An error occured. Please make sure you have at least 220 BOW and some spare SOL in your wallet.")
        return false
    }
    console.log(claimerFeeMintAta)

    const airdropAccountWeaponAta = await getAssociatedTokenAddress(weaponMint, weaponPDA, true)
    const claimFeeTreasuryAta = await getAssociatedTokenAddress(BOW_MINT, treasuryInfo, true)

    let claimerWeaponAta = await getAssociatedTokenAddress(weaponMint, wallet.publicKey)
      
    const toastId = toast.loading("Claiming weapon of Bobo...")
    try {
        await program.rpc.claimWeapon({
            accounts: {
            treasuryInfo,
            authority: new PublicKey("FT7gy5BnPZpfkpUKQ7xeCsZVF3QuLof5nMvEF97tzxPH"),
            claimer: wallet.publicKey,
            weaponAirdropInfo: weaponPDA,

            boboAta: boboAccount,
            weaponMint,
            claimerWeaponAta,
            airdropAccountWeaponAta,

            feeMint: BOW_MINT,
            claimerFeeMintAta,
            claimFeeTreasuryAta,

            systemProgram: anchor.web3.SystemProgram.programId,
            rent: anchor.web3.SYSVAR_RENT_PUBKEY,
            tokenProgram: spl.TOKEN_PROGRAM_ID,
            associatedTokenProgram: spl.ASSOCIATED_TOKEN_PROGRAM_ID,
            }
        })
    } catch (error: any) {
        console.error(error)
        //toast
        let errorMessage = "An error occured while claiming weapon.";
        if(error.message.includes("claimer_fee_mint_ata")) errorMessage = "An error occured. Please make sure you have at least 220 BOW and some spare SOL in your wallet."
        if(error.message.includes("0x1")) errorMessage = "An error occured. Please make sure you have some spare SOL in your wallet."
        if(error.message.includes("bobo_staked_account") || error.message.includes("bobo_ata")) errorMessage = "An error occured. Please make sure you own this Bobo."
        toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
        return false
    }
    toast.update(toastId, { render: "Claimed weapon!", type: "success", isLoading: false, autoClose: 5000 })
    return true
}


export async function claimWeaponWhileStaked(
    connection: Connection,
    wallet: Wallet,
    boboMint: PublicKey
): Promise<boolean> {
    const provider = await getProvider(connection, wallet)
    const program = new Program(weaponsAirdropIdl as Idl, weaponsAirdropProgramIdl, provider);

    const treasuryInfo = await getTreasuryInfoPDA(program)
    const weaponPDA = await getWeaponAirdropInfoPDA(boboMint, program)
    const weaponAccount = await program.account.weaponAirdropInfo.fetch(weaponPDA);
    const weaponMint = weaponAccount.weaponMint
    // const airdropAccountWeaponAta = await getAssociatedTokenAddress(weaponPDA, weaponMint, true)

    const boboStakedAccount = await getStakeDataAddress(boboMint)
    const claimerFeeMintAta = (await connection.getTokenAccountsByOwner(wallet.publicKey, {mint: BOW_MINT})).value[0]?.pubkey
    if(claimerFeeMintAta === undefined) {
        toast.error("An error occured. Please make sure you have at least 220 BOW and some spare SOL in your wallet.")
        return false
    }


    const airdropAccountWeaponAta = await getAssociatedTokenAddress(weaponMint, weaponPDA, true)
    const claimFeeTreasuryAta = await getAssociatedTokenAddress(BOW_MINT, treasuryInfo, true)

    let claimerWeaponAta = await getAssociatedTokenAddress(weaponMint, wallet.publicKey)
      
    const toastId = toast.loading("Claiming weapon of staked Bobo...")
    try {
        await program.rpc.claimWeaponWhileStaked({
            accounts: {
            treasuryInfo,
            authority: new PublicKey("FT7gy5BnPZpfkpUKQ7xeCsZVF3QuLof5nMvEF97tzxPH"),
            claimer: wallet.publicKey,
            weaponAirdropInfo: weaponPDA,

            boboStakedAccount,
            weaponMint,
            claimerWeaponAta,
            airdropAccountWeaponAta,

            feeMint: BOW_MINT,
            claimerFeeMintAta,
            claimFeeTreasuryAta,

            boboMetadataProgram: new PublicKey("DGjGBGv4BDM7K1PWj1XNXmiQwsC3TEk1DPudhGud115P"),
            systemProgram: anchor.web3.SystemProgram.programId,
            rent: anchor.web3.SYSVAR_RENT_PUBKEY,
            tokenProgram: spl.TOKEN_PROGRAM_ID,
            associatedTokenProgram: spl.ASSOCIATED_TOKEN_PROGRAM_ID,
            }
        })
    } catch (error: any) {
        console.error(error)
        //toast
        let errorMessage = "An error occured while claiming weapon.";
        if(error.message.includes("claimer_fee_mint_ata")) errorMessage = "An error occured. Please make sure you have at least 220 BOW and some spare SOL in your wallet."
        if(error.message.includes("0x1")) errorMessage = "An error occured. Please make sure you have some spare SOL in your wallet (for initialization)."
        if(error.message.includes("bobo_staked_account") || error.message.includes("bobo_ata")) errorMessage = "An error occured. Please make sure you own this Bobo."
        toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
        return false
    }
    toast.update(toastId, { render: "Claimed weapon!", type: "success", isLoading: false, autoClose: 5000 })
    return true
}


function getAssociatedTokenAddress(
    tokenAddress: PublicKey,
    walletAddress: PublicKey,
    allowOffCurve: boolean = false
  ): Promise<PublicKey> {
    return spl.Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      tokenAddress,
      walletAddress,
      allowOffCurve
    );
}
async function getTreasuryInfoPDA(
    program: Program
): Promise<PublicKey> {
    const [treasuryInfoPDA, _] = await anchor.web3.PublicKey.findProgramAddress(
        [
          anchor.utils.bytes.utf8.encode("treasury-info"),
        ],
        program.programId
    )
    return treasuryInfoPDA
}
async function getWeaponAirdropInfoPDA(
    boboMint: PublicKey,
    program: Program
): Promise<PublicKey> {
    const [weaponPDA, _] = await anchor.web3.PublicKey.findProgramAddress(
        [
          anchor.utils.bytes.utf8.encode("weapon-airdrop-info"),
          boboMint.toBuffer()
        ],
        program.programId
    )
    return weaponPDA
}
// Hashlist bobos : hashlist weapons
// Loop through if a hash weapon is in wallet then add airdrop info. If add fail add the HB:HW to new array