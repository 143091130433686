import {
    Connection,
    PublicKey,
    ConfirmOptions
} from "@solana/web3.js"
import * as anchor from "@project-serum/anchor";
import {
  Program, Wallet, web3, BN, Idl, Provider
} from '@project-serum/anchor';

import stakingProgramIdl from "../OnChain/Programs/StakingProgram/idl/nft_staking.json"

const STAKING_PROGRAM_ID = new PublicKey(
    stakingProgramIdl.metadata.address
);


const opts: ConfirmOptions = {
    preflightCommitment: "processed"
}
async function getProvider(connection: Connection, wallet: Wallet) {
    const provider = new Provider(
      connection, wallet, opts,
    );
    return provider;
  }

export async function getAllStakers(
    connection: Connection,
    wallet: Wallet
): Promise<number>  {
    const provider = await getProvider(connection, wallet);
    const program = new Program(stakingProgramIdl as Idl, STAKING_PROGRAM_ID, provider);
    let programAccounts = await program.account.stakeData.all(); // ignore the vault data issue
    let ownersList: string[] = [];
  
    for(let account of programAccounts) {
      let owner = account.account.staker.toString()
    //   let owner = new PublicKey(data.slice(8, 40)).toString()
        // let owner = account.staker
    //   console.log(owner)
    //   let mint = new PublicKey(account.account.data.slice(40, 72)).toString();

      if(ownersList.includes(owner)) {
        continue;
      }
      ownersList.push(owner)
    }

    // console.log(programAccounts.length)
    // console.log(ownersList.length)

    return ownersList.length;
}

export async function getTotalHolders(
    connection: Connection,
    wallet: Wallet
): Promise<number> {
    const numStakers = await getAllStakers(connection, wallet)
    const numNonStakers = await fetch("https://api-mainnet.magiceden.io/rpc/getCollectionHolderStats/bobos_of_war?edge_cache=true").then(async (response) => await response.json()).then(data => data.results.uniqueHolders)
    
    const totalHolders = numStakers + numNonStakers
    console.log(`Total Holders count: ${totalHolders}`)
    return totalHolders
}

export interface CollectionStats {
    floorPrice: number,
    listed: number,
    volume: number
}

export async function getCollectionStats(): Promise<CollectionStats> {
    const response = await fetch("https://api-mainnet.magiceden.dev/v2/collections/bobos_of_war/stats")
    const data = await response.json()

    const collectionStats: CollectionStats = {
        floorPrice: Math.round(data.floorPrice * Math.pow(10, -9) * 100) / 100,
        listed: data.listedCount,
        volume: Math.round(data.volumeAll * Math.pow(10, -9) * 100) / 100,
    } 

    return collectionStats
}

export interface CollectionInfo {
  collectionStats: CollectionStats,
  totalHolders: number
}

export async function getCollectionInfo(): Promise<CollectionInfo> {
  const response = await fetch("https://bow-api-czdcwk4zsa-uc.a.run.app/api/metrics/collectionInfo");
  const data = (await response.json()).collectionInfoData as CollectionInfo;
  console.log(data);

  return data;

  // const collectionStats: CollectionStats = {
  //   floorPrice: data.collectionStats.floorPrice,
  //   listed: data.collectionStats.listed,
  //   volume: data.collectionStats.volume,
  // }
}