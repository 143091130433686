import {
    Connection, 
    PublicKey,
} from "@solana/web3.js";

import {
    Program, Wallet, Idl
} from '@project-serum/anchor';

import { BOBO_METADATA_PROGRAM_ID } from "../OnChain/programsInfo"
import boboMetadataIdl from "../OnChain/Programs/BoboMetadata/idl/bobo_metadata.json"
import { getProvider, getBoboMetadata } from "../OnChain/utils"
import { TrackerData } from "../OnChain/chainInfo";

import {
    MetadataData,
} from "@metaplex-foundation/mpl-token-metadata";
import { programs } from '@metaplex/js';
const { metadata: { Metadata } } = programs;

export async function getBoboTrackerData(
    connection: Connection,
    wallet: Wallet,
    boboMint: PublicKey
): Promise<TrackerData> {
    let provider = await getProvider(connection, wallet)
    let metadataProgram = new Program(boboMetadataIdl as Idl, BOBO_METADATA_PROGRAM_ID, provider);

    let metadataPDA = await getBoboMetadata(boboMint)
    let fetchedMetadata = await metadataProgram.account.boboMetadata.fetch(metadataPDA)
    return {
        mint: boboMint,
        combatPoints: fetchedMetadata.combatPoints,
        defensePoints: fetchedMetadata.defensePoints,

        arenaLevel: fetchedMetadata.arenaLevel,
        arenaXp: fetchedMetadata.arenaXp,
        prestigeLevel: fetchedMetadata.prestigeLevel,

        rarityLevel: fetchedMetadata.rarityLevel,
        kingdomRank: fetchedMetadata.kingdomRank,

        perks: fetchedMetadata.perks,
        specialAbility: fetchedMetadata.specialAbility,

        gamesWon: fetchedMetadata.gamesWon,
        tournamentsWon: fetchedMetadata.tournamentsWon,
        gamesPlayed: fetchedMetadata.gamesPlayed,
        minutesPlayed: fetchedMetadata.minutesPlayed,
        kills: fetchedMetadata.kills,
        deaths: fetchedMetadata.deaths,

        claimedWeapon: false,
        builtHouse: false,
    }
}

export async function getBoboImageUrl(
    connection: Connection,
    boboMint: PublicKey
): Promise<string> {
    let metadataPDA = await Metadata.getPDA(boboMint)
    let metadata = await Metadata.getInfo(connection, metadataPDA)

    let metadataData
    if(metadata?.data != undefined) {
        metadataData = MetadataData.deserialize(metadata?.data)
    }
    let imageUrl = (await fetch(metadataData.data.uri).then(response => response.json())).image
    return imageUrl
}