import "./CitizenInfoPanel.css"
import { FC, useEffect, useState, useRef } from "react";

import CitizenCircle from "./CitizenCircle"
import LawContainer from "./LawContainer"
import LawCommentContainer from "./LawCommentContainer"

import { getCitizenLawCreations, getCitizenLawVotes, getCitizenLawComments } from "../../utils/daoVoting"
import { NftData, LawProposition, Law, Comment, VoteBallot, LawComment } from "../../OnChain/chainInfo"
import { delay } from "../../OnChain/utils"

import Spinner from "react-bootstrap/Spinner"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faCircleCheck, faCircleXmark, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { gsap } from "gsap";

const LawPanel: FC<any> = (props) => {
    const { connection, wallet, government, selectedCitizen, returnFunction } = props;

    const [createdLaws, setCreatedLaws] = useState<Law[]>()
    const [votedLaws, setVotedLaws] = useState<VoteBallot[]>()
    const [createdComments, setCreatedComments] = useState<LawComment[]>()

    const [createdLawsLoading, setCreatedLawsLoading] = useState(false)
    const [votedLawsLoading, setVotedLawsLoading] = useState(false)
    const [createdCommentsLoading, setCreatedCommentsLoading] = useState(false)

    const [actions, setActions] = useState("CreatedLaws")
    
    useEffect(() => {
        setActions("CreatedLaws")
    }, [selectedCitizen])

    useEffect(() => {
        if (actions === "CreatedLaws" && createdLaws === undefined) {
            fetchLawCreations()
        } else if (actions === "VotedLaws" && votedLaws === undefined) {
            fetchLawVotes()
        } else if (actions === "CreatedComments" && createdComments === undefined) {
            fetchLawComments()
        }
    }, [actions])

    async function fetchLawCreations() {
        setCreatedLawsLoading(true)
        setCreatedLaws(await getCitizenLawCreations(connection, wallet, selectedCitizen.mint))
        setCreatedLawsLoading(false)
    }
    async function fetchLawVotes() {
        setVotedLawsLoading(true)
        setVotedLaws(await getCitizenLawVotes(connection, wallet, selectedCitizen.mint, government.governmentPublicKey))
        setVotedLawsLoading(false)
    }
    async function fetchLawComments() {
        setCreatedCommentsLoading(true)
        setCreatedComments(await getCitizenLawComments(connection, wallet, selectedCitizen.mint, government.governmentPublicKey))
        setCreatedCommentsLoading(false)
    }

    return(
        // laws created
        // laws voted on
        // comments
        <div id="Citizen-Info-Panel" className="container-fluid d-flex flex-column px-lg-5">
            <FontAwesomeIcon icon={faArrowLeft} className="pointer-hover hover-text-selected-drop text-white align-self-start mb-4" onClick={returnFunction} size="2x" />
            <div className={"d-flex flex-column w-100 mx-auto my-3 p-3"}>
                <div className="d-flex">
                    <CitizenCircle class="citizen-info-image" connection={connection} wallet={wallet} nft={selectedCitizen} selected={true} />
                    <h1 className="text-white text-ussr ms-4 my-auto">{selectedCitizen.name}</h1>
                </div>
                <div className="row">
                    <div className="col-2 d-flex flex-column">
                        <button className={`btn citizen-action-filter-button ${actions === "CreatedLaws" ? "selected-gradient": ""} my-2`} onClick={() => setActions("CreatedLaws")}><h5 className="text-white">Created Laws</h5></button>
                        {/* <button className={`btn citizen-action-filter-button ${actions === "VotedLaws" ? "selected-gradient": ""} my-2`} onClick={() => setActions("VotedLaws")}><h5 className="text-white">Votes</h5></button> */}
                        <button className={`btn citizen-action-filter-button ${actions === "CreatedComments" ? "selected-gradient": ""} my-2`} onClick={() => setActions("CreatedComments")}><h5 className="text-white">Comments</h5></button>
                    </div>  
                    <div className="col-10 d-flex flex-column">
                        {actions === "CreatedLaws" ? !createdLawsLoading && createdLaws !== undefined ? createdLaws.length > 0 ? createdLaws.map(law => <LawContainer key={law.lawIndex} government={government} law={law} containerClass="row w-100 my-3" />) : <h3 className="text-white">Citizen hasn't created any laws yet.</h3> : <Spinner animation="border" className="text-white align-self-center" />
                        : actions === "VotedLaws" ? !votedLawsLoading && votedLaws !== undefined ? votedLaws.length > 0 ? votedLaws.map(voteBallot => <LawContainer key={voteBallot.law.lawIndex} government={government} law={voteBallot.law} selectedPropositionIndex={voteBallot.votePropositionIndex} containerClass="row w-100 my-3" />) : <h3 className="text-white">Citizen hasn't voted on any laws yet.</h3> : <Spinner animation="border" className="text-white align-self-center" />
                        : actions === "CreatedComments" ? !createdCommentsLoading && createdComments !== undefined ? createdComments.length > 0 ? createdComments.map(lawComment => <div className="d-flex flex-column w-100 my-3">
                            <LawContainer key={lawComment.law.lawIndex} government={government} law={lawComment.law} containerClass="row w-100" />
                            <LawCommentContainer key={lawComment.comment.commentIndex} comment={lawComment.comment} containerClass="mx-5 mt-3" />
                        </div>
                        ) : <h3 className="text-white">Citizen hasn't commented on any laws yet.</h3> : <Spinner animation="border" className="text-white align-self-center" /> : ""}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LawPanel
