import {
    Connection
} from "@solana/web3.js";

import * as anchor from "@project-serum/anchor";
import {
    Program, Provider, Wallet, web3, BN, Idl
} from '@project-serum/anchor';
import * as spl from '@solana/spl-token';

import { SWAP_VBOW_PROGRAM_ID } from "../OnChain/programsInfo"
import swapVBOWProgramIdl from "../OnChain/TokenSwap/idl/token_swap.json"
import { getProvider, getAssociatedTokenAddress, getPoolInfo } from "../OnChain/utils"
import { VBOW_MINT, BOW_MINT } from "../OnChain/chainInfo";

export async function swapVBOW(
    connection: Connection,
    wallet: Wallet,
): Promise<boolean | any> {
    let provider = await getProvider(connection, wallet)
    let program = new Program(swapVBOWProgramIdl as Idl, SWAP_VBOW_PROGRAM_ID, provider);

    let poolInfo = await getPoolInfo()

    const payerToken1Account = (await connection.getTokenAccountsByOwner(wallet.publicKey, {mint: VBOW_MINT})).value[0].pubkey;
    const payerToken2Ata = await getAssociatedTokenAddress(wallet.publicKey, BOW_MINT, true);

    let parsedPayerToken1Account = await connection.getParsedAccountInfo(payerToken1Account)
    if(parsedPayerToken1Account == null || parsedPayerToken1Account.value == null) return
    let data: any = parsedPayerToken1Account.value.data
    let swapAmount = data.parsed.info.tokenAmount.uiAmount;

    const poolToken1Ata = await getAssociatedTokenAddress(poolInfo, VBOW_MINT, true)
    const poolToken2Ata = await getAssociatedTokenAddress(poolInfo, BOW_MINT, true)
    console.log(swapAmount)
    try {
        await program.rpc.swap(new BN(swapAmount * Math.pow(10, 9)), {
            accounts: {
              poolInfo,
              payer: wallet.publicKey,
          
              token1: VBOW_MINT,
              payerToken1Account,
              poolToken1Ata,
      
              token2: BOW_MINT,
              payerToken2Ata,
              poolToken2Ata,
          
              tokenProgram: spl.TOKEN_PROGRAM_ID,
              associatedTokenProgram: spl.ASSOCIATED_TOKEN_PROGRAM_ID,
              rent: anchor.web3.SYSVAR_RENT_PUBKEY,
              systemProgram: anchor.web3.SystemProgram.programId
            },
        })
    } catch (err) {
        console.log(err)
        return err
    }
    return true
}