import "./TokenomicsHandler.css";
import React, { FC, useEffect, useState, useReducer } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import {ReactComponent as TokenFlow} from '../../images/TokenFlow.svg';


ChartJS.register(ArcElement, Tooltip, Legend);
const totalAllocationData = {
    labels: ['Team', 'Advisors', 'Continents Initial Reserve', 'Staking Rewards', 'P2E', 'Treasury', "Reserve"],
    datasets: [
      {
        label: 'Total Allocation Split',
        data: [9, 1, 11, 25, 19, 21, 14],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(80, 100, 220, 0.2)',
          'rgba(40, 170, 50, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(80, 100, 220, 1)',
          'rgba(40, 170, 50, 1)',
        ],
        borderWidth: 1,
      },
    ],
};

const teamAllocationData = {
    labels: ['Founders', 'Advisors'],
    datasets: [
      {
        label: 'Team Allocation Split',
        data: [90, 10],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        //   'rgba(255, 206, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(80, 100, 220, 0.2)',
        //   'rgba(40, 170, 50, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(80, 100, 220, 1)',
        //   'rgba(40, 170, 50, 1)',
        ],
        borderWidth: 1,
      },
    ],
};

const ecosystemAllocationData = {
    labels: ['Continent Initial Reserve', 'Staking Rewards', 'P2E'],
    datasets: [
      {
        label: 'Ecosystem Allocation Split',
        data: [20, 45.5, 34.5],
        backgroundColor: [
        //   'rgba(255, 99, 132, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        //   'rgba(80, 100, 220, 0.2)',
        //   'rgba(40, 170, 50, 0.2)',
        ],
        borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        //   'rgba(80, 100, 220, 1)',
        //   'rgba(40, 170, 50, 1)',
        ],
        borderWidth: 1,
      },
    ],
};

const reserveAllocationData = {
    labels: ['Reserve', 'Treasury'],
    datasets: [
      {
        label: 'Reserve Allocation Split',
        data: [40, 60],
        backgroundColor: [
          'rgba(80, 100, 220, 0.2)',
          'rgba(40, 170, 50, 0.2)',
        ],
        borderColor: [
          'rgba(80, 100, 220, 1)',
          'rgba(40, 170, 50, 1)',
        ],
        borderWidth: 1,
      },
    ],
};

const TokenomicsHandler: FC<any> = (props) => {
    const { connection, wallet } = props;

    return (
        <div id="Tokenomics-Handler" className="mt-5 pt-5">
            <div className="row d-flex text-white mb-4">
                <h1 className="text-underline">Total Supply</h1>
                <h1 className="title text-selected tokenomics-text-shadow">300.000.000 BOW</h1>
            </div>
            <div className="row d-flex justify-content-center text-white mt-5 px-3">
                <h1 className="text-start text-underline ms-5">1. Token Allocation</h1>
                <div className="col-10 col-sm-8 col-md-6 col-lg-4 mt-4">
                    <Pie id="Total-Allocation-Split-Pie" data={totalAllocationData} />
                </div>
            </div>
            <div className="row d-flex justify-content-center text-white mt-4">
                {/* // TEAM */}
                <div className="col-12 col-md-6 col-lg-4 row justify-content-center m-3 m-lg-0">
                    <h3 className="text-underline">Team</h3>
                    <h2 className="text-selected tokenomics-text-shadow">10%</h2>
                    <div className="col-6">
                        <h5>Founders</h5>
                        <p>9% (27.000.000)</p>
                    </div>
                    <div className="col-6">
                        <h5>Advisors</h5>
                        <p>1% (3.000.000)</p>
                    </div>
                    <div className="col-6">
                        <Pie data={teamAllocationData} />
                    </div>
                </div>
                {/* // ECOSYSTEM */}
                <div className="col-12 col-md-6 col-lg-4 row justify-content-center m-3 m-lg-0">
                    <h2 className="text-underline">Ecosystem</h2>
                    <h1 className="text-selected tokenomics-text-shadow">55%</h1>
                    <div className="col-4">
                        <h5>Continents Initial Reserve</h5>
                        <p>11% (33.000.000)</p>
                    </div>
                    <div className="col-4">
                        <h5>Staking Rewards</h5>
                        <p>25% (75.075.000)</p>
                    </div>
                    <div className="col-4">
                        <h5>P2E Rewards</h5>
                        <p>19% (56.925.000)</p>
                    </div>
                    <div className="col-6">
                        <Pie data={ecosystemAllocationData} />
                    </div>
                </div>
                {/* // RESERVE */}
                <div className="col-12 col-md-6 col-lg-4 row justify-content-center m-3 m-lg-0">
                    <h3 className="text-underline">Reserve & Treasury</h3>
                    <h2 className="text-selected tokenomics-text-shadow">35%</h2>
                    <div className="col-6">
                        <h5>Reserve</h5>
                        <p>14% (42.000.000)</p>
                    </div>
                    <div className="col-6">
                        <h5>Treasury</h5>
                        <p>21% (63.000.000)</p>
                    </div>
                    <div className="col-6">
                        <Pie data={reserveAllocationData} />
                    </div>
                </div>
            </div>

            <div className="row d-flex justify-content-center text-white px-5" style={{marginTop: "10rem"}}>
                <h1 className="text-start text-underline">2. BOBO & BOW Token Flow (P1)</h1>
                <TokenFlow  />
            </div>

            <p className="text-danger text-underline" style={{marginTop: "15rem"}}><b>ALL</b> information regarding tokenomics is subject to slight change until release of the final version.</p>
        </div>
    )
}

export default TokenomicsHandler;