import "./TrackerHandler.css"
import { FC, useEffect, useState, useReducer } from "react";

import {
    Connection, 
    PublicKey,
} from "@solana/web3.js";

import { getBoboTrackerData, getBoboImageUrl } from "../../utils/tracker"
import { TrackerData } from "../../OnChain/chainInfo"
import {canBoboClaimWeapon, getBoboIdWeapon, claimBoboWeapon} from "../../utils/weaponsClaim"

import Spinner from "react-bootstrap/Spinner"
import Modal from 'react-bootstrap/Modal';

import PopoverContainer from '@idui/react-popover'

import soldierIcon from "../../images/icons/soldier-icon.png"
import civilianIcon from "../../images/icons/civilian-icon.png"
import combatIcon from "../../images/icons/combat-icon.png"
import defenseIcon from "../../images/icons/defense-icon.png"
import arenaLevelIcon from "../../images/icons/arena-level-icon.png"
import prestige0Icon from "../../images/icons/prestige-0-icon.png"
import {ReactComponent as RaritySvg} from "../../images/icons/rarity.svg"
import MEIcon from "../../images/icons/meIcon.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faGun, faSuitcase, faXmark } from "@fortawesome/free-solid-svg-icons";

let namesToHash = require("./hashNames")

const gameInfoKeys = ["Combat Points", "Defense Points", "Arena Level", "Arena Xp", "Prestige Level", "Rarity Level", "Kingdom Rank"]
const gameStatsKeys = ["Games", "Wins", "Tourney Wins", "Minutes", "Kills", "Deaths"]

const Tracker: FC<any> = (props) => {
    const { connection, wallet } = props;

    const [selectedBoboMint, setSelectedBoboMint] = useState<PublicKey>()
    const [selectedBoboId, setSelectedBoboId] = useState<number>()

    const [trackerData, setTrackerData] = useState<TrackerData>()
    const [canClaimWeapon, setCanClaimWeapon] = useState(false)
    const [displayedInfo, setDisplayedInfo] = useState<any>()
    const [imageUrl, setImageUrl] = useState<string>()

    const [loadingData, setLoadingData] = useState<boolean>()
    const [loadingImage, setLoadingImage] = useState<boolean>()

    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>()

    const [isClaimingWeapon, setIsClaimingWeapon] = useState<boolean>(false)


    useEffect(() => {
        setSelectedBoboId(Math.floor(Math.random() * 8499) + 1)
    }, [])

    useEffect(() => {
        if(searchTimeout) clearTimeout(searchTimeout)

        let timeout = setTimeout(setMintFromId, 1000)
        setSearchTimeout(timeout)
        setIsClaimingWeapon(false)
    }, [selectedBoboId])

    useEffect(() => {
        if(selectedBoboMint !== undefined) {
            getTrackerData(new PublicKey(selectedBoboMint))
            getImageUrl(new PublicKey(selectedBoboMint))
            checkCanClaimWeapon()
        }
    }, [selectedBoboMint])

    useEffect(() => {
        setDisplayedInfo({
            "Combat Points": trackerData?.combatPoints,
            "Defense Points": trackerData?.defensePoints,
            "Arena Level": trackerData?.arenaLevel,
            "Arena Xp": trackerData?.arenaXp,
            "Prestige Level": trackerData?.prestigeLevel,

            "Rarity Level": trackerData?.rarityLevel,
            "Kingdom Rank": trackerData?.kingdomRank,

            "Perks": trackerData?.perks,
            "Special Ability": trackerData?.specialAbility,

            "Games": trackerData?.gamesPlayed,
            "Wins": trackerData?.gamesWon,
            "Tourney Wins": trackerData?.tournamentsWon,
            "Minutes": trackerData?.minutesPlayed,
            "Kills": trackerData?.kills,
            "Deaths": trackerData?.deaths,
        })
    }, [trackerData])

    async function setMintFromId() {
        const selectedName = "BOBO #" + selectedBoboId?.toString()
        for(let [name, mint] of namesToHash) {
            if(name == selectedName) {
                setSelectedBoboMint(mint)
                return
            }
        }
    }

    async function getTrackerData(bobo: PublicKey) {
        setLoadingData(true)
        let data = await getBoboTrackerData(connection, wallet, bobo)
        setTrackerData(data)
        setLoadingData(false)
    }

    async function getImageUrl(bobo: PublicKey) {
        setLoadingImage(true)
        let fetchedImageUrl = await getBoboImageUrl(connection, bobo)
        setImageUrl(fetchedImageUrl)
        setLoadingImage(false)
    }

    async function checkCanClaimWeapon() {
        if(selectedBoboMint === undefined || selectedBoboId === undefined) {setCanClaimWeapon(false); return}
        setCanClaimWeapon(await canBoboClaimWeapon(connection, wallet, selectedBoboMint.toString(), selectedBoboId.toString()))
    }


    async function claimWeapon() {
        if(selectedBoboMint === undefined) return
        console.log("claim weapon")
        const claimedWeapon = await claimBoboWeapon(connection, wallet, new PublicKey(selectedBoboMint))
        setIsClaimingWeapon(!claimedWeapon)
    }
        
    return(
        <div id="Tracker-App">
            <div className="container-fluid">
                <div id="bobo-tracking-container" className="row mx-5 p-4">
                    {/* <div className="col-12 d-flex justify-content-start align-items-center mb-3">
                        <FontAwesomeIcon className="image-style-selector cursor-error-hover disabled me-3" icon={faSuitcase} size="2x" style={{borderRadius: "50%"}} />
                    </div> */}
                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                        {!loadingImage ? <div style={{position: "relative", width: "fit-content", height: "fit-content"}}>
                            <PopoverContainer className="p-2" animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter withArrow={false} content="Soldier" fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}}><img id="bobo-type-icon" className="bobo-tracker-icon me-3" src={soldierIcon} /></PopoverContainer>
                            <PopoverContainer className="p-2" animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter withArrow={false} content="Prestige" fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}}><img id="bobo-prestige-icon" className="bobo-tracker-icon me-3" src={prestige0Icon} /></PopoverContainer>
                            {/* <FontAwesomeIcon className="position-absolute image-style-selector selector-selected pointer-hover me-3" icon={faGun} size="2x" style={{zIndex: 10, borderRadius: "50%", top: "10px", right: "10px"}} /> */}
                            <img id="bobo-img" src={imageUrl} />
                        </div>: <Spinner animation="border" className="align-self-center" />}
                    </div>
                    <div className="col-12 col-lg-6 flex-column mt-4 mt-lg-0">
                        <div className="d-flex justify-content-between">
                            <h1 className="text-start text-white">Bobo #<input id="bobo-id-input" className="mb-5 ms-2" type="number" value={selectedBoboId?.toString()} min={0} max={8499} onChange={(e) => setSelectedBoboId(Number(e.target.value))}></input></h1>
                            {selectedBoboMint !== undefined && <a href={`https://magiceden.io/item-details/${selectedBoboMint.toString()}`} target="_blank" rel="noopener noreferrer"><img src={MEIcon} style={{height: "50px", width: "50px"}} /></a>}
                        </div>
                        <h2 className="text-start text-white text-ussr">Game Info</h2>
                        {!loadingData ? <div id="bobo-game-info-container" className="row text-white">
                            {displayedInfo !== undefined && <>
                                <div className="col-6 col-xs-4 col-sm-3 d-flex justify-content-center mb-3">
                                    <h5 className="m-0">{displayedInfo["Combat Points"]}</h5>
                                    <PopoverContainer className="p-2" animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter withArrow={false} content="Combat" fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}}><img src={combatIcon} className="bobo-tracker-icon ms-3" /></PopoverContainer>
                                </div>
                                <div className="col-6 col-xs-4 col-sm-3 d-flex justify-content-center mb-3">
                                    <h5 className="m-0">{displayedInfo["Defense Points"]}</h5>
                                    <PopoverContainer className="p-2" animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter withArrow={false} content="Defense" fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}}><img src={defenseIcon} className="bobo-tracker-icon ms-3" /></PopoverContainer>
                                </div>
                                <div className="col-6 col-xs-4 col-sm-3 d-flex justify-content-center mb-3">
                                    <h5 className="m-0">{displayedInfo["Arena Level"]}</h5>
                                    <PopoverContainer className="p-2" animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter withArrow={false} content="Arena Level" fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}}><img src={arenaLevelIcon} className="bobo-tracker-icon ms-3" /></PopoverContainer>
                                </div>
                                <div className="col-6 col-xs-4 col-sm-3 d-flex justify-content-center mb-3">
                                    <h5 className="m-0">{displayedInfo["Rarity Level"]}</h5>
                                    <PopoverContainer className="p-2" animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter withArrow={false} content="Rarity" fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}}><RaritySvg className="bobo-tracker-icon ms-3" style={{width: "20px", height: "20px"}} /></PopoverContainer>
                                </div>
                            </>}
                            {/* {displayedInfo !== undefined && Object.entries(displayedInfo).map((entry) => gameInfoKeys.includes(entry[0]) && <div key={entry[0]} className="col-3 mb-3">
                                <h4>{entry[0]}</h4>
                                <h6> {entry[1]}</h6>
                            </div>)} */}
                        </div>: <Spinner animation="border" className="align-self-center" />}
                        <h2 className="text-start text-white text-ussr">Stats</h2>
                        {!loadingData ? <div className="row text-white">
                            {displayedInfo !== undefined && Object.entries(displayedInfo).map((entry) => gameStatsKeys.includes(entry[0]) && <div key={entry[0]} className="col-6 col-xs-4 col-sm-3 mb-3">
                                <h4>{entry[0]}</h4>
                                <h6> {entry[1]}</h6>
                            </div>)}
                        </div>: <Spinner animation="border" className="align-self-center" />}
                        <h2 className="text-start text-white text-ussr mt-5 pt-5 mb-4">Available Claims</h2>
                        {!loadingData ? <div id="available-claims-container" className="row text-white">
                            <div className="col-12 col-md-6">
                            {!canClaimWeapon ? <PopoverContainer className="p-2" animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter withArrow={false} content={"No hand item or unreleased weapon"} fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}}><button className={`btn ${canClaimWeapon ? "selected-gradient" : "btn-disabled"} w-100 text-white px-auto`} disabled={!canClaimWeapon} onClick={() => setIsClaimingWeapon(true)}>
                                    <h3 className="mb-0">Weapons {trackerData?.claimedWeapon ? <FontAwesomeIcon className="icon-failed" icon={faCircleXmark} size="1x" style={{borderRadius: "50%"}} />: <FontAwesomeIcon className="text-white white-glow-shadow" icon={faCircleXmark} size="1x" style={{borderRadius: "50%"}} />}</h3>
                                </button>
                                </PopoverContainer> : <button className={`btn ${canClaimWeapon ? "selected-gradient" : "btn-disabled"} w-100 text-white px-auto`} disabled={!canClaimWeapon} onClick={() => setIsClaimingWeapon(true)}>
                                    <h3 className="mb-0">Weapons {trackerData?.claimedWeapon ? <FontAwesomeIcon className="icon-failed" icon={faCircleXmark} size="1x" style={{borderRadius: "50%"}} />: <FontAwesomeIcon className="text-white white-glow-shadow" icon={faCircleCheck} size="1x" style={{borderRadius: "50%"}} />}</h3>
                                </button>}
                            </div>
                            <div className="col-12 col-md-6 mt-3 mt-md-0">
                                <button className="btn btn-disabled w-100 text-white" disabled>
                                    <h3 className="mb-0">Real Estate {trackerData?.builtHouse ? <FontAwesomeIcon className="icon-failed" icon={faCircleXmark} size="1x" style={{borderRadius: "50%"}} />: <FontAwesomeIcon className="text-white white-glow-shadow" icon={faCircleXmark} size="1x" style={{borderRadius: "50%"}} />}</h3>
                                </button>
                            </div>
                        </div>: <Spinner animation="border" className="align-self-center" />}
                    </div>
                </div>
            </div>
            <Modal id="weapon-claim-modal" show={isClaimingWeapon} onHide={() => setIsClaimingWeapon(false)} centered>
                <Modal.Header className="d-flex justify-content-end align-items-center ps-0 pe-2 py-2">
                    <FontAwesomeIcon icon={faXmark} size="2x" className="pointer-hover" onClick={() => setIsClaimingWeapon(false)} />
                </Modal.Header>
                <Modal.Body>
                    {selectedBoboId !== undefined &&
                    <div className="d-flex flex-column justify-content-center">
                        <p className="text-center">Claim Bobo #{selectedBoboId}'s <strong>{getBoboIdWeapon(selectedBoboId.toString())}</strong> for 220 BOW?</p>
                        <button className="btn selected-gradient" onClick={claimWeapon}><h4 className="m-0 text-white">Confirm</h4></button>
                    </div>}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Tracker