import "./LawContainer.css"
import { FC, useEffect, useState } from "react";

import { LawProposition } from "../../OnChain/chainInfo"
import { shortenAddress } from "../../OnChain/utils"


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import {LawState, checkIfWon} from "./LawPanel"

enum TimeFormat {
    SECONDS,
    MINUTES,
    HOURS,
    DAYS
}

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));



const LawContainer: FC<any> = (props) => {
    const { id, onClick, government, law, selectedPropositionIndex, containerClass, solClockDrift } = props;

    const [lawState, setLawState] = useState<LawState>(LawState.UPCOMING)
    const [timeLeft, setTimeLeft] = useState(0)
    const [currentTimeout, SetCurrentTimeout] = useState<NodeJS.Timeout>()

    function getTimeLeft() {
        let currentTime = Math.floor(Date.now() / 1000) - solClockDrift
        let startTimeDifference = law.startTime - currentTime
        if(startTimeDifference > 0) {
            setLawState(LawState.UPCOMING)
            setTimeLeft(startTimeDifference)
            let timeout = setTimeout(getTimeLeft, 1000)
            SetCurrentTimeout(timeout)
            return true
        }
        let endDate = law.startTime + law.daysDuration * 86400
        let endTimeDifference = endDate - currentTime
        if (endTimeDifference > 0) {
            setLawState(LawState.STARTED)
            setTimeLeft(endTimeDifference)
            let timeout = setTimeout(getTimeLeft, 1000)
            SetCurrentTimeout(timeout)
            return true
        } else {
            setTimeLeft(currentTime - endDate)
            let timeout = setTimeout(getTimeLeft, 1000)
            SetCurrentTimeout(timeout)

            if (law.lawConfirmed) {
                setLawState(LawState.PASSED)
                return false
            }
            setLawState(LawState.FAILED)
            return false
        }
    }

    function getFormattedTime(seconds: number, timeFormat: TimeFormat) {
        let formattedTime;
        switch (timeFormat) {
            case TimeFormat.SECONDS:
                formattedTime = seconds % 60
                break;
            case TimeFormat.MINUTES:
                formattedTime = Math.floor(seconds / 60) % 60
                break;
            case TimeFormat.HOURS:
                formattedTime = Math.floor(seconds / 60 / 60) % 24
                break;
            case TimeFormat.DAYS:
                formattedTime = Math.floor(seconds / 60 / 60 / 24)
                break;
        }
        return formattedTime.toString().padStart(2, "0");
    }

    useEffect(() => {
        getTimeLeft()
        if(currentTimeout != undefined) clearTimeout(currentTimeout)

        return () => {
            if(currentTimeout != undefined) clearTimeout(currentTimeout)
          }        
    }, [law])

    let timeDiv = <div>
                    <span className="timer-card text-ussr p-1 mx-1">{getFormattedTime(timeLeft, TimeFormat.DAYS)}</span>
                    <span className="timer-card text-ussr p-1 mx-1">{getFormattedTime(timeLeft, TimeFormat.HOURS)}</span>
                    <span className="timer-card text-ussr p-1 mx-1">{getFormattedTime(timeLeft, TimeFormat.MINUTES)}</span>
                    <span className="timer-card text-ussr p-1 mx-1">{getFormattedTime(timeLeft, TimeFormat.SECONDS)}</span>
                </div>
    return(
        <div id={id} className={"law-container " + containerClass} onClick={onClick}>
            <div className="col-9 d-flex flex-column align-items-start p-3" style={{wordWrap: "break-word"}}>
                <h2>{law.title} <span className="badge rounded-pill selected-gradient text-white" style={{fontSize: ".8rem"}}>{law.topicTag}</span></h2>
                <p className="w-100 text-start">{law.description}</p>
                <b>Propositions:</b>
                <div className="d-flex flex-column w-100">
                {law.propositions.map((proposition: LawProposition, index: number) => {
                    let selected = selectedPropositionIndex !== undefined && selectedPropositionIndex === index
                    return <div key={index} className={`d-flex justify-content-between ${selected ? "text-selected text-bold": ""} w-100 my-2`}>
                            <span className="text-start" style={{maxWidth: "90%"}}>{index + "."} {proposition.title}</span>{lawState !== LawState.UPCOMING && <span className="d-flex align-items-center">{lawState === LawState.STARTED && <FontAwesomeIcon className={`${selected ? "text-selected selected-drop-shadow": ""} me-2`} icon={faArrowUp} />} {proposition.votes}</span>}
                        </div>
                })}
                </div>
            </div>
            <div className="col-3 d-flex flex-column align-items-end p-3">
                <img className="img-fluid creator-img mb-2" src={law.creatorBobo.imageUrl} />
                {/* <h6 className="text-ussr text-end">{shortenAddress(law.creatorBobo.toString())}</h6> */}
                <h6 className="text-ussr text-end">{law.creatorBobo.name}</h6>
                <div className="d-flex flex-column justify-content-center align-items-end w-100 mt-4">
                    {lawState == LawState.PASSED && <FontAwesomeIcon className="text-selected selected-shadow me-3 mb-3" icon={faCircleCheck} size="2x" style={{borderRadius: "50%"}} />}
                    {lawState == LawState.FAILED && <FontAwesomeIcon className="icon-failed me-3 mb-3" icon={faCircleXmark} size="2x" style={{borderRadius: "50%"}} />}
                    {lawState == LawState.UPCOMING && <>
                    <h6>Starts In</h6>
                    {timeDiv}
                    </>}
                    {lawState == LawState.STARTED && <>
                    <h6>Ends In</h6>
                    {timeDiv}
                    </>}
                    {(lawState == LawState.PASSED || lawState == LawState.FAILED) && <>
                    <h6>Ended</h6>
                    {timeDiv}
                    </>}
                </div>
            </div>
        </div>
    )
}

export default LawContainer
