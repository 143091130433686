import './App.css';
import ToolsHandler from "./ToolsHandler.tsx"
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
// import { clusterApiUrl } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo } from 'react';

require('@solana/wallet-adapter-react-ui/styles.css');

const App = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;
    // const network = WalletAdapterNetwork.Devnet;

    // You can also provide a custom RPC endpoint.
    // const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    // const endpoint = "https://ssc-dao.genesysgo.net/";
    // const endpoint = "https://thrumming-shy-cherry.solana-mainnet.quiknode.pro/0f4b1c8dc5ba35837d42d51f99cfd8e4941d4a5d/"
    const endpoint = "https://hidden-falling-research.solana-mainnet.discover.quiknode.pro/7fe95d99a9aaed6ade5d9c7e802ae1adf359974c/"

    // const endpoint = "https://cold-small-meadow.solana-mainnet.quiknode.pro/4d5ba9470c46ab34821fae34c32664c71ca5c40f/";
    // const endpoint = "https://psytrbhymqlkfrhudd.dev.genesysgo.net:8899/"

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletWalletAdapter({ network }),
            new SolletExtensionWalletAdapter({ network }),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                <div id='App'>
                  <ToolsHandler network={network} endpoint={endpoint} wallets={wallets} />
                </div>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default App;