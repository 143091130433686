import { PublicKey } from "@solana/web3.js";
import { BN } from '@project-serum/anchor';

// export const CANDY_MACHINE_ADDRESS = new PublicKey("24w23V4d2xZgHjkupD7WgmgzAwxjomCLKP2ti2VAv2NT") // Devnet
export const CANDY_MACHINE_ADDRESS = new PublicKey("5VB5StUrvwMLirDcfrXQDMVLM3GSAdaZ32oLxaHKqiV8") // mainnet
// export const REWARD_MINT = new PublicKey("6isPCSZf1ZQdwHMTuRMG9q4DZPKsjFog5o3HM4XCuCc9") // vBOW
export const VBOW_MINT = new PublicKey("6isPCSZf1ZQdwHMTuRMG9q4DZPKsjFog5o3HM4XCuCc9")

// export const BOW_MINT = new PublicKey("7HfSrp85GA9yVUXzrer75XVqsYnaLazBrbV3Tii8qD6M") // devnet
export const BOW_MINT = new PublicKey("BoBoWy2Z4QvyZRw7JdNS1dxXPA56DNrxCX97YEzobcA6") // mainnet


export interface VaultData {
    address: PublicKey,
    admin: PublicKey,
    rewardMint: PublicKey,
    rewardDecimals: BN,
    rarityBrackets: BN[],
    rarityMultipliers: BN[], // rarity_bracket_identifier: multiplier
    lockedDurations: BN[], // Duration: multiplier
    durationMultipliers: BN[],
    minLockupPeriod: BN, // in seconds /// Not currently used
    baseRewardRate: BN, // Tokens per second
    overLockMultiplier: BN, // rewards earned after the lock duration get multiplied by this
    overLockMultiplierDecimals: BN,

    collectionCandyMachine: PublicKey,
    numStaked: BN
}

export interface NftData {
    mint: PublicKey;
    name: string;
    uri: any;
    imageUrl: string;
    stakedData: StakedData;
    campData: CampData;
    gameData: GameData;
}
export interface StakedData {
    staked: boolean;
    timestamp: number;
    withdrawn: number;
    rewardMultiplier: number;
    rarityBracket: number;
    lockedDuration: number;
    dailyRate: number;
    currentRewards: number;
}
export interface CampData {
    camped: boolean;
    additionalPoints: number;
    endDate: number;
    trainingType: string;
}

export interface GameData {
    playable: boolean;
    combatPoints: number;
    defensePoints: number;
    arenaLevel: number;
    xp: number;
    branch: string;
}


export interface TrackerData {
    mint: PublicKey,
    combatPoints: number,
    defensePoints: number,

    arenaLevel: number,
    arenaXp: number,
    prestigeLevel: number,

    rarityLevel: number,
    kingdomRank: number,

    perks: string[],
    specialAbility: string,

    // Performance
    gamesWon: number,
    tournamentsWon: number,
    gamesPlayed: number,
    minutesPlayed: number,
    kills: number,
    deaths: number,

    // Claims
    claimedWeapon: boolean,
    builtHouse: boolean
}





export interface GovernmentData {
    governmentPublicKey: PublicKey,
    name: string,
    presidentBobo: PublicKey,
    leaders: PublicKey[],

    lawQuorum: number,
    lawFee: number,
    lawReward: number,
    feeMint: PublicKey,

    lawCreationOpen: boolean,
    minLawDaysDuration: number,
    maxLawDaysDuration: number,

    maxLawTitleLength: number,
    maxLawDescriptionLength: number,
    maxPropositions: number,
    maxPropositionTitleLength: number,
    maxLawCommentLength: number,

    votersCollectionCandyMachine: PublicKey,
}

export interface LawCreatorBobo {
    mint: PublicKey,
    name: string,
    imageUrl: string
}

export interface Law {
    lawPublicKey: PublicKey,
    creatorBobo: LawCreatorBobo,
    lawIndex: number,
    title: string,
    description: string,
    propositions: LawProposition[],
    topicTag: string,

    startTime: number,
    daysDuration: number,

    lawConfirmed: boolean,

    numComments: number
}

export interface LawProposition {
    title: string,
    votes: number
}

export interface Comment {
    commentPublickey: PublicKey,
    commentIndex: number,
    comment: String,
    commenterBobo: LawCreatorBobo,
    commentTimestamp: number,
    numLikes: number,
    numDislikes: number
}

export interface LawComment {
    law: Law,
    comment: Comment
}

export interface VoteBallot {
    law: Law,
    votePropositionIndex: number,
    voterBobo: PublicKey
}