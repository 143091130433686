import "./DAOVotingHandler.css"
import { FC, useEffect, useState, useRef } from "react";

import CitizenCircle from "./CitizenCircle"
import LawContainer from "./LawContainer"
import LawPanel, { LawState, checkIfWon } from "./LawPanel"
import LawCreationPanel from "./LawCreationPanel"
import CitizenInfoPanel from "./CitizenInfoPanel"
import tokenLogo from "../../images/bow.png"

import { getVaultData } from "../../utils/newStaking"
import { getGovernmentData, getAllLaws, getSolTime } from "../../utils/daoVoting";
import { getOwnerNfts, getWalletNfts } from "../../utils/NewBridge"
import { VaultData, NftData, GovernmentData, Law } from "../../OnChain/chainInfo"
import { delay, shortenAddress } from "../../OnChain/utils"
import { swapVBOW } from "../../utils/tokenSwap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faStopwatch, faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import rotatePhone from "../../images/phoneRotate.png"

import Spinner from "react-bootstrap/Spinner"


import { toast } from 'react-toastify';
import { gsap } from "gsap";


const DAOVotingHandler: FC<any> = (props) => {
    const { connection, wallet } = props;

    const [userConnected, setUserConnected] = useState(false)
    const [vaultData, setVaultData] = useState<VaultData>()
    const [bobos, setBobos] = useState<NftData[]>([])

    const [governmentData, setGovernmentData] = useState<GovernmentData>()
    const [laws, setLaws] = useState<Law[]>([])
    const [currentOrderLaws, setCurrentOrderLaws] = useState<Law[]>([])
    const [lawsOrder, setLawsOrder] = useState<LawState>(LawState.STARTED)

    const [loadingBobos, setLoadingBobos] = useState<boolean>(false)
    const [loadingLaws, setLoadingLaws] = useState<boolean>(false)

    const [selectedCitizen, setSelectedCitizen] = useState<NftData>()
    const [selectedLaw, setSelectedLaw] = useState<Law>()

    const [page, setPage] = useState("CPanel")
    const solClockDrift = useRef(0)

    useEffect(() => {
        if (wallet.connected && !wallet.connecting && !wallet.disconnecting && wallet.publicKey !== null) {
            setUserConnected(true)
        } else {
            setUserConnected(false)
        }
    }, [connection, wallet])

    useEffect(() => {
        async function setDatas() {
            setLoadingLaws(true)
            // try {
            let vault = await getVaultData(connection, wallet)
            console.log(vault)
            if (vault !== null) {
                setVaultData(vault)
            }
            console.log("Done")
            let gov = await getGovernmentData(connection, wallet)
            console.log(gov)
            if (gov !== null) {
                setGovernmentData(gov)
            }
            console.log("Done 2")
            let fetchedLaws = await getAllLaws(connection, wallet, gov)
            console.log(fetchedLaws)
            setLaws(fetchedLaws)

            // } catch (err) {
            //     console.error(err)
            // }

            setLoadingLaws(false)

        }
        setDatas()
    }, [])

    useEffect(() => {
        async function runSetBobos() {
            console.log("Getting Bobos")

            if (wallet.publicKey !== null) {
                setLoadingBobos(true)
                const ownerNfts = await getOwnerNfts(connection, wallet, vaultData);
                console.log(ownerNfts)
                setBobos(ownerNfts)
                setLoadingBobos(false)
            }

        }
        runSetBobos()
    }, [vaultData, wallet])



    useEffect(() => {
        let newCurrentOrderLaws: Law[] = [];
        for (let law of laws) {
            let currentTime = Math.floor(Date.now() / 1000) - solClockDrift.current
            let startTimeDifference = law.startTime - currentTime
            if (startTimeDifference > 0) {
                if (lawsOrder == LawState.UPCOMING) newCurrentOrderLaws.push(law)
                continue
            }
            let endDate = law.startTime + law.daysDuration * 86400
            let endTimeDifference = endDate - currentTime
            if (endTimeDifference > 0) {
                if (lawsOrder == LawState.STARTED) newCurrentOrderLaws.push(law)
                continue
            }
            if (governmentData == undefined) continue
            if (law.lawConfirmed && lawsOrder == LawState.PASSED) {
                newCurrentOrderLaws.push(law)
                continue
            }
            if (!law.lawConfirmed && lawsOrder == LawState.FAILED) {
                newCurrentOrderLaws.push(law)
                continue
            }
        }
        setCurrentOrderLaws(newCurrentOrderLaws)
    }, [lawsOrder, laws])

    // function selectVotingMode(law: Law) {
    //     setInSelection(true)
    //     setSelectedLaw(law)
    // }

    // function cancelVoteSelection() {
    //     setInSelection(false)
    //     setSelectedLaw(undefined)
    //     setVoters([])
    // }

    useEffect(() => {
        getSolClockDrift()
    }, [])

    async function getSolClockDrift() {
        let solTime = await getSolTime(connection)
        if (solTime === null) {
            solClockDrift.current = 0
            return
        }
        solClockDrift.current = Math.floor(Date.now() / 1000) - solTime
    }

    async function expandLaw(law: Law) {
        gsap.to("#DAO-Voting-App", { opacity: 0, duration: 1 })

        setSelectedLaw(law)
        await delay(1100)
        setPage("LawVoting")
        gsap.to("#DAO-Voting-App", { opacity: 1, duration: 1 })
    }

    // useEffect(() => {
    //     setShowContentBlocker(inSelection)
    // }, [inSelection])

    // function toggleVoter(bobo: NftData) {
    //     let currentVoters = voters;
    //     if(currentVoters.includes(bobo)) {
    //         currentVoters.splice(currentVoters.indexOf(bobo, 0), 1)
    //     } else {
    //         currentVoters.push(bobo)
    //     }
    //     setVoters([...currentVoters])
    // }

    return (<>
        {/* {inSelection && <div id="content-blocker" onClick={cancelVoteSelection}></div>} */}
        <div id="DAO-Voting-App">
            <div id="small-screen-blocker" className="pt-4"><img src={rotatePhone} className="mb-3" /><p>Rotate your mobile device to view page</p></div>
            {userConnected ?
                page === "CPanel" ?
                    <div id="Control-Panel" className="container-fluid row m-0">
                        <div className="col-2 order-3 col-xxl-1 order-xxl-1 d-flex flex-column">
                            <button className={`btn laws-order-btn d-flex justify-content-between align-items-center mb-2 ${lawsOrder === LawState.UPCOMING && "selected-gradient"}`} onClick={() => setLawsOrder(LawState.UPCOMING)}><FontAwesomeIcon icon={faStopwatch} />Upcoming</button>
                            <button className={`btn laws-order-btn d-flex justify-content-between align-items-center mb-2 ${lawsOrder === LawState.STARTED && "selected-gradient"}`} onClick={() => setLawsOrder(LawState.STARTED)}><FontAwesomeIcon icon={faClock} />Current</button>
                            <button className={`btn laws-order-btn d-flex justify-content-between align-items-center mb-2 ${lawsOrder === LawState.PASSED && "selected-gradient"}`} onClick={() => setLawsOrder(LawState.PASSED)}><FontAwesomeIcon icon={faCircleCheck} />Passed</button>
                            <button className={`btn laws-order-btn d-flex justify-content-between align-items-center mb-2 ${lawsOrder === LawState.FAILED && "selected-gradient"}`} onClick={() => setLawsOrder(LawState.FAILED)}><FontAwesomeIcon icon={faCircleXmark} />Failed</button>
                        </div>
                        <div id="Laws-List" className="col-10 order-4 col-xxl-7 order-xxl-2">
                            {loadingLaws ? <Spinner animation="border" className="align-self-center" />
                                :
                                currentOrderLaws.length !== 0 ?
                                    currentOrderLaws.map((law, index) => {
                                        return <LawContainer key={law.lawIndex} id={"law-" + index} onClick={() => expandLaw(law)} government={governmentData} law={law} containerClass="row w-100 my-3" solClockDrift={solClockDrift.current} />
                                    })
                                    : <h6>Nothing to see here.</h6>}
                        </div>
                        <div className="col-12 order-2 col-xxl-1 order-xxl-3 d-flex justify-content-center my-5 m-xxl-0">
                            <div className="col-break selected-gradient"></div>
                        </div>
                        <div id="User-Panel" className="col-12 order-1 col-xxl-3 order-xxl-4 d-flex flex-column p-4 mb-5">
                            <div className="d-flex justify-content-between w-100 my-2">
                                <span className="text-start text-ussr" style={{ maxWidth: "90%", fontSize: "3rem" }}><b>Wallet</b></span><span className="d-flex align-items-center" style={{ fontSize: "2rem" }}>{shortenAddress(wallet.publicKey.toString())}</span>
                            </div>
                            {/* <h1 className="text-ussr text-start">Wallet</h1>
                    <h2 className="text-end">{shortenAddress(wallet.publicKey.toString())}</h2> */}
                            <div>
                                <h2 className="text-ussr text-start ms-3">Citizens</h2>
                                {bobos.length > 0 ?
                                    <div className="row hidden-scroll d-flex justify-content-center">
                                        {loadingBobos ? <Spinner animation="border" className="align-self-center my-5" />
                                            :
                                            bobos.map((bobo) => {
                                                return <CitizenCircle class="col-3 col-sm-2 col-md-2 col-lg-1 col-xxl-3 mx-2 my-2" connection={connection} wallet={wallet} key={bobo.name} nft={bobo} selected={false} hoverable={true} onClick={() => { setSelectedCitizen(bobo); setPage("CitizenInfo") }} />
                                            })}
                                    </div> : ""}
                            </div>
                            <div className="d-flex justify-content-center align-items-end w-100 py-3 mt-auto">
                                <button id="vote-btn" className="btn selected-gradient text-white" disabled={governmentData === undefined || bobos.length === 0 || !governmentData.lawCreationOpen} onClick={() => setPage("LawCreation")}><h3 className="text-ussr m-0">Create Law</h3></button>
                            </div>
                        </div>
                    </div>
                    :
                    page === "LawVoting" && selectedLaw !== undefined ?
                        <LawPanel connection={connection} wallet={wallet} government={governmentData} selectedLaw={selectedLaw} setSelectedLaw={setSelectedLaw} bobos={bobos} returnFunction={() => setPage("CPanel")} />
                        : page === "LawCreation" && bobos.length > 0 ?
                            <LawCreationPanel connection={connection} wallet={wallet} government={governmentData} bobos={bobos} returnFunction={() => setPage("CPanel")} />
                            : page === "CitizenInfo" && bobos.length > 0 ?
                                <CitizenInfoPanel connection={connection} wallet={wallet} government={governmentData} selectedCitizen={selectedCitizen} returnFunction={() => { setSelectedCitizen(undefined); setPage("CPanel") }} />
                                : ""
                : <h1 className="text-white" style={{ marginTop: "10rem" }}>Connect your wallet to access the Bobo DAO platform</h1>}
        </div>
    </>)
}

export default DAOVotingHandler