import {
  MemcmpFilter,
  Connection,
  PublicKey,
  } from "@solana/web3.js";

import * as anchor from "@project-serum/anchor";
import {
Program, Wallet, web3, BN, Idl
} from '@project-serum/anchor';

import {
  Metadata,
  MetadataData,
} from "@metaplex-foundation/mpl-token-metadata";

import { getMintCurrentRewards, getRarityMultiplier, getDurationMultiplier } from "./newStaking"
import { getAdditionalPoints } from "./trainingStaking"
import { getProvider, getBoboMetadata, getStakeDataAddress, parseUintLe, getBranch } from "../OnChain/utils"
import { VaultData, NftData, StakedData, CampData, GameData, CANDY_MACHINE_ADDRESS } from "../OnChain/chainInfo"

import { STAKING_PROGRAM_ID, BOBO_METADATA_PROGRAM_ID, TRAINING_CAMP_PROGRAM_ID } from "../OnChain/programsInfo"
import stakingProgramIdl from '../OnChain/Programs/StakingProgram/idl/nft_staking.json';
import boboMetadataIdl from "../OnChain/Programs/BoboMetadata/idl/bobo_metadata.json"
import trainingCampProgramIdl from "../OnChain/Programs/TrainingStaking/idl/training_staking.json"

export async function getOwnerNfts(
  connection: Connection,
  wallet: Wallet,
  vault: VaultData | undefined
): Promise<NftData[]> {
  let nfts = await getWalletNfts(connection, wallet)
  if (vault !== undefined) {
    let stakedNfts = await getStakedNftsByOwner(connection, wallet, wallet.publicKey, vault)
    nfts.push(...stakedNfts)
  }
  let campedNfts = await getCampedNftsByOwner(connection, wallet, wallet.publicKey)
  nfts.push(...campedNfts)

  return nfts
}


export async function getWalletNfts(  
  connection: Connection,
  wallet: any,
): Promise<NftData[]> {
  console.log("Getting wallet nfts")
  const walletNfts = await Metadata.findDataByOwner(connection, wallet.publicKey);

  const provider = await getProvider(connection, wallet)
  const metadataProgram = new Program(boboMetadataIdl as Idl, BOBO_METADATA_PROGRAM_ID, provider);

  const nfts = [];
  for (let nft of walletNfts) {
    if (
      nft.data.creators &&
      nft.data.creators[0]?.verified &&
      CANDY_MACHINE_ADDRESS !== undefined &&
      nft.data.creators[0].address === CANDY_MACHINE_ADDRESS.toString()
    ) {
      let defaultStakedData: StakedData = getDefaultStakedData()
      let defaultCampData: CampData = getDefaultCampData()

      let nftData: NftData = {
        mint: new PublicKey(nft.mint),
        name: nft.data.name,
        uri: nft.data.uri,
        imageUrl: "",
        stakedData: defaultStakedData,
        campData: defaultCampData,
        gameData: await getBoboGameData(metadataProgram, new PublicKey(nft.mint), nft.data.name)
      }
      
      nfts.push(nftData);
    }
  }
  return nfts
}

export async function getStakedNftsByOwner(
  connection: Connection,
  wallet: any,
  owner: PublicKey,
  vault: VaultData
): Promise<NftData[]> {
  const provider = await getProvider(connection, wallet)
  const metadataProgram = new Program(boboMetadataIdl as Idl, BOBO_METADATA_PROGRAM_ID, provider);
  const stakingProgram = new Program(stakingProgramIdl as Idl, STAKING_PROGRAM_ID, provider);

  let stakeDataAccounts = await connection.getProgramAccounts(STAKING_PROGRAM_ID, {
    filters: [
      createStakeTokenOwnerFilter(owner),
    ],
  });

  let mintPDAs = await Promise.all(stakeDataAccounts.map(async ({ account: { data } }) => {
    let mint = new PublicKey(data.slice(44, 76));
    return await Metadata.getPDA(mint)
  }))

  let metadatas = await Metadata.getInfos(connection, mintPDAs)

  return Promise.all(
    stakeDataAccounts.map(async ({ account: { data } }, index) => {
      let timestamp = Number(parseUintLe(data, 8, 4));
      let mint = new PublicKey(data.slice(44, 76));
      let metadataAccount = metadatas.get(mintPDAs[index])
      let metadata;
      if(metadataAccount?.data != undefined) {
        metadata = MetadataData.deserialize(metadataAccount?.data)
      }

      let stakeDataInfo = await getStakeDataAddress(mint)
      let stakeData = await stakingProgram.account.stakeData.fetch(stakeDataInfo)

      let rarityBracket = stakeData.rarityBracketIdentifier;
      let lockedDuration = stakeData.lockedDuration;

      const rarityMultiplier = getRarityMultiplier(vault, rarityBracket)
      const durationMultiplier = getDurationMultiplier(vault, lockedDuration)
      let rewardMultiplier = rarityMultiplier * durationMultiplier / (100 * 100)

      let withdrawn = Number(parseUintLe(data, 82, 8));

      let dailyRate = Number(vault.baseRewardRate) * 86400 * rewardMultiplier / Math.pow(10, Number(vault.rewardDecimals))

      let stakedData = {
        staked: true,
        timestamp: stakeData.entryTimestamp,
        withdrawn,
        rewardMultiplier,
        rarityBracket,
        lockedDuration,
        dailyRate,
        currentRewards: getMintCurrentRewards(vault, stakeData.entryTimestamp, lockedDuration, rarityBracket, withdrawn)
      }

      let defaultCampData: CampData = getDefaultCampData()

      return {
        mint,
        name: metadata.data.name,
        uri: metadata.data.uri,
        imageUrl: "",
        stakedData,
        campData: defaultCampData,
        gameData: await getBoboGameData(metadataProgram, new PublicKey(mint), metadata.data.name)
      };
    })
  );
}

export async function getCampedNftsByOwner(
  connection: Connection,
  wallet: any,
  owner: PublicKey,
): Promise<NftData[]> {
  const provider = await getProvider(connection, wallet)
  const metadataProgram = new Program(boboMetadataIdl as Idl, BOBO_METADATA_PROGRAM_ID, provider);
  const trainingProgram = new Program(trainingCampProgramIdl as Idl, TRAINING_CAMP_PROGRAM_ID, provider);

  let trainingInfoAccounts = await connection.getProgramAccounts(TRAINING_CAMP_PROGRAM_ID, {
    filters: [
      createStakeTokenOwnerFilter(owner, 40),
    ],
  });

  let mintPDAs = await Promise.all(trainingInfoAccounts.map(async ({ account: { data } }) => {
    let mint = new PublicKey(data.slice(8, 40));
    return await Metadata.getPDA(mint)
  }))

  let metadatas = await Metadata.getInfos(connection, mintPDAs)

  

  return Promise.all(
    trainingInfoAccounts.map(async ({ account: { data }, pubkey }, index) => {
      let mint = new PublicKey(data.slice(8, 40));
      let score = Number(parseUintLe(data, 72, 2));
      // let entryTime = Number(parseUintLe(data, 74, 4));
      let daysInCamp = Number(parseUintLe(data, 78, 1));
      let trainingType = Number(parseUintLe(data, 79, 1));

      // TEMPORARY 
      let fetchedTrainingInfo = await trainingProgram.account.trainingInfo.fetch(pubkey)
      let entryTime = Number(fetchedTrainingInfo.entryTime);

      let metadataAccount = metadatas.get(mintPDAs[index])
      let metadata;
      if(metadataAccount?.data != undefined) {
        metadata = MetadataData.deserialize(metadataAccount?.data)
      }

      let endDate = entryTime + daysInCamp * 86400

      let campData: CampData = {
        camped: true,
        additionalPoints: getAdditionalPoints(score, daysInCamp),
        endDate,
        trainingType: trainingType === 0 ? "Attack" : "Defense"
      }

      return {
        mint,
        name: metadata.data.name,
        uri: metadata.data.uri,
        imageUrl: "",
        stakedData: getDefaultStakedData(),
        campData,
        gameData: await getBoboGameData(metadataProgram, new PublicKey(mint), metadata.data.name)
      };
    })
  );
}

function getDefaultStakedData(): StakedData {
  return {
    staked: false,
    timestamp: 0,
    withdrawn: 0,
    rewardMultiplier: 0,
    rarityBracket: 0,
    lockedDuration: 0,
    dailyRate: 0,
    currentRewards: 0
  }
}
function getDefaultCampData(): CampData {
  return {
    camped: false,
    additionalPoints: 0,
    endDate: 0,
    trainingType: ""
  }
}


async function getBoboGameData(
  metadataProgram: Program,
  boboMint: PublicKey,
  name: string
): Promise<GameData> {
  let gameData: GameData;
  try {
    let boboMetadata = await getBoboMetadata(boboMint)

    let fetchedBoboMetadata = await metadataProgram.account.boboMetadata.fetch(boboMetadata)
    gameData = {
      playable: false,
      combatPoints: fetchedBoboMetadata.combatPoints,
      defensePoints: fetchedBoboMetadata.defensePoints,
      arenaLevel: fetchedBoboMetadata.arenaLevel,
      xp: fetchedBoboMetadata.arenXp,
      branch: getBranch(name)
    }
  } catch (err) {
    // console.error(err)
    gameData = {
      playable: false,
      combatPoints: 0,
      defensePoints: 0,
      arenaLevel: 0,
      xp: 0,
      branch: ""
    }
  }

  return gameData
}

export async function getMintMetaplexMetadata(
  connection: Connection,
  mint: PublicKey
) {
  return MetadataData.deserialize((await Metadata.getInfo(connection, await Metadata.getPDA(mint))).data).data
}

export function createStakeTokenOwnerFilter(owner: PublicKey, offset: number = 12): MemcmpFilter {
  return {
    memcmp: {
      offset,
      bytes: owner.toBase58(),
    },
  };
}