import "./BoboCard.css";
import React, { FC, useEffect, useState, useReducer } from "react";

interface BoboMetadataInfo {
    imageUrl: string;
    isCivilian: boolean;
    isMutant: boolean;
    isAnimated: boolean;
}

interface BoboMetadataInfoFetch {
    fetching: boolean;
    boboMetadataInfo: BoboMetadataInfo | null;
    fetch: Function;
}

const BoboCard: FC<any> = (props) => {
    const { boboNft, isSwitchToCivCard, className, transformData, selectBobo } = props;

    const [boboMetadataInfoFetch, setBoboMetadataInfoFetch] = useState<BoboMetadataInfoFetch>({ fetching: false, boboMetadataInfo: null, fetch: fetchBoboMetadataInfo })
    const [boboSelectInfo, setBoboSelectInfo] = useState({ isSelected: false, canBeSwitched: false, canBeBurnt: false })

    const [isSelectable, setIsSelectable] = useState(false)

    useEffect(() => {
        fetchBoboMetadataInfo()
    }, [boboNft])

    async function fetchBoboMetadataInfo() {
        setBoboMetadataInfoFetch({ ...boboMetadataInfoFetch, fetching: true })
        let json = await fetch(boboNft.boboUrl).then(response => response.json())

        const imageUrl = json.image;
        const isCivilian = json.attributes.filter((attr: any) => attr.trait_type === "Version")[0].value === "Civilian";
        const isMutant = json.attributes.filter((attr: any) => attr.trait_type === "Specificity")[0]?.value.includes("Mutant");
        const isAnimated = json.attributes.filter((attr: any) => attr.trait_type === "Specificity")[0]?.value.includes("Animated");

        setBoboMetadataInfoFetch({ ...boboMetadataInfoFetch, fetching: false, boboMetadataInfo: { imageUrl, isCivilian, isMutant, isAnimated } })
    }

    useEffect(() => {
        const isCivSelected = boboNft.boboMint === transformData.selectedCivilianMint;
        const isBurnSelected = boboNft.boboMint === transformData.selectedBurnMint;
        if (isSwitchToCivCard) {
            setBoboSelectInfo({ ...boboSelectInfo, isSelected: isCivSelected, canBeSwitched: !isCivSelected && !isBurnSelected && !boboMetadataInfoFetch.boboMetadataInfo?.isCivilian && !boboMetadataInfoFetch.boboMetadataInfo?.isMutant && !boboMetadataInfoFetch.boboMetadataInfo?.isAnimated })
        } else {
            setBoboSelectInfo({ ...boboSelectInfo, isSelected: isBurnSelected, canBeBurnt: !isCivSelected && !isBurnSelected && !boboMetadataInfoFetch.boboMetadataInfo?.isAnimated });
        }
    }, [boboMetadataInfoFetch.boboMetadataInfo, transformData])

    useEffect(() => {
        setIsSelectable(!boboSelectInfo.isSelected && ((isSwitchToCivCard && boboSelectInfo.canBeSwitched) || (!isSwitchToCivCard && boboSelectInfo.canBeBurnt)))
    }, [boboSelectInfo])

    return (
        <div style={isSwitchToCivCard && (boboMetadataInfoFetch.boboMetadataInfo?.isCivilian || boboMetadataInfoFetch.boboMetadataInfo?.isMutant || boboMetadataInfoFetch.boboMetadataInfo?.isAnimated) ? { opacity: .4, background: "transparent" } : {}} className={`bobo-card d-flex flex-column align-items-center ${className} ${boboSelectInfo.isSelected ? "selected" : ""} ${!boboSelectInfo.isSelected && !isSelectable ? "not-selectable" : ""}`} onClick={() => isSelectable && selectBobo(isSwitchToCivCard ? "switch" : "burn", boboNft.boboMint)}>
            <img src={boboMetadataInfoFetch.boboMetadataInfo !== null ? boboMetadataInfoFetch.boboMetadataInfo.imageUrl : undefined} />
            <div className="d-flex mt-3">
                <h4>{boboNft.boboName}</h4>
            </div>
            {/* <button className="select-button mx-auto mt-3">Select</button> */}
        </div>
    )
}

export default BoboCard;