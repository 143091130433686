import "./StakingContainer.css";
import React from "react"
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical, faGripHorizontal } from '@fortawesome/free-solid-svg-icons'

import Modal from "react-bootstrap/Modal"
import Spinner from "react-bootstrap/Spinner";

import {getAllCurrentRewards} from "../../utils/newStaking"

import {ReactComponent as BoboLogo}  from "../../images/logo.svg"
import StakingCard from "./StakingCard.js";
import BowImg from "../../images/bow.png"

const cardsClassName = {
    "column": "col-5 col-md-3 col-lg-5 col-xxl-3",
    "row": "col-4 col-md-3 col-xl-2"
}

class StakingContainer extends React.Component {

    componentDidMount() {
        this.getStakedBoboCards()
        this.getUnstakedBoboCards()
        
        setInterval(this.setTotalRewards, 1000)

        this.animatePage();
    }

    componentDidUpdate(prevProps) {
        if(this.props.refreshHandle !== prevProps.refreshHandle) {
            this.setState({refreshHandle: this.props.refreshHandle})
            this.getStakedBoboCards()
            this.getUnstakedBoboCards()
        }
        else {
            if(prevProps.stakedNfts !== this.props.stakedNfts) {
                this.getStakedBoboCards()
            }
            if(prevProps.unstakedNfts !== this.props.unstakedNfts) {
                this.getUnstakedBoboCards()
            }
        }
      }

    constructor(props) {
        super(props)
        this.state = {
            refreshHandle: this.props.refreshHandle,
            stakedBoboCards: [],
            unstakedBoboCards: [],
            totalRewards: 0,
            showImages: true,
            cardViewMode: "column",

            selectedMint: null,
            showStakingModal: false,
            lockedDuration: 10
        }
        this.getStakedBoboCards = this.getStakedBoboCards.bind(this)
        this.getUnstakedBoboCards = this.getUnstakedBoboCards.bind(this)
        this.setTotalRewards = this.setTotalRewards.bind(this)
        this.toggleShowImages = this.toggleShowImages.bind(this)
        this.changeCardViewMode = this.changeCardViewMode.bind(this)

        this.selectMint = this.selectMint.bind(this)
        this.changeLockDuration = this.changeLockDuration.bind(this)

        this.stakeSelected = this.stakeSelected.bind(this)
        this.stakeAll = this.stakeAll.bind(this)
        
        gsap.registerPlugin(TextPlugin);
    }
    
    getStakedBoboCards() {
        // console.log(this.props.stakedNfts)
        let stakedBoboCards = [];
        for(let i=0; i<this.props.stakedNfts.length; i++) {
            stakedBoboCards.push(<StakingCard key={i} className={`staking-card ${cardsClassName[this.state.cardViewMode]} mx-1 mx-md-2 mx-lg-3 mb-3 p-0`} connection={this.props.connection} wallet={this.props.wallet} vaultData={this.props.vaultData} staked={true} nft={this.props.stakedNfts[i]} showImages={this.state.showImages} rewardCalculator={this.props.rewardCalculator} stakeToken={this.props.stakeToken} unstakeToken={this.props.unstakeToken} withdrawRewards={this.props.withdrawRewardsForStaked} />)
        }
        this.setState({stakedBoboCards})
    }

    getUnstakedBoboCards() {
        // console.log(this.props.unstakedNfts)
        let unstakedBoboCards = [];
        for(let i=0; i<this.props.unstakedNfts.length; i++) {
            unstakedBoboCards.push(<StakingCard key={i} className={`staking-card ${cardsClassName[this.state.cardViewMode]} mx-1 mx-md-2 mx-lg-3 mb-3 p-0`} connection={this.props.connection} wallet={this.props.wallet} vaultData={this.props.vaultData} staked={false} nft={this.props.unstakedNfts[i]} showImages={this.state.showImages} rewardCalculator={this.props.rewardCalculator} selectMint={this.selectMint} unstakeToken={this.props.unstakeToken} withdrawRewards={this.props.withdrawRewardsForStaked} />)
        }
        this.setState({unstakedBoboCards})
    }

    setTotalRewards() {
        let totalRewards = Number(getAllCurrentRewards(this.props.stakedNfts, this.props.vaultData)).toFixed(4)
        this.setState({totalRewards})
    }

    animatePage() {
        let entryTl = gsap.timeline()

        entryTl.from("#bobo-logo", {scale: 10, duration: 0.6})
        entryTl.from(".title", {opacity: 0, duration: 1}, ">")

        let infoHeaders = gsap.utils.toArray(".staking-info-title")
        let infoHeadersText = ["Bobos Staked", "Total %"]
        infoHeaders.map((infoHeader, i) => entryTl.to(infoHeader, {duration: 0.8, text: infoHeadersText[i]}, i==0?">":"<"))

        let infoValues = gsap.utils.toArray(".staking-info-value")
        infoValues.map((infoValue, i) => entryTl.from(infoValue, {duration: 0.8, opacity: 0, scale: 0}, i==0?">":"<"))

        let personalInfoContainers = gsap.utils.toArray(".personal-info-container")
        personalInfoContainers.map((personalInfoContainer, i) => entryTl.from(personalInfoContainer, {duration: .8, opacity: 0}, i==0?"<+0.2":"<"))
    }

    toggleShowImages() {
        this.setState(state => ({showImages: !state.showImages}),
        () => {
            this.getStakedBoboCards()
            this.getUnstakedBoboCards()
        });
    }

    changeCardViewMode(newMode) {
        this.setState({cardViewMode: newMode},
        () => {
            this.getStakedBoboCards()
            this.getUnstakedBoboCards()
        });
    }


    selectMint(mint) {
        console.log(mint)
        this.setState({selectedMint: mint, showStakingModal: true})
    }
    
    changeLockDuration(newDuration) {
        this.setState({lockedDuration: newDuration})
    }

    stakeSelected() {
        console.log(this.state.selectedMint)
        this.props.stakeToken(this.state.selectedMint, this.state.lockedDuration * 86400)
        this.setState({showStakingModal: false})
    }

    stakeAll() {
        console.log(this.state.selectedMint)
        this.props.stakeAllTokens(this.state.lockedDuration * 86400)
        this.setState({showStakingModal: false})
    }

    render() {
        return(
            <div id="Staking-container" className="container-fluid d-flex flex-column justify-content-start align-items-center mt-5 text-white">
                <h1 className='title text-ussr'>BOW Staking</h1>
                <div className='row d-flex w-100 justify-content-center align-items-center' style={{marginTop: "6rem"}}>
                    <span className='col-lg-4 staking-info-container'>
                        <h2 className='staking-info-title'><b></b></h2>
                        <h1 className='staking-info-value text-ussr'>{this.props.totalStaked}</h1>
                    </span>
                    <BoboLogo id="bobo-logo" className="col-lg-4" />
                    {/* <img src={boboLogo} className="col-lg-4" style={{width: "200px", fill: "white"}} /> */}
                    <span className='col-lg-4 staking-info-container'>
                        <h2 className='staking-info-title'><b></b></h2>
                        <h1 className='staking-info-value text-ussr'>{(this.props.totalStaked / 8500 * 100).toFixed(2)}%</h1>
                    </span>
                </div>
                <div className='row d-flex w-100 justify-content-center mt-5'>
                    <div className='personal-info-container col-6 col-lg-3'>
                        <h3>Total Rewards</h3>
                        {this.props.fetchingStakedData ? <h5>Loading...</h5> : <h5><img src={BowImg} width="30px" className="mb-1" /> {this.state.totalRewards}</h5>}
                        {this.props.stakedNfts.length > 0 && <button className="btn text-white selected-gradient mb-3" onClick={this.props.withdrawAllRewardsForStaked}><b>Withdraw All</b></button>}
                    </div>
                    <div className='personal-info-container col-6 col-lg-3'>
                        <h3>Staking Rate</h3>
                        {this.props.fetchingStakedData ? <h5>Loading...</h5> : <h5><img src={BowImg} width="30px" className="mb-1" /> {this.props.totalDailyRate.toFixed(4)}/day</h5>}
                        {this.props.unstakedNfts.length > 0 && <button className="btn text-white selected-gradient mb-3" onClick={() => this.selectMint(null)}><b>Stake/Lock All</b></button>}
                        {this.props.stakedNfts.length > 0 && <button className="btn text-white selected-gradient ms-3 mb-3" onClick={() => this.props.unstakeAllUnlockedTokens()}><b>Unlock All Completed</b></button>}
                        {/* {this.props.stakedNfts.length > 0 && <button className="btn text-white selected-gradient" onClick={() => this.props.unstakeAllBobos()}><b>Unstake All</b></button>} */}
                    </div>
                </div>
                {/* <h1 className="my-5" style={{paddingLeft: "5rem", paddingRight:"5rem"}}>Staking is in maintenance until Tuesday 26th April at 9pm UTC when the staking program updates to a locked staking mechanism</h1> */}
                {this.props.wallet?.connected &&
                    <div className='row d-flex w-100 px-3' style={{marginTop: "7rem"}}>
                        <div className='col-12 d-flex justify-content-between'>
                            <div className="d-flex justify-content-start">
                                <div id='image-toggle' className='d-flex justify-content-end ms-3' onClick={this.toggleShowImages} style={{width: "30px", height: "30px", background: "#ffffff"}}>
                                    <div id="image-toggle-handle" className={`${this.state.showImages && "selected-gradient"}`} style={{width: "30px", height: "30px", background: this.state.showImages ? "#30dd8a": "#191919"}}></div>
                                </div>
                                <h6 className="my-auto ms-2">Image toggle (faster load)</h6>
                            </div>
                            <div className="d-none d-lg-flex justify-content-start">
                                <div className={`${this.state.cardViewMode == "column" && "selected-gradient"} me-3 p-2`} onClick={() => this.changeCardViewMode("column")} style={{borderRadius: '.7rem'}}>
                                    <FontAwesomeIcon icon={faGripVertical} size="2x" />
                                </div>
                                <div className={`${this.state.cardViewMode == "row" && "selected-gradient"} me-3 px-2 py-1 my-1`} onClick={() => this.changeCardViewMode("row")} style={{borderRadius: '.7rem'}}>
                                    <FontAwesomeIcon icon={faGripHorizontal} size="2x" />
                                </div>
                            </div>
                        </div>

                        <div id="staked-bobos-container" className={`d-flex flex-column col-12 ${this.state.cardViewMode == "column" ? "align-items-start col-lg-6" : "align-items-center"}`}>
                            <h1>Hibernating Bobos ({this.props.stakedNfts.length})</h1>
                            {this.props.fetchingStakedData ? <Spinner animation="border" role="status" className="mx-auto mt-5">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : ""}
                            <div className={`d-flex row justify-content-center ${this.state.cardViewMode == "column" && "justify-content-lg-start"} mt-3 w-100`}>
                                {this.state.stakedBoboCards}
                            </div>
                            
                        </div>
                        <div id="normal-bobos-container" className={`d-flex flex-column col-12 ${this.state.cardViewMode == "column" ? " align-items-end col-lg-6" : "align-items-center mt-5"}`}>
                            <h1>({this.props.unstakedNfts.length}) Unstaked Bobos</h1>
                            {this.props.fetchingUnstakedData ? <Spinner animation="border" role="status" className="mx-auto mt-5">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : ""}
                            <div className={`d-flex row justify-content-center ${this.state.cardViewMode == "column" && "justify-content-lg-end"} mt-3 w-100`}>
                                {this.state.unstakedBoboCards}  
                            </div>
                        </div>
                    </div>}
                    <Modal id="staking-modal" show={this.state.showStakingModal} onHide={() => this.setState({showStakingModal: false})} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Select Locking Duration</Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="d-flex jusitfy-content-between">
                            <button className={`btn text-white ${this.state.lockedDuration === 10 ? "selected-gradient" : "btn-default"}`} onClick={() => this.changeLockDuration(10)}>10 Days (1.01x)</button>
                            <button className={`btn text-white ${this.state.lockedDuration === 15 ? "selected-gradient" : "btn-default"}`} onClick={() => this.changeLockDuration(15)}>15 Days (1.03x)</button>
                            <button className={`btn text-white ${this.state.lockedDuration === 30 ? "selected-gradient" : "btn-default"}`} onClick={() => this.changeLockDuration(30)}>30 Days (1.09x)</button>
                            <button className={`btn text-white ${this.state.lockedDuration === 45 ? "selected-gradient" : "btn-default"}`} onClick={() => this.changeLockDuration(45)}>45 Days (1.18x)</button>
                            <button className={`btn text-white ${this.state.lockedDuration === 70 ? "selected-gradient" : "btn-default"}`} onClick={() => this.changeLockDuration(70)}>70 Days (1.39x)</button>
                        </Modal.Body>

                        <Modal.Footer>
                            <button className="btn text-white selected-gradient" onClick={() => {this.state.selectedMint !== null ? this.stakeSelected(): this.stakeAll()}}>Lock for <b>{this.state.lockedDuration} days</b></button>
                        </Modal.Footer>
                    </Modal>
            </div>
        )
    }
}

export default StakingContainer;