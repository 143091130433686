import "./CiviliansHandler.css";
import React, { FC, useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import optionOneImage from "../../images/Civilians/optionOne.png"
import optionTwoImage from "../../images/Civilians/optionTwo.png"
import startOptionBackground from "../../images/Civilians/optionOneLarge.png"
import resumeOptionBackground from "../../images/Civilians/optionTwoLarge.png"

import BoboCard from "./BoboCard"


import { Metadata } from "@metaplex-foundation/mpl-token-metadata";

import { sendTransformApiCall, sendTransformTx } from "../../utils/civilians";
import { getStakedDataByOwner } from "../../utils/newStaking";

import { CANDY_MACHINE_ADDRESS } from "../../OnChain/chainInfo"
import { toast } from "react-toastify";


enum SwitchOption {
    Start,
    Resume
}

interface UserBoboPick {
    boboName: string;
    boboUrl: string;
    boboMint: string;
    eligible: boolean;
    isStaked: boolean;
}

interface TransformData {
    bowPaid: number;
    selectedCivilianMint: string | null;
    selectedBurnMint: string | null;
    transforming: boolean;
    transformResult: { success: boolean, error: string | null, metadataUrl: string | null } | null;
}

const MIN_BOW_PAY = 50;
const MAX_BOW_PAY = 500;

const IS_OPEN = false;

const CiviliansHandler: FC<any> = (props) => {
    const { connection, wallet, locationPaths } = props;

    const history = useHistory();

    const [userConnected, setUserConnected] = useState(false)
    const [currentSwitchOption, setCurrentSwitchOption] = useState<SwitchOption | null>();

    const [userBobos, setUserBobos] = useState<{ all: UserBoboPick[] | null, staked: UserBoboPick[] | null, owned: UserBoboPick[] | null, fetching: boolean }>({ all: null, staked: null, owned: null, fetching: false })
    const [transformData, setTransformData] = useState<TransformData>({ bowPaid: 100, selectedCivilianMint: null, selectedBurnMint: null, transforming: false, transformResult: null })
    const [canTransform, setCanTransform] = useState(false);

    const [generationText, setGenerationText] = useState("")

    const [paymentTxInput, setPaymentTxInput] = useState<string>()

    const [transformedBoboWarImage, setTransformedBoboWarImage] = useState()
    const [transformedBoboCivilianImage, setTransformedBoboCivilianImage] = useState()

    useEffect(() => {
        if (wallet.connected && !wallet.connecting && !wallet.disconnecting && wallet.publicKey !== null) {
            setUserConnected(true)
        } else {
            setUserConnected(false)
        }
    }, [connection, wallet])

    useEffect(() => {
        if (userConnected) {
            fetchUserBobos()
        }
    }, [userConnected])

    useEffect(() => {
        console.log(locationPaths[1])
        console.log(locationPaths[1] === "start" ? SwitchOption.Start : locationPaths[1] === "resume" ? SwitchOption.Resume : null)
        setCurrentSwitchOption(locationPaths[1] === "start" ? SwitchOption.Start : locationPaths[1] === "resume" ? SwitchOption.Resume : null)
    }, [locationPaths])

    async function fetchUserBobos() {
        setUserBobos({ ...userBobos, fetching: true })
        const stakedNftsOfWallet = await getStakedDataByOwner(connection, wallet, wallet.publicKey, null);
        let collator = new Intl.Collator(undefined, { numeric: true });
        stakedNftsOfWallet.sort((a: any, b: any) => collator.compare(a.data.name, b.data.name));
        console.log(stakedNftsOfWallet)
        const stakedNfts = stakedNftsOfWallet.map(nft => { return { boboName: nft.data.name, boboUrl: nft.data.uri, boboMint: nft.mint.toString(), eligible: true, isStaked: true } })


        const walletNfts = await Metadata.findDataByOwner(connection, wallet.publicKey);
        const heldNfts: UserBoboPick[] = [];
        for (let nft of walletNfts)
            if (
                nft.data.creators &&
                nft.data.creators[0]?.verified &&
                nft.data.creators[0].address === CANDY_MACHINE_ADDRESS.toString()
            )
                heldNfts.push({
                    boboName: nft.data.name,
                    boboUrl: nft.data.uri,
                    boboMint: nft.mint.toString(),
                    eligible: true,
                    isStaked: false
                });
        heldNfts.sort((a: UserBoboPick, b: UserBoboPick) => collator.compare(a.boboName, b.boboName))

        setUserBobos({ all: [...stakedNfts, ...heldNfts], staked: stakedNfts, owned: heldNfts, fetching: false });
    }

    function selectBobo(type: string, boboMint: string) {
        if (type === "switch") setTransformData({ ...transformData, selectedCivilianMint: boboMint })
        else setTransformData({ ...transformData, selectedBurnMint: boboMint })
    }

    useEffect(() => {
        if (currentSwitchOption === SwitchOption.Start) setCanTransform(transformData.bowPaid >= MIN_BOW_PAY && transformData.bowPaid <= MAX_BOW_PAY && transformData.selectedCivilianMint !== null && transformData.selectedBurnMint !== null)
        else setCanTransform(transformData.selectedCivilianMint !== null && paymentTxInput?.length !== 0);
    }, [transformData, paymentTxInput])

    async function initiateTransform() {
        if (!userConnected || !canTransform) return
        console.log(transformData)
        setTransformData({ ...transformData, transforming: true, transformResult: null })

        fetchTransformingBoboImage("war")
        let transformResult;

        setGenerationText("Sending payment transaction...")
        const paymentTx = await sendTransformTx(connection, wallet, transformData.bowPaid, transformData.selectedBurnMint!);
        if (paymentTx.length === 0) {
            transformResult = { success: false, error: "Payment transaction not completed", metadataUrl: null };
            toast.error(transformResult.error)
            setGenerationText("")
            setTransformData({ ...transformData, transforming: false, transformResult })
            return;
        }
        else {
            setGenerationText("Generating Civilian...")
            transformResult = await sendTransformApiCall(wallet, transformData.selectedCivilianMint!, paymentTx);
            setGenerationText("")
        }

        if (transformResult.success) {
            fetchTransformingBoboImage("civilian", transformResult.metadataUrl);
            toast.success("Successfully switched Bobo to civilian!")
            setTransformData({ ...transformData, transforming: false, transformResult, selectedCivilianMint: null, selectedBurnMint: null })
        } else {
            toast.error(transformResult.error + " Try to resume failed transaction.")
            setTransformData({ ...transformData, transforming: false, transformResult })
            setCurrentSwitchOption(SwitchOption.Resume);
            setPaymentTxInput(paymentTx);
        }

        fetchUserBobos()
    }

    async function resumeTransform() {
        if (!userConnected || !canTransform || paymentTxInput === undefined) return
        console.log(transformData)
        setTransformData({ ...transformData, transforming: true, transformResult: null })

        fetchTransformingBoboImage("war")
        setGenerationText("Generating Civilian...")
        const transformResult = await sendTransformApiCall(wallet, transformData.selectedCivilianMint!, paymentTxInput);
        setGenerationText("")
        setTransformData({ ...transformData, transforming: false, transformResult, selectedCivilianMint: null, selectedBurnMint: null })

        if (transformResult.success) {
            fetchTransformingBoboImage("civilian", transformResult.metadataUrl);
            toast.success("Successfully switched Bobo to civilian!")
        } else {
            toast.error(transformResult.error)
        }

        fetchUserBobos()
    }

    async function fetchTransformingBoboImage(type: string, metadataUrl: string | null = null) {
        if (userBobos.all === null || transformData.selectedCivilianMint === null) return;
        let json = await fetch(metadataUrl === null ? userBobos.all.filter(bobo => bobo.boboMint === transformData.selectedCivilianMint)[0].boboUrl : metadataUrl).then(response => response.json())

        const imageUrl = json.image;
        if (type === "war") setTransformedBoboWarImage(imageUrl)
        else setTransformedBoboCivilianImage(imageUrl)
    }

    function verifyBowPaidValue() {
        if (transformData.bowPaid < MIN_BOW_PAY) setTransformData({ ...transformData, bowPaid: MIN_BOW_PAY })
        else if (transformData.bowPaid > MAX_BOW_PAY) setTransformData({ ...transformData, bowPaid: MAX_BOW_PAY })
    }

    return (
        <div id="civilians-handler" className="d-flex flex-column text-white pb-5">
            {!userConnected ? <h2 style={{ marginTop: "10rem" }}>Connect your wallet to access Bobo civilians.</h2> :
                currentSwitchOption == null ?
                    <div id="option-select-container" className="d-flex justify-content-center align-items-center">
                        <div className="switch-option option-one col-6 m-3">
                            <div className="pointer-hover" onClick={() => { history.push("civilians/start"); setCurrentSwitchOption(SwitchOption.Start) }}><img src={optionOneImage} /><h2>Start a Civilian Transformation</h2></div>
                        </div>
                        <div className="switch-option option-two col-6 m-3">
                            <div className="pointer-hover" onClick={() => { history.push("civilians/resume"); setCurrentSwitchOption(SwitchOption.Resume) }}><img src={optionTwoImage} /><h2>Resume Failed Transformation</h2></div>

                        </div>
                    </div>
                    : currentSwitchOption == SwitchOption.Start ?
                        <div id="start-switch-container" className="switch-container d-flex flex-column align-items-center mt-5">
                            <img src={startOptionBackground} className="background-image" />
                            {IS_OPEN ? <><div className="background-image-extension"><div></div></div>
                                <div className="d-flex flex-column align-items-center w-100" style={{ zIndex: 10 }}>
                                    <div className="row justify-content-center w-100">
                                        <div id="civilian-pick-container" className="d-flex flex-column col-12 col-sm-10 col-md-8 col-lg-6 px-3">
                                            <h2>1. Pick Bobo to Transform</h2>
                                            <div className="row justify-content-center mt-4">
                                                {userBobos.staked?.map(boboNft => <BoboCard key={boboNft.boboName} boboNft={boboNft} isSwitchToCivCard={true} className="col-3 col-xl-2 mx-2 my-2" transformData={transformData} selectBobo={selectBobo} />)}
                                                {userBobos.owned?.map(boboNft => <BoboCard key={boboNft.boboName} boboNft={boboNft} isSwitchToCivCard={true} className="col-3 col-xl-2 mx-2 my-2" transformData={transformData} selectBobo={selectBobo} />)}
                                            </div>
                                        </div>
                                        <div id="burn-pick-container" className="d-flex flex-column col-12 col-sm-10 col-md-8 col-lg-6 px-3 mt-5 mt-lg-0">
                                            <h2>2. Pick Bobo to Sacrifice</h2>
                                            <div className="row justify-content-center mt-4">
                                                {userBobos.owned?.map(boboNft => <BoboCard key={boboNft.boboName} boboNft={boboNft} isSwitchToCivCard={false} className="col-3 col-xl-2 mx-2 my-2" transformData={transformData} selectBobo={selectBobo} />)}
                                            </div>
                                        </div>
                                    </div>
                                    <div id="bow-pick-container" className="d-flex flex-column align-items-center w-100 mt-5">
                                        <h4>3. Select amount of BOW to pay</h4>
                                        <div className="d-flex justify-content-center col-11 col-sm-10 col-xl-6 col-xxl-4 mx-auto mt-4 pt-3">
                                            <span>Min: {MIN_BOW_PAY} BOW <br /> (lower chance of rares)</span>
                                            <input type="number" min={MIN_BOW_PAY} max={MAX_BOW_PAY} value={transformData.bowPaid} onChange={(e) => setTransformData({ ...transformData, bowPaid: Number(e.target.value) })} onBlur={() => verifyBowPaidValue()} className="mx-2 mx-sm-4 mx-lg-5" />
                                            <span>Max: {MAX_BOW_PAY} BOW <br /> (higher chance of rares)</span>
                                        </div>
                                    </div>
                                    <button id="transform-btn" className="btn btn-light mt-5" disabled={!canTransform} onClick={initiateTransform}>Transform</button>
                                </div></> : <h1 style={{ marginTop: "8rem" }}>You missed out on upgrading your Bobos to Civilians this time! <br />Watch out for when more spots open <b style={{ textDecoration: "underline" }}><i>soon</i></b>!</h1>}
                        </div>
                        : currentSwitchOption == SwitchOption.Resume ?
                            <div id="resume-switch-container" className="switch-container d-flex flex-column align-items-center mt-5">
                                <img src={resumeOptionBackground} className="background-image" />
                                <div className="background-image-extension"></div>
                                <div className="d-flex flex-column align-items-center w-100" style={{ zIndex: 10 }}>
                                    <div className="d-flex justify-content-center w-100">
                                        <div id="civilian-pick-container" className="d-flex flex-column col-11 col-lg-10 px-3">
                                            <h2>1. Pick Bobo to Transform</h2>
                                            <div className="row justify-content-center mt-4">
                                                {userBobos.staked?.map(boboNft => <BoboCard key={boboNft.boboName} boboNft={boboNft} isSwitchToCivCard={true} className="col-3 col-xl-2 mx-2 my-2" transformData={transformData} selectBobo={selectBobo} />)}
                                                {userBobos.owned?.map(boboNft => <BoboCard key={boboNft.boboName} boboNft={boboNft} isSwitchToCivCard={true} className="col-3 col-xl-2 mx-2 my-2" transformData={transformData} selectBobo={selectBobo} />)}
                                            </div>
                                        </div>
                                    </div>
                                    <div id="payment-tx-container" className="d-flex flex-column align-items-center w-100 mt-5">
                                        <h2>2. Input the payment Tx ID</h2>
                                        <div className="d-flex justify-content-center col-11 col-md-10 col-lg-8 col-xl-6 col-xxl-5 mx-auto mt-4 pt-3">
                                            <input type="text" value={paymentTxInput} onChange={(e) => setPaymentTxInput(e.target.value)} onBlur={() => verifyBowPaidValue()} className="simple-input w-100" />
                                        </div>
                                    </div>
                                    <button id="transform-btn" className="btn btn-light mt-5" disabled={!canTransform} onClick={resumeTransform}>Transform</button>
                                </div>
                            </div>
                            : null
            }

            <Modal id="civilian-switch-result-modal" show={transformData.transforming || transformData.transformResult !== null && transformData.transformResult?.error === null} onHide={() => setTransformData({ ...transformData, transformResult: null })} size="lg" centered>
                <Modal.Body className="d-flex flex-column align-items-center">
                    <div className="switch-image-container">
                        <img src={transformedBoboWarImage} id="war-bobo-image" className="img-fluid" />
                    </div>
                    <FontAwesomeIcon icon={faArrowDown} size="2x" className="my-4" />
                    <div className="switch-image-container d-flex align-items-center justify-content-center">
                        {transformData.transforming ? <div className="d-flex flex-column align-items-center justify-content-center"><h6 className="mb-4">{generationText}</h6><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div> :
                            <img src={transformedBoboCivilianImage} id="civilian-bobo-image" className="img-fluid" />
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    );
}


export default CiviliansHandler;
