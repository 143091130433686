import {
    Connection,
    PublicKey,
    MemcmpFilter,
    DataSizeFilter,
    Keypair,
    Transaction,
    sendAndConfirmTransaction
} from "@solana/web3.js";
import {
    Wallet, web3
} from '@project-serum/anchor';
import * as spl from '@solana/spl-token';

import axios from "axios";

import { BOW_MINT } from "../OnChain/chainInfo"

const TREASURY = new PublicKey("FT7gy5BnPZpfkpUKQ7xeCsZVF3QuLof5nMvEF97tzxPH")
// const API_URL = "http://localhost:8080";
const API_URL = "https://us-central1-bow-api.cloudfunctions.net/api"

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export async function transformBobo(
    connection: Connection,
    wallet: any,
    bowPaid: number,
    burnedBobo: string,
    switchedBobo: string,
): Promise<{ success: boolean, error: string | null, metadataUrl: string | null }> {
    const paymentTx = await sendTransformTx(connection, wallet, bowPaid, burnedBobo);



    return await sendTransformApiCall(wallet, switchedBobo, paymentTx);
}

export async function sendTransformApiCall(
    wallet: any,
    switchedBobo: string,
    paymentTx: string
) {
    const data = JSON.stringify({ boboOwner: wallet.publicKey, boboMint: switchedBobo, paymentTx });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/v1/civilians/switch`, data, customConfig);
    } catch (error: any) {
        console.error(error);
        return { success: false, error: "An error occured while switching Bobo to civilian. Check the console for more details.", metadataUrl: null };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, error: responseData.error, metadataUrl: null };
    }

    return { success: true, error: null, metadataUrl: responseData.metadataUrl };
}

export async function sendTransformTx(
    connection: Connection,
    wallet: any,
    bowPaid: number,
    burnedBobo: string
): Promise<string> {
    // console.log(spl);
    const burnedBoboMint = new PublicKey(burnedBobo);

    console.log(wallet.publicKey.toString());
    console.log(bowPaid);
    console.log(burnedBobo);

    // console.log((await connection.getParsedTokenAccountsByOwner(wallet.publicKey, { mint: BOW_MINT })).value[0].pubkey)
    // return ""
    try {
        const payerBowTokenAccount = (await connection.getParsedTokenAccountsByOwner(wallet.publicKey, { mint: BOW_MINT })).value[0].pubkey
        const payerBurnedBoboTokenAccount = (await connection.getParsedTokenAccountsByOwner(wallet.publicKey, { mint: burnedBoboMint })).value[0].pubkey

        const authBowTokenAccount = await spl.Token.getAssociatedTokenAddress(spl.ASSOCIATED_TOKEN_PROGRAM_ID, spl.TOKEN_PROGRAM_ID, BOW_MINT, TREASURY);
        const authBurnedBoboAta = await spl.Token.getAssociatedTokenAddress(spl.ASSOCIATED_TOKEN_PROGRAM_ID, spl.TOKEN_PROGRAM_ID, burnedBoboMint, TREASURY);
        const authBurnedBoboAtaAccountInfo = await connection.getAccountInfo(authBurnedBoboAta);

        const transaction = new Transaction()
        if (authBurnedBoboAtaAccountInfo === null) transaction.add(
            spl.Token.createAssociatedTokenAccountInstruction(
                spl.ASSOCIATED_TOKEN_PROGRAM_ID,
                spl.TOKEN_PROGRAM_ID,
                burnedBoboMint,
                authBurnedBoboAta,
                TREASURY,
                wallet.publicKey
            ))
        transaction.add(
            spl.Token.createTransferInstruction(
                spl.TOKEN_PROGRAM_ID,
                payerBowTokenAccount,
                authBowTokenAccount,
                wallet.publicKey,
                [],
                bowPaid * 10e8
            ),
            spl.Token.createTransferInstruction(
                spl.TOKEN_PROGRAM_ID,
                payerBurnedBoboTokenAccount,
                authBurnedBoboAta,
                wallet.publicKey,
                [],
                1
            )
        );

        // const signature = await web3.sendAndConfirmTransaction(connection, transaction, [wallet.payer]);


        let { blockhash } = await connection.getRecentBlockhash();
        transaction.feePayer = wallet.publicKey;
        transaction.recentBlockhash = blockhash;

        // const signedTx = await wallet.signTransaction(transaction)
        const paymentTx = await wallet.sendTransaction(transaction)

        let parsedTx = null;
        let delayCount = 0;
        while (parsedTx === null && delayCount < 10) {
            parsedTx = await connection.getParsedTransaction(paymentTx);
            console.log(parsedTx);

            if (parsedTx === null) await delay(1000);
            delayCount++;
        }

        return paymentTx;
    } catch (err) {
        console.log(err);
        return ""
    }

    // transaction.sign(wallet.payer)

    // const signature = await connection.sendRawTransaction(transaction.serialize(), {
    //     skipPreflight: true,
    // })
    // await connection.confirmTransaction(signature, "processed")

    // return signature
}