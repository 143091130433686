import { 
  PublicKey,
  Connection,
  ConfirmOptions,
  Transaction,
  SignatureResult
} from '@solana/web3.js';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  Token
} from "@solana/spl-token";

import * as anchor from "@project-serum/anchor";
import { Program, Provider, Wallet, BN } from '@project-serum/anchor';

import {CANDY_MACHINE_ADDRESS} from "./chainInfo"

import ranks from './ranks.json'
import boboBranches from "./boboBranches.json"

import {STAKING_PROGRAM_ID, TRAINING_CAMP_PROGRAM_ID, DAO_VOTING_PROGRAM_ID, SWAP_VBOW_PROGRAM_ID, BOBO_METADATA_PROGRAM_ID} from "./programsInfo"

import { toast } from 'react-toastify';

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const shortenAddress = (address: string, chars = 4): string => {
  return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};

export const getPubKey = (address: string | PublicKey): PublicKey => {
  if(typeof address === 'string') {
    address = new PublicKey(address);
  }
  return address;
}

export const getBoboInfoFromMint = (userBobos: any, mint: string): any => {
  for(let i = 0; i < userBobos.length; i++) {
    let bobo = userBobos[i];
    if (bobo.mint == mint) {
      return [bobo, i];
    }
  }
}

const opts: ConfirmOptions = {
  preflightCommitment: "processed"
}

export async function getProvider(connection: Connection, wallet: Wallet) {
  const provider = new Provider(
      connection, wallet, opts,
  );
  return provider;
}

export function getAssociatedTokenAddress(
  walletAddress: PublicKey,
  tokenAddress: PublicKey,
  allowOffCurve: boolean = false
): Promise<PublicKey> {
  return Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tokenAddress,
    walletAddress,
    allowOffCurve
  );
}


/// Bobo metadata
export async function getAdminInfo(
  program: Program<any>
) {
  let [adminInfo] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("admin-info")
    ],
    BOBO_METADATA_PROGRAM_ID
  )

  return adminInfo
}

export async function getBoboMetadata(
  boboMint: PublicKey
) {
  let [boboMetadata] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("bobo-metadata"),
      boboMint.toBuffer()
    ],
    BOBO_METADATA_PROGRAM_ID
  )

  return boboMetadata
}


/// Training camp
export async function getCampDataAddress() {
  let [campDataAddress] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("camp-data")
    ],
    TRAINING_CAMP_PROGRAM_ID
  )

  return campDataAddress
}

export async function getTrainingInfoAddress(
  mint: PublicKey
) {
  const [trainingInfo] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("training-info"),
      mint.toBuffer()
    ],
    TRAINING_CAMP_PROGRAM_ID
  )

  return trainingInfo
}


/// Hibernation station
export async function getStakingVaultAddress(): Promise<PublicKey> {
  let [vaultDataAddress] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("vault"),
      CANDY_MACHINE_ADDRESS.toBuffer()
    ],
    STAKING_PROGRAM_ID
  )

  return vaultDataAddress
}

export async function getStakeDataAddress(
  mint: PublicKey,
): Promise<PublicKey> {
  let [stakeDataAddress] = await PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("stake-data"),
      mint.toBuffer()
    ],
    STAKING_PROGRAM_ID
  );
  return stakeDataAddress;
}


/// Pool swap
export async function getPoolInfo(): Promise<anchor.web3.PublicKey> {
  let [poolInfo] = await anchor.web3.PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("pool-info")
    ],
    SWAP_VBOW_PROGRAM_ID
  )
  return poolInfo
}


/// DAO VOTING

export async function getGovermentPDA(
  votersCollectionCandyMachine: PublicKey
): Promise<PublicKey> {
  const [government] = await PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("government"),
      votersCollectionCandyMachine.toBuffer()
    ],
    DAO_VOTING_PROGRAM_ID
  )
  return government
}

export async function getTopicTagPDA(
  government: PublicKey,
  name: string
) {
  const [topicTag] = await PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("topic-tag"),
      government.toBuffer(),
      anchor.utils.bytes.utf8.encode(name)
    ],
    DAO_VOTING_PROGRAM_ID
  )
  return topicTag
}

export async function getLawPDA(
  government: PublicKey,
  lawIndex: BN
): Promise<PublicKey> {
  const [law] = await PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("law"),
      government.toBuffer(),
      lawIndex.toArrayLike(Buffer, 'le', 4)
    ],
    DAO_VOTING_PROGRAM_ID
  )
  return law
}

export async function getVoteBallotPDA(
  law: PublicKey,
  voterBobo: PublicKey
): Promise<PublicKey> {
  const [voteBallot] = await PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("vote-ballot"),
      law.toBuffer(),
      voterBobo.toBuffer()
    ],
    DAO_VOTING_PROGRAM_ID
  )
  return voteBallot
}

export async function getLawCommentPDA(
  law: PublicKey,
  commentIndex: BN
): Promise<PublicKey> {
  const [lawComment] = await PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("law-comment"),
      law.toBuffer(),
      commentIndex.toArrayLike(Buffer, 'le', 4)
    ],
    DAO_VOTING_PROGRAM_ID
  )
  return lawComment
}

export async function getCommentLikePDA(
  comment: PublicKey,
  boboMint: PublicKey
): Promise<PublicKey> {
  const [commentLike] = await PublicKey.findProgramAddress(
    [
      anchor.utils.bytes.utf8.encode("comment-like"),
      comment.toBuffer(),
      boboMint.toBuffer()
    ],
    DAO_VOTING_PROGRAM_ID
  )
  return commentLike
}


export async function sendAndConfirmTransactions(
  connection: Connection,
  wallet: Wallet,
  transactions: Transaction[]
): Promise<SignatureResult[]> {
  let { blockhash } = await connection.getRecentBlockhash("singleGossip");

  transactions.forEach((transaction) => {
    console.log("Transaction tick")
    transaction.feePayer = wallet.publicKey;
    transaction.recentBlockhash = blockhash;
  });

  let signedTransactions: Transaction[] = await wallet.signAllTransactions(transactions);

  let signatures: string[] = await Promise.all(
    signedTransactions.map((transaction) =>
      connection.sendRawTransaction(transaction.serialize(), {
        skipPreflight: true,
      })
    )
  );
  // toast.info("Waiting for confirmation")
  toast.info("Waiting for confirmation")
  let rpcResponses = await Promise.all(
    signatures.map((signature) =>
      connection.confirmTransaction(signature, "processed")
    )
  );
  // console.log("Transactions success!")

  return rpcResponses.map(resp => {
    announceResponse(resp.value); return resp.value})
}

function announceResponse(result: SignatureResult) {
  if(result.err === null) {
    toast.success("Transaction successful")
  } else {
    console.error(result.err)
    toast.error("An error occured with the transaction")
  }
}










export function getRole(
  token: PublicKey,
): any {
  let boboRanks: any = ranks;
  let boboMint: string = token.toString()
  let role: number = boboRanks[boboMint]
  return role;
}
export function getBranch(
  name: string
): string {
  let branches: any = boboBranches;
  let branch: string = branches[name]
  return branch;
}


export function parseUintLe(data: Uint8Array, offset: number = 0, length: number): bigint {
  let number = BigInt(0);
  for (let i = 0; i < length; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 4);
  return number;
}
// function parseUint64Le(data: Uint8Array, offset: number = 0): bigint {
//   let number = BigInt(0);
//   for (let i = 0; i < 8; i++)
//     number += BigInt(data[offset + i]) << BigInt(i * 8);
//   return number;
// }