import "./StakingCard.css";
import React from "react"

import {getRole, getMintCurrentRewards, StakedData} from "../../utils/newStaking.ts"

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { LazyLoadImage } from 'react-lazy-load-image-component';


import 'react-lazy-load-image-component/src/effects/blur.css';

import BowImg from "../../images/bow.png"

const rank_obsufcation = {
    37: 12,
    83: 5,
    15: 4,
    91: 3.5,
    27: 3,
    52: 2.5,
    77: 2,
    49: 1.75,
}

const placeholderDiv = <div className="image-placeholder w-100" style={{background: "grey", borderRadius: "2rem", height: 0, paddingBottom: "100%"}}></div>


class StakingCard extends React.Component {

    componentDidMount() {
        if(this.state.staked) {
            setInterval(this.getCurrentRewards, 1000)
        }
        this.getImage()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.nft !== this.props.nft) {
            this.setState({
                staked: this.props.staked,
                nft: this.props.nft,
                name: this.props.nft.data.name,
                uri: this.props.nft.uri,
                image: null,
                role: getRole(this.props.nft.mint),
                rankMultiplier: rank_obsufcation[getRole(this.props.nft.mint)],
                mint: this.props.nft.mint,
                currentRewards: 0,
            }, this.getImage)
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            staked: this.props.staked,
            nft: this.props.nft,
            name: this.props.nft.data.name,
            uri: this.props.nft.uri,
            role: getRole(this.props.nft.mint),
            rankMultiplier: rank_obsufcation[getRole(this.props.nft.mint)],
            mint: this.props.nft.mint,
            currentRewards: 0,
            timeLeft: 0
        }
        this.getImage = this.getImage.bind(this)
        this.getCurrentRewards = this.getCurrentRewards.bind(this)
    }

    async getImage() {
        let json = await fetch(this.state.uri).then(response => response.json())
        this.setState({image: json.image})
    }

    getCurrentRewards() {
        let currentRewards = Number(getMintCurrentRewards(
            this.props.vaultData,
            this.state.nft.timestamp,
            this.state.nft.lockedDuration,
            this.state.nft.rarityBracket,
            this.state.nft.withdrawn,
          )).toFixed(4)

        let secondsLeft = (this.state.nft.timestamp + this.state.nft.lockedDuration) - new Date() / 1000
        let timeLeft = this.beautifyTimeLeft(secondsLeft)
        this.setState({currentRewards, timeLeft})
    }

    beautifyTimeLeft(secondsLeft) {
        if (secondsLeft < 0) {
            return "DONE"
        }
        var fm = [
            Math.floor(secondsLeft / 60 / 60 / 24), // DAYS
            Math.floor(secondsLeft / 60 / 60) % 24, // HOURS
            Math.floor(secondsLeft / 60) % 60, // MINUTES
            Math.floor(secondsLeft % 60) // SECONDS
      ];
        return fm.map((v,i) => { return ( (v < 10) ? '0' : '' ) + v; }).join( ':' );
    }

    render() {
        return(
            <Card className={this.props.className}>
                <Card.Body className='p-0 text-white'>
                    {this.props.showImages &&
                        <LazyLoadImage src={this.state.image} className="w-100 mb-2" style={{borderRadius: "2rem"}} placeholder={placeholderDiv} effect='blur' threshold={100} />}
                    <h4 className="text-start">{this.state.name}</h4>
                    <div className="d-flex justify-content-around" style={{height: "50px"}}>
                        {this.props.staked && <span><h5><img src={BowImg} width="25rem" className="mb-1" /> {this.state.currentRewards}</h5></span>}
                        <div className="d-none d-md-inline"><h5>{this.props.staked ? this.state.nft.rewardMultiplier: this.state.rankMultiplier}x</h5></div>
                    </div>
                    {this.props.staked ?
                        <>
                            <div className="d-flex justify-content-center justify-content-lg-between px-3 mb-2">
                                <span className="d-none d-lg-inline"><b>Time Left:</b></span> 
                                <span><b>{this.state.timeLeft}</b></span>
                            </div>
                            <Button variant="none" className="text-white selected-gradient no-focus w-100 mb-2" onClick={() => this.props.withdrawRewards(this.state.mint)}><b>Withdraw</b></Button>
                            {/* Add relock button IF lock duration passed */}
                            <Button variant="danger" className="w-100" onClick={() => this.props.unstakeToken(this.state.mint)}><b>Unstake</b></Button>
                        </>
                        :
                        <>
                            <Button variant="none" className="text-white selected-gradient no-focus w-100 mb-2" onClick={() => this.props.selectMint(this.state.mint)}><b>Stake/Lock</b></Button>
                            <Button variant="danger" className="w-100" style={{visibility: "hidden"}}><b>Unstake</b></Button>
                        </>
                    }
                </Card.Body>
            </Card>
        )
    }
}

export default StakingCard;