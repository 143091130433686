// import React from 'react'
import "./ToolsHandler.css"
import { FC, useState, useEffect } from "react";

import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import {
    WalletDisconnectButton,
    WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { shortenAddress } from "./OnChain/utils"

import bobosLogo from "./images/logo512.png"

// import HomeContainer from "./HomeContainer"
import StakingHandler from "./Tools/Staking/StakingHandler"
import MetricsHandler from "./Tools/Metrics/MetricsHandler"
// import DexHandler from "./Tools/DEX/DexHandler"
import TokenSwapHandler from "./Tools/TokenSwap/TokenSwapHandler"
import DAOVotingHandler from "./Tools/DAOVoting/DAOVotingHandler"
import Tracker from "./Tools/Tracker/TrackerHandler"
import TokenomicsHandler from "./Tools/Tokenomics/TokenomicsHandler"
import CiviliansHandler from "./Tools/Civilians/CiviliansHandler"

const authorizedWallets = [
    "FT7gy5BnPZpfkpUKQ7xeCsZVF3QuLof5nMvEF97tzxPH",
    "91rg31SN78JqAU3527TAeVyo2NZdEyc6nbv8NJ4XGYdk",
    "DQXa5Eaf5ZdK4ojvN14iNvmhmTqJXTp72pJKt3BwviP4",

    "HMsen7CFW9GQQVSF18DDN2PstSusbrTtMzDGUn2KMvWT",
    "CumLpQb9EQkqjwCYfZZ456Yq1CPt1hUSmjWryZkD4Rdp"
]

const ToolHandler: FC<any> = (props) => {
    const location = useLocation()
    const { connection } = useConnection();
    const wallet = useWallet();

    // const [showContentBlocker, setShowContentBlocker] = useState(false)

    const [locationPaths, setLocationPaths] = useState<string[]>([])

    useEffect(() => {
        console.log(location.pathname)

        // location.pathname.replace(/https:\/\/.+\//, "")
        const paths = location.pathname.slice(1, location.pathname.length).split("/")
        console.log(paths)
        setLocationPaths(paths)

    }, [location])

    return (
        <div id="Bow-tools">
            <Navbar id="Connection-Navbar" expand="lg">
                <Container className="mx-3 w-100" style={{ maxWidth: "unset" }}>
                    {/* <Navbar.Brand href="/home"><h1 className="text-white text-ussr" style={{fontSize: "5rem", fontWeight: "bold"}}>Bobos</h1></Navbar.Brand> */}
                    <Navbar.Brand href="/">
                        <img
                            src={bobosLogo}
                            width="100"
                            height="auto"
                            className="d-inline-block align-top ms-3"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ background: "white" }} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto ms-0 ms-lg-5">
                            <NavDropdown title="Boboverse">
                                <NavDropdown.Item href="/dao"><h5 className={`text-ussr ${location?.pathname === "/dao" ? "text-selected" : "text-white"} mb-0`}>DAO</h5></NavDropdown.Item>
                                <NavDropdown.Item href="/metrics"><h5 className={`text-ussr ${location?.pathname === "/metrics" ? "text-selected" : "text-white"} mb-0`}>Metrics</h5></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="BOW Coin">
                                <NavDropdown.Item href="/swap"><h5 className={`text-ussr ${location?.pathname === "/swap" ? "text-selected" : "text-white"} mb-0`}>Swap</h5></NavDropdown.Item>
                                <NavDropdown.Item href="/tokenomics"><h5 className={`text-ussr ${location?.pathname === "/tokenomics" ? "text-selected" : "text-white"} mb-0`}>Tokenomics</h5></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Bobos">
                                <NavDropdown.Item href="/staking"><h5 className={`text-ussr ${location?.pathname === "/staking" ? "text-selected" : "text-white"} mb-0`}>Stake</h5></NavDropdown.Item>
                                <NavDropdown.Item href="/civilians"><h5 className={`text-ussr ${location?.pathname === "/civilians" ? "text-selected" : "text-white"} mb-0`}>Civilians</h5></NavDropdown.Item>
                                <NavDropdown.Item href="/tracker"><h5 className={`text-ussr ${location?.pathname === "/tracker" ? "text-selected" : "text-white"} mb-0`}>Tracker</h5></NavDropdown.Item>
                                {/* <NavDropdown.Item href="/DEX"><h5 className={`text-ussr ${location?.pathname === "/dex" ? "text-selected" : "text-white"} mb-0`}>DEX</h5></NavDropdown.Item> */}
                            </NavDropdown>
                        </Nav>
                        <div id="navbar" className="d-flex justify-content-center mx-auto mx-lg-3 mt-4 mt-lg-0">
                            {wallet?.connected && wallet?.publicKey !== null && <h6 className="text-white my-auto me-3">{shortenAddress(wallet?.publicKey.toString(), 8)}</h6>}
                            {/* //<WalletDisconnectButton children={<h3 className='text-ussr m-0'>Disconnect</h3>} className="selected-gradient me-3" />} */}
                            <WalletMultiButton children={<h3 className='text-ussr m-0'>{wallet?.connected ? "Account" : "Connect"}</h3>} className="selected-gradient" />
                        </div>
                    </Navbar.Collapse>
                </Container>

            </Navbar>
            <Switch>
                <Redirect exact from='/' to='/staking' />
                {/* <Route exact path='/home' children={<HomeContainer connection={connection} wallet={wallet} />}></Route> */}
                <Route exact path='/staking' children={<StakingHandler connection={connection} wallet={wallet} />}></Route>
                <Route exact path='/swap' children={<TokenSwapHandler connection={connection} wallet={wallet} />}></Route>
                <Route exact path='/dao' children={<DAOVotingHandler connection={connection} wallet={wallet} />}></Route>
                <Route exact path='/metrics' children={<MetricsHandler connection={connection} wallet={wallet} />}></Route>
                {/* <Route exact path='/dex' children={<DexHandler connection={connection} wallet={wallet} />}></Route> */}
                <Route exact path='/tokenomics' children={<TokenomicsHandler connection={connection} wallet={wallet} />}></Route>
                <Route path='/civilians' children={<CiviliansHandler connection={connection} wallet={wallet} locationPaths={locationPaths} />}></Route>
                {/* {wallet.publicKey !== null && authorizedWallets.includes(wallet.publicKey.toString()) && <Route exact path='/dao' children={<DAOVotingHandler connection={connection} wallet={wallet} />}></Route>} */}
                <Route exact path='/tracker' children={<Tracker connection={connection} wallet={wallet} />}></Route>
            </Switch>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    )
}


export default ToolHandler;