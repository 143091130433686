import "./TokenSwapHandler.css"
import { FC, useEffect, useState, useReducer } from "react";

import tokenLogo from "../../images/bow.png"
import vBOWLogo from "../../images/BIALogo.png"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { VBOW_MINT } from "../../OnChain/chainInfo"
import { swapVBOW } from "../../utils/tokenSwap"

import { toast } from 'react-toastify';

const TokenSwapHandler: FC<any> = (props) => {
    const { connection, wallet } = props;

    const [holderVBOW, setHolderVBOW] = useState(0)
    const [refreshHandle, forceRefresh] = useReducer((x) => x + 1, 0);
    const ratio = 14;
    const ratioDecimals = 2;

    useEffect(() => {
        async function fetchHeldVBOW() {
            if(wallet.publicKey !== null) {
                let accountsData = await connection.getParsedTokenAccountsByOwner(wallet.publicKey, {mint: VBOW_MINT});
                if(accountsData.value.length) {
                    setHolderVBOW(accountsData.value[0].account.data.parsed.info.tokenAmount.uiAmount)
                }
            }
        }

        fetchHeldVBOW()
    }, [connection, wallet, refreshHandle])

    const swapContainer = (info: any) => <div className="swap-container d-flex align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100 h-100 px-3">
            <img src={info.logo} className="img-fluid p-3" style={{"width": "90px"}} />
            <span>{info.amount.toFixed(2)}</span>
        </div>
    </div>

    async function initSwap() {
        toast.info("Converting wallet $vBOW to $BOW")
        let response = await swapVBOW(connection, wallet)
        if (response === true) {
            toast.success("Converted $vBOW to $BOW")
        } else {
            toast.error("Failed while converting to $BOW: " + response)
        }
        setTimeout(forceRefresh, 3000);
    }

    return(
        <div id="Swap-App" style={{marginTop: "3rem", marginBottom: "8rem"}}>
            <div className="container-fluid d-flex flex-column justify-content-start align-items-center text-white">
                <h1 className="title text-ussr">Token Swap</h1>
                <img src={tokenLogo} className="img-fluid" />
                <div className="row w-100 mt-4">
                    <div className="col-5 d-flex justify-content-end align-items-center">{swapContainer({logo: vBOWLogo, amount: holderVBOW})}</div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={faArrowRight} size="2x" />
                    </div>
                    <div className="col-5 d-flex justify-content-start align-items-center">{swapContainer({logo: tokenLogo, amount: Math.floor(holderVBOW*ratio/Math.pow(10, ratioDecimals))})}</div>
                </div>
                <button className="btn selected-gradient mt-5 px-3" onClick={initSwap}><h1 className="text-white text-ussr m-0">SWAP</h1></button>
            </div>
            
        </div>
    )
}

export default TokenSwapHandler