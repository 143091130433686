import "./LawCreationPanel.css"
import { FC, useEffect, useState, useRef } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import Dropdown from "react-bootstrap/Dropdown"

import CitizenCircle from "./CitizenCircle"
import { createLaw } from "../../utils/daoVoting"
import { NftData, LawProposition, Law, Comment } from "../../OnChain/chainInfo"
import { delay } from "../../OnChain/utils"

import { BN } from '@project-serum/anchor';

import Spinner from "react-bootstrap/Spinner"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { gsap } from "gsap";
import { toast } from "react-toastify";

const minStartDate = new Date((new Date()).getTime() + 1450 * 60000)
const maxStartDate = new Date((new Date()).getTime() + 43_200 * 60000)

const LawCreationPanel: FC<any> = (props) => {
    const { connection, wallet, government, bobos, returnFunction } = props;

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    const [propositions, setPropositions] = useState<string[]>([""])
    const [selectedTopicTag, setSelectedTopictag] = useState<string>("Brand")

    const [selectedCreator, setSelectedCreator] = useState<NftData>()

    const [startDate, setStartDate] = useState(minStartDate)
    const [selectedDuration, setSelectedDuration] = useState<number>(government.minLawDaysDuration)


    function updatePropositionText(e: any, index: number) {
        let newPropositions = propositions
        newPropositions[index] = e.target.value
        setPropositions([...newPropositions])
    }
    function removeProposition(index: number) {
        let newPropositions = propositions.filter((prop, propIndex) => propIndex !== index)
        setPropositions(newPropositions)
    }

    function buildLaw() {
        let lawPropositions = propositions.map(proposition => { return { title: proposition, votes: 0 } })
        if (title.length === 0 || description.length === 0) {
            toast.error("Not all fields were filled")
            return
        }
        if (selectedCreator === undefined) {
            toast.error("Select a Bobo creator")
            return
        }
        createLaw(
            connection,
            wallet,
            title,
            description,
            lawPropositions,
            selectedTopicTag,
            new BN(Math.floor(startDate.getTime() / 1000)),

            new BN(selectedDuration),
            selectedCreator
        )
    }

    return (
        <div id="Law-Creation-Panel" className="container-fluid d-flex flex-column px-lg-5">
            <FontAwesomeIcon icon={faArrowLeft} className="pointer-hover hover-text-selected-drop text-white align-self-start mb-4" onClick={returnFunction} size="2x" />
            <h1 className="text-start text-ussr text-white">Create Law Proposition</h1>
            <div className={"row law-container no-hover selected-shadow justify-content-between w-100 mx-auto my-3 p-3"}>
                <div className="col-9 col-xxl-7 d-flex flex-column pe-4 mt-4">
                    <div className="d-flex w-100">
                        <div className="d-flex flex-column w-100">
                            <textarea className="law-creation-input-text styled-input" style={{ fontSize: "2rem" }} placeholder="Title" onChange={(e) => setTitle(e.target.value)} maxLength={government.maxLawTitleLength}></textarea>
                            <p className={`text-end`}>{government.maxLawTitleLength - title.length} left</p>
                        </div>
                        <span className="badge rounded-pill selected-gradient text-white ms-3" style={{ fontSize: "1rem", height: "fit-content" }}>{selectedTopicTag}</span>
                    </div>
                    <div className="d-flex flex-column w-100">
                        <textarea className="law-creation-input-text styled-input" placeholder="Description" onChange={(e) => setDescription(e.target.value)} maxLength={government.maxLawDescriptionLength}></textarea>
                        <p className={`text-end`}>{government.maxLawTitleLength - description.length} left</p>
                    </div>
                    <h2 className="text-start mt-5 mb-3">Propositions: </h2>
                    <div className="d-flex flex-column w-50 ms-5">
                        {propositions.map((e, index) => <div className="d-flex flex-column w-100 my-3">
                            <div className="d-flex align-items-center w-100">
                                <input key={index} type="text" className="law-creation-input-text w-100" placeholder="Proposition Title" value={propositions[index]} maxLength={government.maxPropositionTitleLength} onChange={(e) => updatePropositionText(e, index)}></input>
                                {propositions.length > 1 && <FontAwesomeIcon icon={faXmark} className="pointer-hover ms-2" onClick={() => removeProposition(index)} />}
                            </div>
                            <p className={`text-end me-4`}>{government.maxPropositionTitleLength - propositions[index].length} left</p>
                        </div>
                        )}
                        {propositions.length < government.maxPropositions &&
                            <button className="btn selected-gradient text-white w-50 mx-auto" onClick={() => propositions.length < government.maxPropositions && setPropositions([...propositions, ""])}><h4 className="m-0">ADD</h4></button>
                        }
                    </div>
                    <div className="d-flex justify-content-end">
                        <OverlayTrigger
                            trigger="click"
                            placement="top"
                            overlay={
                                <Popover id={`popover-positioned-top`}>
                                    <Calendar onChange={setStartDate} value={startDate} minDate={minStartDate} minDetail="year" maxDate={maxStartDate} />
                                </Popover>
                            }
                        >
                            <button className="btn selected-gradient"><h3 className="text-white text-ussr m-0">Start Date</h3></button>
                        </OverlayTrigger>
                        <Dropdown className="d-inline mx-2">
                            <Dropdown.Toggle id="dropdown-autoclose-true" className="selected-gradient">
                                <h3 className="text-white text-ussr">{selectedDuration} Days</h3>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {[...Array(government.maxLawDaysDuration - government.minLawDaysDuration + 1)].map((elem, index) => <Dropdown.Item onClick={() => setSelectedDuration(government.minLawDaysDuration + index)}>{(government.minLawDaysDuration + index).toString()}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-1 d-flex justify-content-center py-5">
                    <div className="col-break selected-gradient"></div>
                </div>
                <div className="col">
                    <h3 className="text-start text-ussr">Creator Bobo</h3>
                    <div className="row justify-content-around">
                        {bobos.map((bobo: NftData) => <CitizenCircle class="col-3 col-sm-2 col-md-2 col-lg-1 col-xxl-3 mx-2 my-2" connection={connection} wallet={wallet} key={bobo.name} nft={bobo} selected={selectedCreator === bobo} onClick={() => setSelectedCreator(bobo)} />)}
                    </div>
                </div>
            </div>
            <button className="btn selected-gradient my-5 mx-5" onClick={buildLaw}><h1 className="text-white text-ussr">Create</h1></button>
        </div>
    )
}

export default LawCreationPanel
