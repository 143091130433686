import { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { UnstakedData } from "../../../utils/newStaking";

export const useUnstakedNfts = (collectionCandymachine: string | undefined, refreshHandle?: any) => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const [nfts, setNfts] = useState<UnstakedData[]>([]);
  const[fetchingUnstakedData, setFetchingUnstakedData] = useState(false)

  useEffect(() => {
    (async () => {
      if (!publicKey) return;
      console.log("UPDATE CM: " + collectionCandymachine)
      setFetchingUnstakedData(true);
      const walletNfts = await Metadata.findDataByOwner(connection, publicKey);

      const nfts = [];
      for (let nft of walletNfts)
        if (
          nft.data.creators &&
          nft.data.creators[0]?.verified &&
          collectionCandymachine !== undefined &&
          nft.data.creators[0].address === collectionCandymachine
        )
          nfts.push({
            mint: new PublicKey(nft.mint),
            data: nft.data,
            uri: nft.data.uri
            // json: await fetch(nft.data.uri).then((resp) => {
            //   if(!resp.ok) {
            //     return null
            //   }
            //   return resp.json()
            // })
            // .catch(() => {return null}),
          });

      let collator = new Intl.Collator(undefined, { numeric: true });
      nfts.sort((a, b) => collator.compare(a.data.name, b.data.name))
      setNfts(nfts);
      setFetchingUnstakedData(false);
    })();
  }, [publicKey, connection, refreshHandle]);

  return {nfts, fetchingUnstakedData};
};
