import {
  Connection,
  PublicKey,
  MemcmpFilter,
  DataSizeFilter,
  Keypair,
  Transaction
} from "@solana/web3.js";

import * as anchor from "@project-serum/anchor";
import {
  Program, Provider, Wallet, web3, BN, Idl
} from '@project-serum/anchor';
import * as spl from '@solana/spl-token';

import { DAO_VOTING_PROGRAM_ID } from "../OnChain/programsInfo"
import daoVotingProgramIdl from "../OnChain/Programs/DaoVoting/idl/dao_voting.json"
import { getProvider, getAssociatedTokenAddress, getStakeDataAddress, getTrainingInfoAddress, getGovermentPDA, getLawPDA, getVoteBallotPDA, getLawCommentPDA, getCommentLikePDA, getTopicTagPDA, sendAndConfirmTransactions } from "../OnChain/utils"
import { CANDY_MACHINE_ADDRESS, NftData, GovernmentData, LawProposition, Law, Comment, LawComment, BOW_MINT, LawCreatorBobo, VoteBallot } from "../OnChain/chainInfo";

import {
  MetadataData,
} from "@metaplex-foundation/mpl-token-metadata";
import { programs } from '@metaplex/js';
import { toast } from "react-toastify";
const { metadata: { Metadata } } = programs;

export async function getGovernmentData(
  connection: Connection,
  wallet: Wallet,
): Promise<GovernmentData> {
  // return {
  //     presidentBobo: new PublicKey("9DNf8zRBvzNRfpFPWamZy2M61C9EpsYAryxtCUfdWAYw"),
  //     leaders: [new PublicKey("9DNf8zRBvzNRfpFPWamZy2M61C9EpsYAryxtCUfdWAYw"), new PublicKey("9DNf8zRBvzNRfpFPWamZy2M61C9EpsYAryxtCUfdWAYw")],

  //     lawQuorum: 3000,
  //     lawFee: 10 * Math.pow(10, 9),
  //     lawReward: 10 * Math.pow(10, 9),
  //     feeMint: BOW_MINT,

  //     lawCreationOpen: false,
  //     minLawDaysDuration: 1,
  //     maxLawDaysDuration: 7,

  //     maxLawTitleLength: 100,
  //     maxLawDescriptionLength: 100,
  //     maxPropositions: 3,
  //     maxPropositionTitleLength: 100,
  //     maxLawCommentLength: 100,

  //     votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,
  // }

  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let government = await getGovermentPDA(CANDY_MACHINE_ADDRESS)
  let fetchedGovernment = await program.account.government.fetch(government)
  return {
    governmentPublicKey: government,
    name: fetchedGovernment.name,
    presidentBobo: fetchedGovernment.presidentBobo,
    leaders: fetchedGovernment.leaders,

    lawQuorum: fetchedGovernment.lawQuorum,
    lawFee: fetchedGovernment.lawFee,
    lawReward: fetchedGovernment.lawReward,
    feeMint: fetchedGovernment.feeMint,

    lawCreationOpen: fetchedGovernment.lawCreationOpen,
    minLawDaysDuration: fetchedGovernment.minLawDaysDuration,
    maxLawDaysDuration: fetchedGovernment.maxLawDaysDuration,

    maxLawTitleLength: fetchedGovernment.maxLawTitleLength,
    maxLawDescriptionLength: fetchedGovernment.maxLawDescriptionLength,
    maxPropositions: fetchedGovernment.maxPropositions,
    maxPropositionTitleLength: fetchedGovernment.maxPropositionTitleLength,
    maxLawCommentLength: fetchedGovernment.maxLawCommentLength,

    votersCollectionCandyMachine: fetchedGovernment.votersCollectionCandyMachine
  }
}

export async function openLawCreation(
  connection: Connection,
  wallet: Wallet,
  boboMint: PublicKey
): Promise<boolean | any> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let government = await getGovermentPDA(CANDY_MACHINE_ADDRESS)

  let boboAccount = (await connection.getTokenLargestAccounts(boboMint)).value[0].address;

  try {
    await program.rpc.openOrCloseLawCreation(true, {
      accounts: {
        government,
        presidentWallet: wallet.publicKey,

        presidentBobo: boboMint,
        presidentBoboAccount: boboAccount,
        votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,
      }
    })
  } catch (e) {
    console.log(e)
    return e
  }

  return true

}

export async function createLaw(
  connection: Connection,
  wallet: Wallet,

  lawTitle: String,
  description: String,
  propositions: LawProposition[],
  topicTagName: string,
  startTime: BN,
  daysDuration: BN,

  bobo: NftData
): Promise<boolean | any> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let government = await getGovermentPDA(CANDY_MACHINE_ADDRESS);
  let fetchedGovernment = await program.account.government.fetch(government)

  let law = await getLawPDA(government, new BN(fetchedGovernment.nextLawIndex))

  let topicTag = await getTopicTagPDA(government, topicTagName)

  let governmentFeeAta = await getAssociatedTokenAddress(government, BOW_MINT, true)
  let lawCreatorFeeAta = await getAssociatedTokenAddress(wallet.publicKey, BOW_MINT)

  let boboAccount = (await connection.getTokenLargestAccounts(bobo.mint)).value[0].address;

  let hibStakingPDA = bobo.stakedData.staked ? await getStakeDataAddress(bobo.mint) : program.programId
  let campStakingPDA = bobo.campData.camped ? await getTrainingInfoAddress(bobo.mint) : program.programId

  try {
    toast.info("Sending law transaction")
    await program.rpc.createLaw(
      lawTitle,
      description,
      propositions,
      topicTagName,
      startTime,
      daysDuration,
      bobo.stakedData.staked,
      bobo.campData.camped,
      {
        accounts: {
          government,
          lawCreatorWallet: wallet.publicKey,

          lawCreatorBobo: bobo.mint,
          lawCreatorBoboAccount: boboAccount,
          lawCreatorBoboHibStakingAccount: hibStakingPDA,
          lawCreatorBoboCampStakingAccount: campStakingPDA,
          boboMetadataInfo: await Metadata.getPDA(bobo.mint),

          law,
          topicTag,

          feeMint: BOW_MINT,
          governmentFeeAta,
          lawCreatorFeeAta,

          votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,

          tokenProgram: spl.TOKEN_PROGRAM_ID,
          associatedTokenProgram: spl.ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId,
        }
      }
    )
  } catch (e) {
    console.log(e)
    return e
  }
  toast.success("Added Law proposition to the chain!")

  return true
}




export async function voteOnLaw(
  connection: Connection,
  wallet: Wallet,

  lawIndex: BN,
  propositionIndex: BN,

  voterBobo: NftData,
): Promise<boolean | any> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let government = await getGovermentPDA(CANDY_MACHINE_ADDRESS);
  let law = await getLawPDA(government, lawIndex)
  let voteBallot = await getVoteBallotPDA(law, voterBobo.mint)

  let voterBoboAccount = (await connection.getTokenLargestAccounts(voterBobo.mint)).value[0].address;

  let hibStakingPDA = voterBobo.stakedData.staked ? await getStakeDataAddress(voterBobo.mint) : program.programId
  let campStakingPDA = voterBobo.campData.camped ? await getTrainingInfoAddress(voterBobo.mint) : program.programId

  try {
    await program.rpc.voteOnLaw(
      propositionIndex,
      voterBobo.stakedData.staked,
      voterBobo.campData.camped,
      {
        accounts: {
          government,
          voterWallet: wallet.publicKey,

          voterBobo: voterBobo.mint,
          voterBoboAccount,
          voterBoboHibStakingAccount: hibStakingPDA,
          voterBoboCampStakingAccount: campStakingPDA,
          boboMetadataInfo: await Metadata.getPDA(voterBobo.mint),

          law,

          voteBallot,

          votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,

          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId,
        }
      }
    )
  } catch (e) {
    console.log(e)
    return e
  }

  return true
}

export async function multiVoteOnLaw(
  connection: Connection,
  wallet: Wallet,

  government: PublicKey,
  law: PublicKey,
  propositionIndex: BN,

  voterBobos: NftData[],
): Promise<boolean | any> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  // let government = await getGovermentPDA(CANDY_MACHINE_ADDRESS);
  toast.info("Voting on law...")
  async function getVoteTransactionForMint(
    voterBobo: NftData
  ): Promise<Transaction> {
    let voteBallot = await getVoteBallotPDA(law, voterBobo.mint)

    let voterBoboAccount = (await connection.getTokenLargestAccounts(voterBobo.mint)).value[0].address;
    let hibStakingPDA = voterBobo.stakedData.staked ? await getStakeDataAddress(voterBobo.mint) : program.programId
    let campStakingPDA = voterBobo.campData.camped ? await getTrainingInfoAddress(voterBobo.mint) : program.programId

    return program.transaction.voteOnLaw(
      propositionIndex,
      voterBobo.stakedData.staked,
      voterBobo.campData.camped,
      {
        accounts: {
          government,
          voterWallet: wallet.publicKey,

          voterBobo: voterBobo.mint,
          voterBoboAccount,
          voterBoboHibStakingAccount: hibStakingPDA,
          voterBoboCampStakingAccount: campStakingPDA,
          boboMetadataInfo: await Metadata.getPDA(voterBobo.mint),

          law,

          voteBallot,

          votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,

          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId,
        }
      }
    )
  }

  let transactions: Transaction[] = [];
  for (let voterBobo of voterBobos) {
    transactions.push(await getVoteTransactionForMint(voterBobo));
  }

  let response = await sendAndConfirmTransactions(connection, wallet, transactions);
  return true;
}


export async function commentOnLaw(
  connection: Connection,
  wallet: Wallet,

  government: PublicKey,
  law: PublicKey,

  comment: string,
  commenterBobo: NftData,
): Promise<boolean | any> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  // let government = await getGovermentPDA(CANDY_MACHINE_ADDRESS);

  let fetchedLaw = await program.account.law.fetch(law)
  let lawComment = await getLawCommentPDA(law, new BN(fetchedLaw.numComments))

  let commenterBoboAccount = (await connection.getTokenLargestAccounts(commenterBobo.mint)).value[0].address;
  let hibStakingPDA = commenterBobo.stakedData.staked ? await getStakeDataAddress(commenterBobo.mint) : program.programId
  let campStakingPDA = commenterBobo.campData.camped ? await getTrainingInfoAddress(commenterBobo.mint) : program.programId

  toast.info("Commenting on Law...")
  try {
    await program.rpc.commentOnLaw(
      comment,
      commenterBobo.stakedData.staked,
      commenterBobo.campData.camped,
      {
        accounts: {
          government,
          commenterWallet: wallet.publicKey,

          commenterBobo: commenterBobo.mint,
          commenterBoboAccount,
          commenterBoboHibStakingAccount: hibStakingPDA,
          commenterBoboCampStakingAccount: campStakingPDA,
          boboMetadataInfo: await Metadata.getPDA(commenterBobo.mint),

          law,

          lawComment,

          votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,

          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId,
        }
      }
    )
  } catch (e) {
    console.log(e)
    return e
  }
  toast.success("Commented on Law!")
  return true
}

export async function multiLikeLawComment(
  connection: Connection,
  wallet: Wallet,

  government: PublicKey,
  law: PublicKey,
  lawComment: PublicKey,
  likers: NftData[]
): Promise<boolean | any> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  toast.info("Liking comment...")
  async function getLikeTransactionForMint(
    likerBobo: NftData
  ): Promise<Transaction> {
    let commentLike = await getCommentLikePDA(lawComment, likerBobo.mint)

    let likerBoboAccount = (await connection.getTokenLargestAccounts(likerBobo.mint)).value[0].address;
    let hibStakingPDA = likerBobo.stakedData.staked ? await getStakeDataAddress(likerBobo.mint) : program.programId
    let campStakingPDA = likerBobo.campData.camped ? await getTrainingInfoAddress(likerBobo.mint) : program.programId

    return program.transaction.likeComment(
      likerBobo.stakedData.staked,
      likerBobo.campData.camped,
      {
        accounts: {
          government,
          likerWallet: wallet.publicKey,

          likerBobo: likerBobo.mint,
          likerBoboAccount,
          likerBoboHibStakingAccount: hibStakingPDA,
          likerBoboCampStakingAccount: campStakingPDA,
          boboMetadataInfo: await Metadata.getPDA(likerBobo.mint),

          law,
          lawComment,

          commentLike,

          votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,

          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId,
        }
      }
    )
  }

  let transactions: Transaction[] = [];
  for (let likerBobo of likers) {
    transactions.push(await getLikeTransactionForMint(likerBobo));
  }

  let response = await sendAndConfirmTransactions(connection, wallet, transactions);
  return true;
}
export async function multiDislikeLawComment(
  connection: Connection,
  wallet: Wallet,

  government: PublicKey,
  law: PublicKey,
  lawComment: PublicKey,
  likers: NftData[]
): Promise<boolean | any> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  toast.info("Disliking comment...")
  async function getLikeTransactionForMint(
    likerBobo: NftData
  ): Promise<Transaction> {
    let commentLike = await getCommentLikePDA(lawComment, likerBobo.mint)

    let likerBoboAccount = (await connection.getTokenLargestAccounts(likerBobo.mint)).value[0].address;
    let hibStakingPDA = likerBobo.stakedData.staked ? await getStakeDataAddress(likerBobo.mint) : program.programId
    let campStakingPDA = likerBobo.campData.camped ? await getTrainingInfoAddress(likerBobo.mint) : program.programId

    return program.transaction.dislikeComment(
      likerBobo.stakedData.staked,
      likerBobo.campData.camped,
      {
        accounts: {
          government,
          likerWallet: wallet.publicKey,

          likerBobo: likerBobo.mint,
          likerBoboAccount,
          likerBoboHibStakingAccount: hibStakingPDA,
          likerBoboCampStakingAccount: campStakingPDA,
          boboMetadataInfo: await Metadata.getPDA(likerBobo.mint),

          law,
          lawComment,

          commentLike,

          votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,

          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId,
        }
      }
    )
  }

  let transactions: Transaction[] = [];
  for (let likerBobo of likers) {
    transactions.push(await getLikeTransactionForMint(likerBobo));
  }

  let response = await sendAndConfirmTransactions(connection, wallet, transactions);
  return true;
}

export async function multiBurnVoteBallots(
  connection: Connection,
  wallet: Wallet,

  government: PublicKey,
  law: PublicKey,

  voterBobos: NftData[],
): Promise<boolean | any> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  async function getVoteTransactionForMint(
    voterBobo: NftData
  ): Promise<Transaction> {
    let voteBallot = await getVoteBallotPDA(law, voterBobo.mint)
    console.log(voteBallot.toString())

    let voterBoboAccount = (await connection.getTokenLargestAccounts(voterBobo.mint)).value[0].address;
    let hibStakingPDA = voterBobo.stakedData.staked ? await getStakeDataAddress(voterBobo.mint) : program.programId
    let campStakingPDA = voterBobo.campData.camped ? await getTrainingInfoAddress(voterBobo.mint) : program.programId

    return program.transaction.burnVoteBallot(
      voterBobo.stakedData.staked,
      voterBobo.campData.camped,
      {
        accounts: {
          government,
          voterWallet: wallet.publicKey,

          voterBobo: voterBobo.mint,
          voterBoboAccount,
          voterBoboHibStakingAccount: hibStakingPDA,
          voterBoboCampStakingAccount: campStakingPDA,
          boboMetadataInfo: await Metadata.getPDA(voterBobo.mint),

          law,

          voteBallot,

          votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,

          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          systemProgram: anchor.web3.SystemProgram.programId,
        }
      }
    )
  }

  let transactions: Transaction[] = [];
  for (let voterBobo of voterBobos) {
    transactions.push(await getVoteTransactionForMint(voterBobo));
  }

  let response = await sendAndConfirmTransactions(connection, wallet, transactions);
  return true;
}

export async function confirmLaw(
  connection: Connection,
  wallet: Wallet,

  government: PublicKey,
  law: PublicKey,

  presidentBobo: NftData,
): Promise<boolean | any> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  // let government = await getGovermentPDA(CANDY_MACHINE_ADDRESS);

  let presidentBoboAccount = (await connection.getTokenLargestAccounts(presidentBobo.mint)).value[0].address;
  let hibStakingPDA = presidentBobo.stakedData.staked ? await getStakeDataAddress(presidentBobo.mint) : program.programId
  let campStakingPDA = presidentBobo.campData.camped ? await getTrainingInfoAddress(presidentBobo.mint) : program.programId

  toast.info("Confirming Law...")
  try {
    await program.rpc.confirmLawPass(
      presidentBobo.stakedData.staked,
      presidentBobo.campData.camped,
      {
        accounts: {
          government,
          presidentWallet: wallet.publicKey,

          presidentBobo: presidentBobo.mint,
          presidentBoboAccount,
          presidentBoboHibStakingAccount: hibStakingPDA,
          presidentBoboCampStakingAccount: campStakingPDA,
          boboMetadataInfo: await Metadata.getPDA(presidentBobo.mint),

          law,

          votersCollectionCandyMachine: CANDY_MACHINE_ADDRESS,
        }
      }
    )
  } catch (e) {
    console.log(e)
    return e
  }
  toast.success("Confirmed Law!")
  return true
}










export async function getAllLaws(
  connection: Connection,
  wallet: Wallet,
  governmentData: GovernmentData
): Promise<Law[]> {
  // let placeholderLaws: Law[] = [{
  //     lawPublicKey: Keypair.generate().publicKey,
  //     creatorBobo: {mint: new PublicKey("9DNf8zRBvzNRfpFPWamZy2M61C9EpsYAryxtCUfdWAYw"), name: "Bobo #3844", imageUrl: "https://bafybeigsi3bvs6k4zs7exyvsncuunzhlvj54inxg7neoqgcrajawdse57q.ipfs.dweb.link/3844.png"},
  //     lawIndex: 0,
  //     title: "Change Gun Claim Mechanism",
  //     description: "https://discordapp.com/channels/895437534585290753/936634187740495892/973243236934119515",
  //     propositions: [{title: "Don't change anything", votes: 0}, {title: "Can either be claimed for full price or at a discount through quests. Allows for flexibility of the holder", votes: 57}, {title: "Only claimable through quests", votes: 387}],
  //     startTime: 1652550337,
  //     daysDuration: 5,
  //     numComments: 0,
  //     topicTag: "Education"
  // },{
  //     lawPublicKey: Keypair.generate().publicKey,
  //     creatorBobo: {mint: new PublicKey("CM8bSr7v4Dygo8RdqGU3AdZuPUcpHMPwJ2aZQ9JnVN4H"), name: "Bobo #5122", imageUrl: "https://bafybeic2tuto4itm6fdqfpgx5kol7njprrcx3oqx6jklr6gtiuxmnxo7cy.ipfs.dweb.link/5122.png"},
  //     lawIndex: 1,
  //     title: "Airdrop Kwengo 1000 sol",
  //     description: "https://discordapp.com/channels/895437534585290753/936634187740495892/973243236934119515",
  //     propositions: [{title: "Yes", votes: 1}, {title: "Dunno", votes: 0}, {title: "Hell yeah", votes: 7589}],
  //     startTime: 1652580337,
  //     daysDuration: 1,
  //     numComments: 0,
  //     topicTag: "Education"
  // },{
  //     lawPublicKey: Keypair.generate().publicKey,
  //     creatorBobo: {mint: new PublicKey("bu7dXMMw9JrokTfEDZYxgeBVKwjPSzoeXPR8SrmUbg3"), name: "Bobo #4917", imageUrl: "https://bafybeifsfpnto4i2oxqdlbzi7hhivzzmd3cq5iyzk3slh53zyur4gvc6uu.ipfs.dweb.link/4917.png"},
  //     lawIndex: 2,
  //     title: "Airdrop Kwengo 1000 sol",
  //     description: "https://discordapp.com/channels/895437534585290753/936634187740495892/973243236934119515",
  //     propositions: [{title: "Yes", votes: 2}, {title: "Dunno", votes: 0}, {title: "Hell yeah", votes: 7589}],
  //     startTime: 1652500337,
  //     daysDuration: 1,
  //     numComments: 0,
  //     topicTag: "Education"
  // },{
  //     lawPublicKey: Keypair.generate().publicKey,
  //     creatorBobo: {mint: new PublicKey("9DNf8zRBvzNRfpFPWamZy2M61C9EpsYAryxtCUfdWAYw"), name: "Bobo #3844", imageUrl: "https://bafybeigsi3bvs6k4zs7exyvsncuunzhlvj54inxg7neoqgcrajawdse57q.ipfs.dweb.link/3844.png"},
  //     lawIndex: 3,
  //     title: "Airdrop Kwengo 1000 sol",
  //     description: "https://discordapp.com/channels/895437534585290753/936634187740495892/973243236934119515",
  //     propositions: [{title: "Yes", votes: 3}, {title: "Dunno", votes: 0}, {title: "Hell yeah", votes: 500}],
  //     startTime: 1650570337,
  //     daysDuration: 1,
  //     numComments: 0,
  //     topicTag: "Education"
  // },{
  //     lawPublicKey: Keypair.generate().publicKey,
  //     creatorBobo: {mint: new PublicKey("DbSkVb1agcoppVNvDNMqwdBaUokvbcMXsx8i6DKdAS98"), name: "Bobo #5721", imageUrl: "https://bafybeig5h3ssiizod6ux5xsmxzcp7dko23ozg6sog3jh27yfberlcpfjji.ipfs.dweb.link/5721.png"},
  //     lawIndex: 4,
  //     title: "Airdrop Kwengo 100 sol",
  //     description: "https://discordapp.com/channels/895437534585290753/936634187740495892/973243236934119515",
  //     propositions: [{title: "Yes", votes: 4}, {title: "Dunno", votes: 0}, {title: "Hell yeah", votes: 7589}],
  //     startTime: 1650570337,
  //     daysDuration: 1,
  //     numComments: 0,
  //     topicTag: "Education"
  // },{
  //     lawPublicKey: Keypair.generate().publicKey,
  //     creatorBobo: {mint: new PublicKey("9DNf8zRBvzNRfpFPWamZy2M61C9EpsYAryxtCUfdWAYw"), name: "Bobo #3844", imageUrl: "https://bafybeigsi3bvs6k4zs7exyvsncuunzhlvj54inxg7neoqgcrajawdse57q.ipfs.dweb.link/3844.png"},
  //     lawIndex: 5,
  //     title: "Airdrop Kwengo 1000 sol",
  //     description: "https://discordapp.com/channels/895437534585290753/936634187740495892/973243236934119515",
  //     propositions: [{title: "Yes", votes: 5}, {title: "Dunno", votes: 0}, {title: "Hell yeah", votes: 7589}],
  //     startTime: 1652580337,
  //     daysDuration: 1,
  //     numComments: 0,
  //     topicTag: "Education"
  // },{
  //     lawPublicKey: Keypair.generate().publicKey,
  //     creatorBobo: {mint: new PublicKey("DbSkVb1agcoppVNvDNMqwdBaUokvbcMXsx8i6DKdAS98"), name: "Bobo #8721", imageUrl: "https://bafybeig5h3ssiizod6ux5xsmxzcp7dko23ozg6sog3jh27yfberlcpfjji.ipfs.dweb.link/5721.png"},
  //     lawIndex: 6,
  //     title: "Airdrop Kwengo 1000 sol",
  //     description: "https://discordapp.com/channels/895437534585290753/936634187740495892/973243236934119515",
  //     propositions: [{title: "Yes", votes: 6}, {title: "Dunno", votes: 0}, {title: "Hell yeah", votes: 7589}],
  //     startTime: 1652580337,
  //     daysDuration: 1,
  //     numComments: 0,
  //     topicTag: "Education"
  // },
  // ]
  // return placeholderLaws
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let laws: Law[] = []
  let fetchedLawAccounts = await program.account.law.all()
  console.log(fetchedLawAccounts)
  for (let account of fetchedLawAccounts) {
    let fetchedLaw = account.account
    console.log(fetchedLaw)
    let lawCreatorBobo = await getLawCreatorBoboData(connection, fetchedLaw.creatorBobo)
    console.log(lawCreatorBobo)
    laws.push({
      lawPublicKey: account.publicKey,
      creatorBobo: lawCreatorBobo,
      lawIndex: fetchedLaw.lawIndex,
      title: fetchedLaw.title,
      description: fetchedLaw.description,
      propositions: fetchedLaw.propositions,
      topicTag: fetchedLaw.topicTag,

      startTime: fetchedLaw.startTime,
      daysDuration: fetchedLaw.daysDuration,

      lawConfirmed: fetchedLaw.lawConfirmed,

      numComments: fetchedLaw.numComments,
    })
  }
  return laws
}

export async function getLawComments(
  connection: Connection,
  wallet: Wallet,
  law: PublicKey,
  offset: number,
  number: number
): Promise<Comment[]> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let comments: Comment[] = []
  console.log("Getting comments")
  for (let index = offset; index < offset + number; index++) {
    let commentPDA = await getLawCommentPDA(law, new BN(index))
    let fetchedComment
    try {
      fetchedComment = await program.account.lawComment.fetch(commentPDA)
    } catch (e) { break }
    let comment: Comment = {
      commentPublickey: commentPDA,
      commentIndex: fetchedComment.commentIndex,
      comment: fetchedComment.comment,
      commenterBobo: await getLawCreatorBoboData(connection, fetchedComment.commenterBobo),
      commentTimestamp: fetchedComment.commentTimestamp,
      numLikes: fetchedComment.numLikes,
      numDislikes: fetchedComment.numDislikes
    }
    comments.push(comment)
  }
  return comments

  // return [{
  //   commentIndex: 0,
  //   comment: "Bro are you stupid or what?",
  //   commenterBobo: {mint: new PublicKey("CM8bSr7v4Dygo8RdqGU3AdZuPUcpHMPwJ2aZQ9JnVN4H"), name: "Bobo #5122", imageUrl: "https://bafybeic2tuto4itm6fdqfpgx5kol7njprrcx3oqx6jklr6gtiuxmnxo7cy.ipfs.dweb.link/5122.png"},
  //   commentTimestamp: 1652645638,
  //   numLikes: 15
  // },
  // {
  //   commentIndex: 1,
  //   comment: "No it's actually a really great idea",
  //   commenterBobo: {mint: new PublicKey("DbSkVb1agcoppVNvDNMqwdBaUokvbcMXsx8i6DKdAS98"), name: "Bobo #8721", imageUrl: "https://bafybeig5h3ssiizod6ux5xsmxzcp7dko23ozg6sog3jh27yfberlcpfjji.ipfs.dweb.link/5721.png"},
  //   commentTimestamp: 1652645738,
  //   numLikes: 53
  // },
  // {
  //   commentIndex: 2,
  //   comment: "Wen LP",
  //   commenterBobo: {mint: new PublicKey("bu7dXMMw9JrokTfEDZYxgeBVKwjPSzoeXPR8SrmUbg3"), name: "Bobo #4917", imageUrl: "https://bafybeifsfpnto4i2oxqdlbzi7hhivzzmd3cq5iyzk3slh53zyur4gvc6uu.ipfs.dweb.link/4917.png"},
  //   commentTimestamp: 1652645900,
  //   numLikes: 2
  // }]
}


export async function getCitizenLawCreations(
  connection: Connection,
  wallet: Wallet,
  boboCitizen: PublicKey,
): Promise<Law[]> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let laws: Law[] = []
  let fetchedLawAccounts = await program.account.law.all([createCitizenFilter(boboCitizen, 8)])
  for (let account of fetchedLawAccounts) {
    let fetchedLaw = account.account
    let lawCreatorBobo = await getLawCreatorBoboData(connection, fetchedLaw.creatorBobo)
    laws.push(getLawFromFetched(account.publicKey, lawCreatorBobo, fetchedLaw))
  }
  console.log(laws)
  return laws
}

interface FetchedLaw {
  bump: number,
  claimedReward: boolean,
  creatorBobo: PublicKey,
  daysDuration: number,
  description: string,
  lawIndex: number,
  numComments: number,
  propositions: LawProposition[],
  startTime: number,
  title: string,
  topicTag: string,
}

export async function getCitizenLawVotes(
  connection: Connection,
  wallet: Wallet,
  boboCitizen: PublicKey,
  government: PublicKey
): Promise<VoteBallot[]> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let voteBallots: VoteBallot[] = []
  let fetchedVoteBallots = await program.account.voteBallot.all([createCitizenFilter(boboCitizen, 13)])

  let votedLawsAddresses = await Promise.all(fetchedVoteBallots.map(voteBallot => getLawPDA(government, new BN(voteBallot.account.lawIndex))))
  let fetchedVotedLaws = await program.account.law.fetchMultiple(votedLawsAddresses)

  for (let i = 0; i < fetchedVoteBallots.length; i++) {
    let fetchedVoteBallot = fetchedVoteBallots[i]


    // let fetchedLaw = fetchedVotedLaws.filter(law => law !== null && law.lawIndex == fetchedVoteBallot.account.lawIndex)[0]

    let fetchedLaw: FetchedLaw | null = fetchedVotedLaws[i] as FetchedLaw
    if (fetchedLaw === null) continue
    console.log(fetchedLaw)

    let lawCreatorBobo = await getLawCreatorBoboData(connection, fetchedLaw.creatorBobo)
    let law: Law = getLawFromFetched(votedLawsAddresses[i], lawCreatorBobo, fetchedLaw)

    let voteBallot: VoteBallot = {
      law,
      votePropositionIndex: fetchedVoteBallot.account.votePropositionIndex,
      voterBobo: fetchedVoteBallot.account.voterBobo
    }
    voteBallots.push(voteBallot)
  }
  console.log(voteBallots)
  return voteBallots
}

export async function getCitizenLawComments(
  connection: Connection,
  wallet: Wallet,
  boboCitizen: PublicKey,
  government: PublicKey
): Promise<LawComment[]> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let comments: LawComment[] = []
  let fetchedComments = await program.account.lawComment.all([createCitizenFilter(boboCitizen, 18)])

  let commentedLawsAddresses = await Promise.all(fetchedComments.map(comment => getLawPDA(government, new BN(comment.account.lawIndex))))
  let fetchedVotedLaws = await program.account.law.fetchMultiple(commentedLawsAddresses)

  for (let i = 0; i < fetchedComments.length; i++) {
    let fetchedComment = fetchedComments[i]
    // let fetchedLaw = fetchedVotedLaws.filter(law => law !== null && law.lawIndex == fetchedVoteBallot.account.lawIndex)[0]

    let fetchedLaw: FetchedLaw | null = fetchedVotedLaws[i] as FetchedLaw
    if (fetchedLaw === null) continue
    console.log(fetchedLaw)

    let lawCreatorBobo = await getLawCreatorBoboData(connection, fetchedLaw.creatorBobo)
    let law: Law = getLawFromFetched(commentedLawsAddresses[i], lawCreatorBobo, fetchedLaw)

    let lawCommenterBobo = await getLawCreatorBoboData(connection, fetchedComment.account.commenterBobo)

    let comment: Comment = {
      commentPublickey: fetchedComment.publicKey,
      commentIndex: fetchedComment.account.commentIndex,
      comment: fetchedComment.account.comment,
      commenterBobo: lawCommenterBobo,
      commentTimestamp: fetchedComment.account.commentTimestamp,
      numLikes: fetchedComment.account.numLikes,
      numDislikes: fetchedComment.account.numDislikes
    }

    let lawComment: LawComment = {
      law,
      comment
    }
    comments.push(lawComment)
  }
  console.log(comments)
  return comments
}



function getLawFromFetched(lawPublicKey: PublicKey, creatorBobo: LawCreatorBobo, fetchedLaw: any) {
  return {
    lawPublicKey,
    creatorBobo,
    lawIndex: fetchedLaw.lawIndex,
    title: fetchedLaw.title,
    description: fetchedLaw.description,
    propositions: fetchedLaw.propositions,
    topicTag: fetchedLaw.topicTag,

    startTime: fetchedLaw.startTime,
    daysDuration: fetchedLaw.daysDuration,

    lawConfirmed: fetchedLaw.lawConfirmed,

    numComments: fetchedLaw.numComments,
  }
}




export async function refreshLawVoteCounts(
  connection: Connection,
  wallet: Wallet,
  lawPDA: PublicKey,
  law: Law
): Promise<Law> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let fetchedLaw = await program.account.law.fetch(lawPDA)
  law.propositions = fetchedLaw.propositions

  return law
}



async function getLawCreatorBoboData(
  connection: Connection,
  boboMint: PublicKey
): Promise<LawCreatorBobo> {
  let metadataPDA = await Metadata.getPDA(boboMint)
  let metadata = await Metadata.getInfo(connection, metadataPDA)

  let metadataData
  if (metadata?.data != undefined) {
    metadataData = MetadataData.deserialize(metadata?.data)
  }
  let imageUrl = (await fetch(metadataData.data.uri).then(response => response.json())).image
  return {
    mint: boboMint,
    name: metadataData.data.name,
    imageUrl
  }
}


export async function checkIfBoboVoted(
  connection: Connection,
  wallet: Wallet,
  boboMint: PublicKey,
  law: PublicKey
): Promise<boolean> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let voteBallot = await getVoteBallotPDA(law, boboMint)
  return (await connection.getAccountInfo(voteBallot)) !== null
}

export async function checkIfBoboLikedComment(
  connection: Connection,
  wallet: Wallet,
  boboMint: PublicKey,
  comment: PublicKey
): Promise<boolean> {
  let provider = await getProvider(connection, wallet)
  let program = new Program(daoVotingProgramIdl as Idl, DAO_VOTING_PROGRAM_ID, provider);

  let commentLike = await getCommentLikePDA(comment, boboMint)
  return (await connection.getAccountInfo(commentLike)) !== null
}


export async function getSolTime(
  connection: Connection
): Promise<number | null> {
  let blockHeight = await connection.getSlot();
  let time = await connection.getBlockTime(blockHeight);
  console.log("Current Sol Clock timestamp: " + time)
  return time
}







function createLawAccountSizeFilter(): DataSizeFilter {
  return {
    dataSize: 603
  }
}

export function createLawAccountFilter(identifier: BN, offset: number = 8): MemcmpFilter {
  return {
    memcmp: {
      offset,
      bytes: "HXGU" //identifier.toString(),
    },
  };
}

function createCitizenFilter(citizenMint: PublicKey, offset: number = 9): MemcmpFilter {
  return {
    memcmp: {
      offset,
      bytes: citizenMint.toString()
    },
  };
}

