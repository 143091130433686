import "./MetricsHandler.css";
import React, { FC, useEffect, useState, useReducer } from "react";

import {
  // getTotalHolders, 
  // getCollectionStats, 
  // CollectionStats, 
  getCollectionInfo,
  CollectionInfo} 
from "../../utils/metrics"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import Spinner from 'react-bootstrap/Spinner';

import { gsap } from "gsap";


const MetricsHandler: FC<any> = (props) => {
  const { connection, wallet } = props;

  // const [totalHolders, setTotalHolders] = useState(0)
  // const [collectionStats, setCollectionStats] = useState<CollectionStats>()

  const [collectionInfo, setCollectionInfo] = useState<CollectionInfo>()

  useEffect(() => {
    startEntryAnimation()

    assignCollectionInfo()

    // assignTotalStakers()
    // assignCollectionStats()
  }, [])

  const assignCollectionInfo = async () => {
    setCollectionInfo(await getCollectionInfo())
  }
  // const assignTotalStakers = async () => {
  //   setTotalHolders(await getTotalHolders(connection, wallet))
  // }
  // const assignCollectionStats = async () => {
  //   setCollectionStats(await getCollectionStats())
  // }


  const startEntryAnimation = async () => {
    let entryTimeline = gsap.timeline()

    entryTimeline.from("#metric-header-1", {xPercent: -100, opacity: 0, duration: 1})
    let metricTabs1 = gsap.utils.toArray(".metric-tab")
    metricTabs1.map((metricTab: any) => {
        entryTimeline.from(metricTab, {yPercent: 100, opacity: 0, duration: 0.8}, "<+.25")
    })
  }

  const spinner = <Spinner animation="border" role="status">
  <span className="visually-hidden">Loading...</span>
</Spinner>

  return (
    <div id="Metrics-app" className="mt-5 pt-5">
        <h1 id="metric-header-1" className="text-start text-white ms-5" style={{textDecoration: "underline"}}>Marketplace</h1>
        <div className="row d-flex justify-content-center mx-5">
            <div className="metric-tab metric-tab-1 col-10 col-md-6 col-lg-5 col-xl-3 py-4 mx-3 my-3">
                <h2 className="text-white"><FontAwesomeIcon icon={faCircle} className="text-selected me-3" />Total Holders: {collectionInfo == undefined ? 
                spinner : collectionInfo.totalHolders}</h2>
            </div>
            <div className="metric-tab metric-tab-1 col-10 col-md-6 col-lg-5 col-xl-3 py-4 mx-3 my-3">
                <h2 className="text-white"><FontAwesomeIcon icon={faCircle} className="text-selected me-3" />Floor Price: {collectionInfo == undefined ? 
                spinner : collectionInfo.collectionStats.floorPrice}</h2>
            </div>
            <div className="metric-tab metric-tab-1 col-10 col-md-6 col-lg-5 col-xl-3 py-4 mx-3 my-3">
                <h2 className="text-white"><FontAwesomeIcon icon={faCircle} className="text-selected me-3" />Listed Bobos: {collectionInfo == undefined ? 
                spinner : collectionInfo.collectionStats.listed}</h2>
            </div>
            <div className="metric-tab metric-tab-1 col-10 col-md-6 col-lg-5 col-xl-3 py-4 mx-3 my-3">
                <h2 className="text-white"><FontAwesomeIcon icon={faCircle} className="text-selected me-3" />Total Volume: {collectionInfo == undefined ? 
                spinner : collectionInfo.collectionStats.volume}</h2>
            </div>
        </div>

    </div>
  );
}


export default MetricsHandler;
