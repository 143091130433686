import "./LawPanel.css"
import { FC, useEffect, useState, useRef } from "react";

import CitizenCircle from "./CitizenCircle"
import { getLawComments, multiVoteOnLaw, commentOnLaw, multiLikeLawComment, multiDislikeLawComment, multiBurnVoteBallots, confirmLaw, refreshLawVoteCounts, checkIfBoboVoted, getSolTime } from "../../utils/daoVoting"
import { NftData, LawProposition, Law, Comment } from "../../OnChain/chainInfo"

import { delay } from "../../OnChain/utils"

import { BN } from '@project-serum/anchor';

import Modal from "react-bootstrap/Modal"
import Spinner from "react-bootstrap/Spinner"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faCircleCheck, faCircleXmark, faArrowLeft, faRotateRight, faXmark } from "@fortawesome/free-solid-svg-icons";

import LawCommentContainer from "./LawCommentContainer"
import { gsap } from "gsap";
import { toast } from "react-toastify";
import { PublicKey } from "@solana/web3.js";

export enum LawState {
    UPCOMING,
    STARTED,
    PASSED,
    FAILED
}

enum TimeFormat {
    SECONDS,
    MINUTES,
    HOURS,
    DAYS
}

const votingFee = 0.00120604

export function checkIfWon(propositions: LawProposition[], quorum: number) {
    let sum = propositions.reduce((sum, proposition: LawProposition) => sum + proposition.votes, 0)
    if (sum > quorum) return true
    return false
}

const LawPanel: FC<any> = (props) => {
    const { connection, wallet, government, selectedLaw, setSelectedLaw, bobos, returnFunction } = props;

    const [lawState, setLawState] = useState<LawState>(LawState.UPCOMING)
    const [timeLeft, setTimeLeft] = useState(0)
    const [comments, setComments] = useState<Comment[]>([])

    const [selectedProposition, setSelectedProposition] = useState<number>()
    const [selectedComment, setSelectedComment] = useState<Comment>()
    const [writtenComment, setWrittenComment] = useState<string>("")

    const [loadingVotes, setLoadingVotes] = useState(false)
    const [loadingComments, setLoadingComments] = useState(false)
    const [loadingRetrievingFees, setLoadingRetrievingFees] = useState(false)
    const [executingAction, setExecutingAction] = useState(false)

    const [voterBobos, setVoterBobos] = useState<NftData[]>([])
    const [disabledBobos, setDisabledBobos] = useState<NftData[]>([])
    const [selectedBobos, setSelectedBobos] = useState<NftData[]>([])
    const [selectionMode, setSelectionMode] = useState("")

    const [currentTimeLeftTimeout, setCurrentTimeLeftTimeout] = useState<NodeJS.Timeout>()
    const [commentTimeout, setCommentTimeout] = useState<NodeJS.Timeout>()

    const solClockDrift = useRef(0)
    const [winningPropositionIndex, setWinningPropositionIndex] = useState<number>(0)
    const [hasAnAuthority, setHasAnAuthority] = useState(false)

    function getTimeLeft() {
        let currentTime = Math.floor(Date.now() / 1000) - solClockDrift.current
        let startTimeDifference = selectedLaw.startTime - currentTime
        if(startTimeDifference > 0) {
            setLawState(LawState.UPCOMING)
            setTimeLeft(startTimeDifference)
            let timeout = setTimeout(getTimeLeft, 1000)
            setCurrentTimeLeftTimeout(timeout)
            return true
        }
        let endDate = selectedLaw.startTime + selectedLaw.daysDuration * 86400
        let endTimeDifference = endDate - currentTime
        if (endTimeDifference > 0) {
            setLawState(LawState.STARTED)
            setTimeLeft(endTimeDifference)
            let timeout = setTimeout(getTimeLeft, 1000)
            setCurrentTimeLeftTimeout(timeout)
            return true
        } else {
            setTimeLeft(currentTime - endDate)
            let timeout = setTimeout(getTimeLeft, 1000)
            setCurrentTimeLeftTimeout(timeout)

            if (selectedLaw.lawConfirmed) {
                setLawState(LawState.PASSED)
                return false
            }
            setLawState(LawState.FAILED)
            return false
        }
    }

    function getFormattedTime(seconds: number, timeFormat: TimeFormat) {
        let formattedTime;
        switch (timeFormat) {
            case TimeFormat.SECONDS:
                formattedTime = seconds % 60
                break;
            case TimeFormat.MINUTES:
                formattedTime = Math.floor(seconds / 60) % 60
                break;
            case TimeFormat.HOURS:
                formattedTime = Math.floor(seconds / 60 / 60) % 24
                break;
            case TimeFormat.DAYS:
                formattedTime = Math.floor(seconds / 60 / 60 / 24)
                break;
        }
        return formattedTime.toString().padStart(2, "0");
    }

    async function getComments() {
        setLoadingComments(true)
        setComments(await getLawComments(connection, wallet, selectedLaw.lawPublicKey, 0, 10))
        setLoadingComments(false)
        // let timeout = setTimeout(getComments, 10_000)
        // setCommentTimeout(timeout)
    }

    async function getVoteCounts() {
        console.log("getting vote counts")
        setLoadingVotes(true)
        setSelectedLaw(await refreshLawVoteCounts(connection, wallet, selectedLaw.lawPublicKey, selectedLaw))
        setLoadingVotes(false)
    }

    async function refreshLaw() {
        getVoteCounts();
        getComments();
    }

    function getWinningProposition() {
        let winningIndex = 0
        let winningVotes = 0
        for(let i = 0; i <selectedLaw.propositions.length; i++) {
            if (selectedLaw.propositions[i].votes > winningVotes) {
                winningIndex = i
                winningVotes = selectedLaw.propositions[i].votes
            }
        }
        setWinningPropositionIndex(winningIndex)
    }

    useEffect(() => {
        getWinningProposition()

        getTimeLeft()
        getComments()
        if(currentTimeLeftTimeout != undefined) clearTimeout(currentTimeLeftTimeout)
        if(commentTimeout != undefined) clearTimeout(commentTimeout)

        return () => {
            if(currentTimeLeftTimeout != undefined) clearTimeout(currentTimeLeftTimeout)
            if(commentTimeout != undefined) clearTimeout(commentTimeout)
        }  
    }, [selectedLaw])

    useEffect(() => {
        setDisabledBobos([])
        setSelectedBobos([])
    }, [selectionMode])

    function selectProposition(propositionIndex: number) {
        setSelectionMode("Vote")
        setSelectedProposition(propositionIndex)
    }

    function selectCommentLike(comment: Comment) {
        setSelectionMode("Like")
        setSelectedComment(comment)
    }
    function selectCommentDislike(comment: Comment) {
        setSelectionMode("Dislike")
        setSelectedComment(comment)
    }

    function toggleBoboSelection(bobo: NftData) {
        if (selectionMode === "Comment") {
            setSelectedBobos([bobo]); 
            return
        }

        let currentSelected = selectedBobos;
        if(currentSelected.includes(bobo)) {
            currentSelected.splice(currentSelected.indexOf(bobo, 0), 1)
        } else {
            currentSelected.push(bobo)
        }
        setSelectedBobos([...currentSelected])
    }

    function updateDisabledBobos(bobo: NftData) {
        let newDisabledBobos = disabledBobos
        newDisabledBobos.push(bobo)
        setDisabledBobos([...newDisabledBobos])
    }

    function toggleSelectAll() {
        if (selectedBobos.length !== 0) setSelectedBobos([])
        else setSelectedBobos([...bobos.filter((bobo: NftData) => !disabledBobos.includes(bobo))])
    }

    async function executeAction() {
        if(executingAction) return

        setExecutingAction(true)
        let refreshFunc: Function
        let response = true
        if(selectionMode === "Vote" && selectedProposition !== undefined && selectedBobos.length !== 0) {
            refreshFunc = getVoteCounts
            try {
                await multiVoteOnLaw(connection, wallet, government.governmentPublicKey, selectedLaw.lawPublicKey, new BN(selectedProposition), selectedBobos)
            } catch (err: any) {
                console.error(err)
                toast.error(err.toString())
                response = false
            }
        }
        else if(selectionMode === "Comment" && selectedBobos.length === 1 && writtenComment !== "") {
            refreshFunc = getComments
            try {
                await commentOnLaw(connection, wallet, government.governmentPublicKey, selectedLaw.lawPublicKey, writtenComment, selectedBobos[0])
            } catch (err: any) {
                console.error(err)
                toast.error(err.toString())
                response = false
            }
        }
        else if(selectionMode === "Like" && selectedComment !== undefined && selectedBobos.length !== 0) {
            refreshFunc = getComments
            try {
                await multiLikeLawComment(connection, wallet, government.governmentPublicKey, selectedLaw.lawPublicKey, selectedComment.commentPublickey, selectedBobos)
            } catch (err: any) {
                console.error(err)
                toast.error(err.toString())
                response = false
            }
        } else if(selectionMode === "Dislike" && selectedComment !== undefined && selectedBobos.length !== 0) {
            refreshFunc = getComments
            try {
                await multiDislikeLawComment(connection, wallet, government.governmentPublicKey, selectedLaw.lawPublicKey, selectedComment.commentPublickey, selectedBobos)
            } catch (err: any) {
                console.error(err)
                toast.error(err.toString())
                response = false
            }
        } else {
            setExecutingAction(false)
            return
        }
        if(response !== true) {
            setExecutingAction(false)
            return
        }

        await delay(3000)
        setSelectionMode("")
        setExecutingAction(false)
        refreshFunc()
    }

    useEffect(() => {
        gsap.from(".law-container", {scale: 0, duration: 1})
        getSolClockDrift()
    }, [])

    useEffect(() => {
        checkIfHasAnAuthority()
    }, [bobos, government])

    useEffect(() => {
        getVoterBobos()
    }, [bobos, selectedLaw])

    async function refreshData() {
        gsap.to("#refresh-icon", {rotation: 1080, duration: 1, ease: "none", onComplete: () => {gsap.set("#refresh-icon", {rotation: 0})}})
        refreshLaw()
        getVoterBobos()
    }

    async function retrieveVotingFees() {
        if(voterBobos.length === 0) {
            toast.error("Already retrieved voting fees")
            return
        }
        toast.info("Retrieving voting fees for " + voterBobos.length + " vote ballots")
        setLoadingRetrievingFees(true)
        try {
            await multiBurnVoteBallots(connection, wallet, government.governmentPublicKey, selectedLaw.lawPublicKey, voterBobos)
        } catch (err: any) {
            console.error(err)
            toast.error(err.toString())
        }
        
        setTimeout(() => {getVoterBobos(); setLoadingRetrievingFees(false)}, 5000)
    }

    async function getVoterBobos() {
        let newVoterBobos: NftData[] = [];
        for (let bobo of bobos) {
            if(await checkIfBoboVoted(connection, wallet, bobo.mint, selectedLaw.lawPublicKey)) {
                newVoterBobos.push(bobo)
            }
        }
        setVoterBobos(newVoterBobos)
    }

    async function getSolClockDrift() {
        let solTime = await getSolTime(connection)
        if (solTime === null) {
            solClockDrift.current = 0
            return
        }
        solClockDrift.current = Math.floor(Date.now() / 1000) - solTime
    }

    function checkIfHasAnAuthority() {
        let stringLeaders = government.leaders.map((leader: PublicKey) => leader.toString())
        let has = false
        for (let i = 0; i < bobos.length; i++) {
            let bobo = bobos[i]
            if (bobo.mint.toString() == government.presidentBobo.toString() || stringLeaders.includes(bobo.mint.toString())) {
                console.log("Has an authority!")
                has = true
            }
        }
        setHasAnAuthority(has)
    }

    let timeDiv = <div>
                    <span className="timer-card text-ussr p-1 mx-1" style={{fontSize: "2rem"}}>{getFormattedTime(timeLeft, TimeFormat.DAYS)}</span>
                    <span className="timer-card text-ussr p-1 mx-1" style={{fontSize: "2rem"}}>{getFormattedTime(timeLeft, TimeFormat.HOURS)}</span>
                    <span className="timer-card text-ussr p-1 mx-1" style={{fontSize: "2rem"}}>{getFormattedTime(timeLeft, TimeFormat.MINUTES)}</span>
                    <span className="timer-card text-ussr p-1 mx-1" style={{fontSize: "2rem"}}>{getFormattedTime(timeLeft, TimeFormat.SECONDS)}</span>
                </div>

    return (
        <div id="Law-Panel" className="container-fluid d-flex flex-column px-lg-5">
            <FontAwesomeIcon icon={faArrowLeft} className="pointer-hover hover-text-selected-drop text-white align-self-start mb-4" onClick={returnFunction} size="2x" />
            <div className="d-flex justify-content-between align-items-center"><h1 className="text-start text-ussr">Law n°{selectedLaw.lawIndex}</h1><FontAwesomeIcon id="refresh-icon" icon={faRotateRight} className="pointer-hover hover-text-selected-drop" size="2x" onClick={refreshData} /></div>
            <div className={"law-container no-hover selected-shadow row justify-content-center w-100 mx-auto my-3"}>
                <div className="col-9 d-flex flex-column align-items-start p-3" style={{wordWrap: "break-word"}}>
                    <h1>{selectedLaw.title} <span className="badge rounded-pill selected-gradient text-white" style={{fontSize: "1rem"}}>{selectedLaw.topicTag}</span></h1>
                    <p className="w-100 text-start my-3">{selectedLaw.description}</p>
                    <h2 className="mt-5">Propositions:</h2>
                    <div className="d-flex flex-column w-100">
                    {selectedLaw.propositions.map((proposition: LawProposition, index: number) => {
                        return <div key={index} className="d-flex justify-content-between w-100 my-2">
                                <span className="text-start ms-3" style={{maxWidth: "90%", fontSize: "1.5rem"}}>{index + "."} {proposition.title}</span>{lawState !== LawState.UPCOMING && <button className={`btn ${lawState === LawState.STARTED && "selected-gradient"} text-white d-flex align-items-center`} onClick={() => selectProposition(index)} disabled={lawState !== LawState.STARTED}><FontAwesomeIcon className="vote-icon me-2" icon={faArrowUp} /> {loadingVotes ? <Spinner animation="border" className="align-self-center" /> : <span className={lawState == LawState.PASSED && index === winningPropositionIndex ? "text-selected text-bold": ""}>{proposition.votes}</span>}</button>}
                            </div>
                    })}
                    </div>
                </div>
                <div className="col-3 d-flex flex-column align-items-end p-3">
                    <img className="img-fluid mb-2" style={{borderRadius: "50%", width: "150px"}} src={selectedLaw.creatorBobo.imageUrl} />
                    {/* <h6 className="text-ussr text-end">{shortenAddress(selectedLaw.creatorBobo.toString())}</h6> */}
                    <h3 className="text-ussr text-end">{selectedLaw.creatorBobo.name}</h3>
                    <div className="d-flex flex-column justify-content-center align-items-end w-100 mt-4">
                        {lawState == LawState.PASSED && <FontAwesomeIcon className="text-selected selected-shadow me-3 mb-3" icon={faCircleCheck} size="2x" style={{borderRadius: "50%"}} />}
                        {lawState == LawState.FAILED && <FontAwesomeIcon className="icon-failed me-3 mb-3" icon={faCircleXmark} size="2x" style={{borderRadius: "50%"}} />}
                        {lawState == LawState.UPCOMING && <>
                        <h3>Starts In</h3>
                        {timeDiv}
                        </>}
                        {lawState == LawState.STARTED && <>
                        <h3>Ends In</h3>
                        {timeDiv}
                        </>}
                        {(lawState == LawState.PASSED || lawState == LawState.FAILED) && <>
                        <h3>Ended</h3>
                        {timeDiv}
                        </>}
                    </div>
                </div>
            </div>
            {hasAnAuthority && checkIfWon(selectedLaw.propositions, government.lawQuorum) ? <button className="btn selected-gradient text-white mb-4" disabled={selectedLaw.lawConfirmed} onClick={() => confirmLaw(connection, wallet, government.governmentPublicKey, selectedLaw.lawPublicKey, bobos.filter((bobo: NftData) => bobo.mint.toString() == government.presidentBobo.toString())[0])}><h3 className="mb-0">Confirm Law</h3></button> : ""}
            {(lawState == LawState.PASSED || lawState == LawState.FAILED) && voterBobos.length > 0 ? !loadingRetrievingFees ? <button className="btn selected-gradient text-white" onClick={retrieveVotingFees}><h3 className="mb-0">Retrieve {voterBobos.length} voting fees ({votingFee * voterBobos.length} SOL)</h3></button> : <Spinner animation="border" className="align-self-center" /> : ""}
            <div className="d-flex justify-content-between mt-5"><span className="text-start text-ussr"><h2>Comments ({comments.length})</h2></span><button className="btn selected-gradient no-focus text-white text-ussr" onClick={() => setSelectionMode("Comment")}><h4 className="m-0">Comment</h4></button></div>
            <div className="d-flex flex-column justify-content-start align-items-start w-100 mt-2 ps-5">
                {loadingComments ? <Spinner animation="border" className="align-self-center" />
                :
                comments.map(comment => <LawCommentContainer key={comment.commentIndex} comment={comment} onLikeClick={() => selectCommentLike(comment)} onDislikeClick={() => selectCommentDislike(comment)} />)}
            </div>
            <Modal id="law-selection-modal" size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={selectionMode !== ""} onHide={() => setSelectionMode("")} backdrop={executingAction ? "static": undefined} keyboard={!executingAction}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {selectionMode === "Vote" ? "Vote for proposition n°" + selectedProposition : (selectionMode === "Like" || selectionMode === "Dislike") ? selectionMode + " " + selectedComment?.commenterBobo.name + "'s comment": selectionMode === "Comment" ? "Comment" : ""}
                    </Modal.Title>
                    <FontAwesomeIcon icon={faXmark} size="2x" className="pointer-hover hover-text-selected-drop" onClick={() => setSelectionMode("")} />
                </Modal.Header>
                <Modal.Body className="d-flex flex-column justify-content-center">
                    {selectionMode === "Comment" && <input onChange={(e) => setWrittenComment(e.target.value)} className="styled-input my-3 mx-3 px-2" maxLength={government.maxLawCommentLength} autoFocus={true}></input>}
                    {bobos.length > 1 && disabledBobos.length < bobos.length && selectionMode !== "Comment" && <button className="btn selected-gradient no-focus text-white my-3" style={{width: "30%"}} onClick={toggleSelectAll}>Select all</button>}
                    <h4 className="text-start text-ussr mt-4">Select a Bobo</h4>
                    <div className="row justify-content-center">
                        {bobos.map((bobo: NftData) => {
                            return <CitizenCircle class="justify-content-center align-items-center col-3 col-sm-2 col-md-2 col-lg-2 col-xxl-2 mx-2 my-2" connection={connection} wallet={wallet} key={bobo.name} nft={bobo} selectedLaw={selectionMode === "Vote" ? selectedLaw : undefined} selectedComment={(selectionMode === "Like" || selectionMode === "Dislike") ? selectedComment : undefined} updateDisabledBobos={updateDisabledBobos} selected={selectedBobos.includes(bobo)} onClick={() => toggleBoboSelection(bobo)} />
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className={`btn ${selectedBobos.length !== 0 && "selected-gradient"} text-white`} disabled={selectedBobos.length === 0} onClick={executeAction} >{selectionMode}</button>
                </Modal.Footer>
                {executingAction && <div className="absolute-container-block d-flex justify-content-center align-items-center"><Spinner animation="border" className="align-self-center" /></div>}
            </Modal>
        </div>
    )
}

export default LawPanel
