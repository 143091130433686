import { useEffect, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { VaultData, StakedData, getStakedDataByOwner } from "../../../utils/newStaking";

export const useStakedNfts = (vaultData: VaultData | undefined, refreshHandle?: any) => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [nfts, setNfts] = useState<StakedData[]>([]);
  const[fetchingStakedData, setFetchingStakedData] = useState(false)

  useEffect(() => {
    (async () => {
      if (!wallet.publicKey || vaultData === undefined) return;

      setFetchingStakedData(true)
      const stakedNfts = await getStakedDataByOwner(connection, wallet, wallet.publicKey, vaultData);
      console.log(stakedNfts);
      let collator = new Intl.Collator(undefined, { numeric: true });
      stakedNfts.sort((a, b) => collator.compare(a.data.name, b.data.name));
      setNfts(stakedNfts);
      setFetchingStakedData(false);
    })();
  }, [wallet, connection, refreshHandle]);

  return {nfts, fetchingStakedData};
};
