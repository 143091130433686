import React, { FC, useEffect, useState, useReducer } from "react";

import { PublicKey } from "@solana/web3.js";
import {
  getVaultData,
  VaultData,
  stakeNFT,
  stakeAllNFTs,
  unstakeNFT,
  unstakeAllNFTs,
  withdrawRewards,
  withdrawAllRewards
} from "../../utils/newStaking"
import { useStakedNfts } from "./hooks/useStakedNfts";
import { useUnstakedNfts } from "./hooks/useUnstakedNfts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import StakingContainer from "./StakingContainer.js"

const toastOptions = {
  style: {background: "#333333", color: '#ffffff', fontWeight: 'bold'},
  progressStyle: {background: "#30dd8a"}
}

const StakingHandler: FC<any> = (props) => {
  const { connection, wallet } = props;
  const [refreshHandle, forceRefresh] = useReducer((x) => x + 1, 0);

  const [vaultData, setVaultData] = useState<VaultData>()
  const {nfts: stakedNfts, fetchingStakedData} = useStakedNfts(vaultData, refreshHandle);
  const {nfts: unstakedNfts, fetchingUnstakedData} = useUnstakedNfts(vaultData?.collectionCandyMachine?.toString(), refreshHandle);
  const [totalDailyRate, setTotalDailyRate] = useState(0)
  const [totalStaked, setTotalStaked] = useState(0)
  const [vBOWHeld, setVBOWHeld] = useState<number>();

  const stakeToken = React.useCallback(
    async (mint: PublicKey, lockedDuration: number) => {
      let fetchedVaultData = await fetchVaultData()
      let timeout = 3000
      if(fetchedVaultData !== null && wallet !== undefined && wallet.connected) {
        try {
          await stakeNFT(connection, wallet, mint, fetchedVaultData, lockedDuration)
          toast.success("Sent Bobo to Hibernation Station Staking!")
        } catch (e) {
          toast.error("Failed to verify signature. Refresh page and try again.")
          timeout = 10000
        }
      }
      setTimeout(forceRefresh, timeout);
    },
    [connection, wallet]
  )

  const unstakeToken = React.useCallback(
    async (mint: PublicKey) => {
      let fetchedVaultData = await fetchVaultData()
      let timeout = 3000
      if(fetchedVaultData !== null && wallet !== undefined && wallet.connected) {
        try {
          await unstakeNFT(connection, wallet, mint, fetchedVaultData)
          toast.success("Successfully woke up Bobo from Hibernation")
        } catch (e) {
          toast.error("Could not verify signature. Refresh page and try again.")
          timeout = 10000
        }
      }
      setTimeout(forceRefresh, timeout);
    },
    [connection, wallet]
  )

  const withdrawRewardsForStaked = React.useCallback(
    async (mint: PublicKey) => {
      let fetchedVaultData = await fetchVaultData()
      let timeout = 3000
      if(fetchedVaultData !== null && wallet !== undefined && wallet.connected) {
        try {
          await withdrawRewards(connection, wallet, mint, fetchedVaultData)
          toast.success("Successfully claimed staking rewards")
        } catch (e) {
          toast.error("Could not verify signature. Refresh page and try again.")
          timeout = 10000
        }
      }
      setTimeout(forceRefresh, timeout);
    },
    [connection, wallet]
  )

  const stakeAllTokens = React.useCallback(
    async (lockedDuration: number) => {
      let fetchedVaultData = await fetchVaultData()
      let timeout = 3000
      if(fetchedVaultData !== null && wallet !== undefined && wallet.connected) {
        let mints = unstakedNfts.map(staked => staked.mint)
        try {
          await stakeAllNFTs(connection, wallet, mints, fetchedVaultData, lockedDuration)
        } catch (err) {
          timeout = 10000
        }
      }
      setTimeout(forceRefresh, timeout);
    },
    [connection, wallet, unstakedNfts]
  )

  const unstakeAllUnlockedTokens = React.useCallback(
    async () => {
      let fetchedVaultData = await fetchVaultData()
      let timeout = 3000
      if(fetchedVaultData !== null && wallet !== undefined && wallet.connected) {
        let currentTimestamp = Math.floor(Date.now() / 1000)
        let unlockedNfts = stakedNfts.filter(staked => ((staked.timestamp + staked.lockedDuration) - currentTimestamp) < 0)
        let mints = unlockedNfts.map(nft => nft.mint)
        try {
          await unstakeAllNFTs(connection, wallet, mints, fetchedVaultData)
        } catch (err) {
          timeout = 10000
        }
      }
      setTimeout(forceRefresh, timeout);
    },
    [connection, wallet, stakedNfts]
  )

  const withdrawAllRewardsForStaked = React.useCallback(
    async () => {
      let fetchedVaultData = await fetchVaultData()
      let timeout = 3000
      if(fetchedVaultData !== null && wallet !== undefined && wallet.connected) {
        let mints = stakedNfts.map(staked => staked.mint)
        try {
          await withdrawAllRewards(connection, wallet, mints, fetchedVaultData)
        } catch (err) {
          timeout = 10000
        }
      }
      setTimeout(forceRefresh, timeout);
    },
    [connection, wallet, stakedNfts]
  )

  const fetchVaultData = async () => {
    let fetchedVaultData = await getVaultData(connection, wallet)
    if(fetchedVaultData !== null) {
      setVaultData(fetchedVaultData)
    }
    return fetchedVaultData
  }

  useEffect(() => {
    const getVaultInfo = async () => {
      if(wallet !== undefined && wallet?.publicKey != null && wallet.connected && !wallet.connecting && !wallet.disconnecting) {
        let fetchedVaultData = await getVaultData(connection, wallet)
        if(fetchedVaultData !== null) {
          setVaultData(fetchedVaultData)
          let _totalStaked = Number(fetchedVaultData.numStaked);
          setTotalStaked(_totalStaked)

          if(wallet.publicKey !== null) {
            let accountsData = await connection.getParsedTokenAccountsByOwner(wallet.publicKey, {mint: fetchedVaultData.rewardMint});
            if(accountsData.value.length) {
              setVBOWHeld(accountsData.value[0].account.data.parsed.info.tokenAmount.uiAmount)
            }
          }

          setTimeout(forceRefresh, 1500);
        }
      }
    }

    if(wallet !== null) {
      getVaultInfo()
    }
  }, [connection, wallet])

  useEffect(() => {
    let sum = 0
    stakedNfts.map(stakedNft => sum += stakedNft.dailyRate)
    // console.log("TOTAL DAILY RATE: " + sum)
    setTotalDailyRate(sum)
  }, [stakedNfts])

  return (
    <div id="Staking-app">
      {/* stakeToken={stakeToken} unstakeToken={unstakeToken} withdrawRewards={withdrawRewards} stakeAllBobos={stake} withdrawAllRewards={withdrawAllRewards} */}
      <StakingContainer refreshHandle={refreshHandle} connection={connection} wallet={wallet} vaultData={vaultData} totalStaked={totalStaked} totalDailyRate={totalDailyRate} stakedNfts={stakedNfts} fetchingStakedData={fetchingStakedData} unstakedNfts={unstakedNfts} fetchingUnstakedData={fetchingUnstakedData} stakeToken={stakeToken} unstakeToken={unstakeToken} withdrawRewardsForStaked={withdrawRewardsForStaked} stakeAllTokens={stakeAllTokens} unstakeAllUnlockedTokens={unstakeAllUnlockedTokens} withdrawAllRewardsForStaked={withdrawAllRewardsForStaked} />
      <ToastContainer />
    </div>
    
  );
};

export default StakingHandler;
