import React from "react"

import Spinner from "react-bootstrap/Spinner"


import { checkIfBoboVoted, checkIfBoboLikedComment } from "../../utils/daoVoting"
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';

const placeholderDiv = <div className="image-placeholder w-100" style={{background: "grey", borderRadius: "2rem", height: 0, paddingBottom: "100%"}}></div>


class CitizenCircle extends React.Component<any, any> {

    async componentDidMount() {
        this.getImage()
        this.setState({loading: true})
        let votedOnLaw = this.props.selectedLaw !== undefined && await checkIfBoboVoted(this.props.connection, this.props.wallet, this.props.nft.mint, this.props.selectedLaw.lawPublicKey)
        let likedComment = this.props.selectedComment !== undefined && await checkIfBoboLikedComment(this.props.connection, this.props.wallet, this.props.nft.mint, this.props.selectedComment.commentPublickey)
        this.setState({votedOnLaw, likedComment, loading: false});
        (votedOnLaw || likedComment) && this.updateParentWhenDisabled()
    }

    async componentDidUpdate(prevProps: any) {
        if(prevProps.nft !== this.props.nft) {
            this.setState({
                nft: this.props.nft,
                uri: this.props.nft.uri,
                image: null
            }, this.getImage)
        }
        if(prevProps.selectedLaw !== this.props.selectedLaw) {
            this.setState({loading: true})
            let votedOnLaw = this.props.selectedLaw !== undefined && await checkIfBoboVoted(this.props.connection, this.props.wallet, this.props.nft.mint, this.props.selectedLaw.lawPublicKey)
            this.setState({votedOnLaw, loading: false});
            votedOnLaw && this.updateParentWhenDisabled()
        }
        if(prevProps.selectedComment !== this.props.selectedComment) {
            this.setState({loading: true})
            let likedComment = this.props.selectedComment !== undefined && await checkIfBoboLikedComment(this.props.connection, this.props.wallet, this.props.nft.mint, this.props.selectedComment.commentPublickey)
            this.setState({likedComment, loading: false});
            likedComment && this.updateParentWhenDisabled()
        }
    }

    constructor(props: any) {
        super(props)
        this.state = {
            nft: this.props.nft,
            uri: this.props.nft.uri,
            loading: false
        }
        this.getImage = this.getImage.bind(this)
    }

    async getImage() {
        this.setState({loading: true})
        let json = await fetch(this.state.uri).then(response => response.json())
        this.setState({image: json.image, loading: false})
    }

    updateParentWhenDisabled() {
        if(this.props.updateDisabledBobos === undefined || this.props.updateDisabledBobos === null) return

        this.props.updateDisabledBobos(this.state.nft)
    }

    render() {
        return(
            <div className={this.props.class + ((this.state.votedOnLaw || this.state.likedComment) ? " disabled-container" : "")} onClick={() => !this.state.loading && !this.state.votedOnLaw && !this.state.likedComment && this.props.onClick()}>
                {this.state.loading ? <Spinner animation="border" className="align-self-center justify-self-center" />
                :
                <img src={this.state.image} className={`w-100 mb-2 ${this.props.selected && "selected-shadow selected-border"} ${this.props.hoverable && "pointer-hover hover-selected-border"}`} style={{borderRadius: "2rem"}} />}
            </div>
        )
    }
}

export default CitizenCircle;